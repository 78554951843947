<template>
  <Toast />

  <Dialog style="width: 450px" v-model:visible="altNameDialog" modal header="Modificar Nome" :dismissable-mask="true">
    <div style="width: 100%; padding: 10px">
      <InputText v-model="newName" style="width: 100%; border: 0px;
        border-bottom: 1px solid var(--color_brand_medium);
        border-radius: 0px;" placeholder="Nome do Processo" />
    </div>
    <template #footer>
      <div style="width:100%" class="text-center">
        <Button class="primaryButton" style="width: 100px" :label="this.$langGetValue('buttonSave')" @click="altName" />
      </div>
    </template>
  </Dialog>

  <Dialog style="width: 450px" v-model:visible="altBpmDialog" modal header="Modificar BPM" :dismissable-mask="true">
    <div class="flex" style="width: 100%; padding: 10px">
      <AutoComplete field="user" style="height: fit-content; width: 100%" :multiple="false" v-model="newBpm"
        :suggestions="filteredUsers" :disabled="newBpm?.mail != null" @complete="searchUsersLara($event, 'user')"
        :forceSelection="true" :placeholder="newBpm == null ? this.$langGetValue('addPerson') : ''" />
      <i v-if="newBpm?.mail != null" class="pi pi-times flex align-items-center" @click="newBpm = null"
        style="font-size: 1.1rem; cursor: pointer; color: red"></i>
    </div>
    <template #footer>
      <div style="width:100%" class="text-center">
        <Button class="primaryButton" style="width: 100px" :label="this.$langGetValue('buttonSave')" @click="altBpm" />
      </div>
    </template>
  </Dialog>

  <Dialog style="width: 450px" v-model:visible="altRexDialog" modal header="Modificar REX" :dismissable-mask="true">
    <div class="flex" style="width: 100%; padding: 10px">
      <AutoComplete field="user" style="height: fit-content; width: 100%" :multiple="false" v-model="newRex"
        :suggestions="filteredUsers" :disabled="newRex?.mail != null" @complete="searchUsersLara($event, 'user')"
        :forceSelection="true" :placeholder="newRex == null ? this.$langGetValue('addPerson') : ''" />
      <i v-if="newRex?.mail != null" class="pi pi-times flex align-items-center" @click="newRex = null"
        style="font-size: 1.1rem; cursor: pointer; color: red"></i>
    </div>
    <template #footer>
      <div style="width:100%" class="text-center">
        <Button class="primaryButton" style="width: 100px" :label="this.$langGetValue('buttonSave')" @click="altRex" />
      </div>
    </template>
  </Dialog>

  <Dialog style="width: 450px" v-model:visible="altDescriptionDialog" modal header="Modificar Descrição"
    :dismissable-mask="true">
    <div class="flex" style="width: 100%; padding: 10px">
      <Textarea v-model="newDescription" rows="5" cols="30" style="resize: none; width: 100%" />
    </div>
    <template #footer>
      <div style="width:100%" class="text-center">
        <Button class="primaryButton" style="width: 100px" :label="this.$langGetValue('buttonSave')"
          @click="altDescription" />
      </div>
    </template>
  </Dialog>

  <div style="display: flex; flex-direction: column; width: 100%">
    <div style="
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 32px;
      ">
      <BiaFormStepper class="biggerStepper" :statusInput="this.formData.status" />
      <div class="h2" style="margin-top: 64px; width: 50%; text-align: center">
        <i v-if="checkRole(['bia.manager'])" class="pi pi-pencil mr-1" v-tooltip.top="'Editar'"
          @click="altNameDialog = true" style="font-size: 1.1rem; cursor: pointer; color: red"></i>
        {{ this.formData.data.process.name }}
      </div>
      <div style="
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 24px;
          margin-top: 16px;
        ">
        <div class="body2" style="color: var(--color_gray_dark)">
          <i v-if="checkRole(['bia.manager'])" class="pi pi-pencil mr-1" v-tooltip.top="'Editar'"
            @click="altBpmDialog = true" style="font-size: 1.1rem; cursor: pointer; color: red"></i>
          <b>BPM:</b> {{ this.formData.data.process.bpm }}
        </div>
        <div class="body2" style="color: var(--color_gray_dark)">
          <i v-if="checkRole(['bia.manager'])" class="pi pi-pencil mr-1" v-tooltip.top="'Editar'"
            @click="altRexDialog = true" style="font-size: 1.1rem; cursor: pointer; color: red"></i>
          <b>REX:</b> {{ this.formData.data.process.rex }}
        </div>
      </div>
    </div>
    <div style="
        display: flex;
        flex-direction: row;
        gap: 80px;
        padding: 80px 0px 0px 0px;
      ">
      <div v-if="windowWidth >= 1366 && menuIsOpen == false" class="body1" ref="fixedComponent" style="
        display: flex;
        flex-direction: column;
        align-items: start; 
        position: fixed;
        top: 150px;
        min-width: 200px;
        gap: 4px;
      ">
        <div class="h4" style="margin-bottom: 16px">{{ this.$langGetValue('navigation') }}</div>
        <button class="linkButton" @click="scrollToDiv('generalDataSection')" style="
            display: flex;
            flex-direction: row;
            text-align: left;
            gap: 4px;
            cursor: pointer">
          1. {{ this.$langGetValue('generalDataBia') }}
        </button>
        <button class="linkButton" @click="scrollToDiv('systemSection')" style="
            display: flex;
            flex-direction: row;
            text-align: left;
            gap: 4px;
            cursor: pointer;
          ">
          2. {{ this.$langGetValue('systems') }}
        </button>
        <button class="linkButton" @click="scrollToDiv('thirdPartySection')" style="
            display: flex;
            flex-direction: row;
            text-align: left;
            gap: 4px;
            cursor: pointer;
          ">
          3. {{ this.$langGetValue('suppliers') }}
        </button>
        <button class="linkButton" @click="scrollToDiv('internalDependencySection')" style="
            display: flex;
            flex-direction: row;
            text-align: left;
            gap: 4px;
            cursor: pointer;
          ">
          4. {{ this.$langGetValue('internalDependencies') }}
        </button>
        <button class="linkButton" @click="scrollToDiv('dataSection')" style="
            display: flex;
            flex-direction: row;
            text-align: left;
            gap: 4px;
            cursor: pointer;">
          5. {{ this.$langGetValue('personalDataHandling') }}
        </button>
        <button class="linkButton" @click="scrollToDiv('impactSection')" style="
            display: flex;
            flex-direction: row;
            text-align: left;
            gap: 4px;
            cursor: pointer;">
          6. {{ this.$langGetValue('criticalityClassification') }}
        </button>
        <button class="linkButton" @click="scrollToDiv('timeSection')" style="
            display: flex;
            flex-direction: row;
            text-align: left;
            gap: 4px;
            cursor: pointer;">
          7. {{ this.$langGetValue('rtoRpo') }}
        </button>
        <button class="linkButton" @click="scrollToDiv('contingencySection')" style="
            display: flex;
            flex-direction: row;
            text-align: left;
            gap: 4px;
            cursor: pointer;">
          8. {{ this.$langGetValue('contingency') }}
        </button>
      </div>

      <div v-if="this.formData.status == 5 || this.formData.status == 6" style="
        display: flex;
        flex-direction: column;
        align-items: start;
        position: fixed;
        top: 80%;
        min-width: 200px;
        gap: 12px;
        background: #ffffff;
        z-index: 1099;
      " :class="{ 'card': windowWidth < 1366 }">
        <div v-if="this.formData.status == 5">
          <div style="font-size:14px; font-weight:bold">{{ this.$langGetValue('formRejectedByBPM') }}</div>
        </div>
        <div v-if="this.formData.status == 6">
          <div style="font-size:14px; font-weight:bold">{{ this.$langGetValue('formRejectedByRO') }}</div>
        </div>
        <Button style="width: 100%" :label="this.$langGetValue('viewJustification')"
          class="p-button p-component p-button-danger" @click="rejectJustificationDialog = true" />
      </div>

      <div v-if="windowWidth >= 1366 && menuIsOpen == false" :style="{
        width: divWidthSummary,
        marginRight: '96px',
        minWidth: '120px',
      }"></div>

      <div :style="{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '56px',
        justifyContent: 'space-between'
      }">
        <div class="h3" id="generalDataSection">1. {{ this.$langGetValue('generalDataBia') }}</div>
        <!-- Empresa, Capability e área do processo -->
        <div style="
            display: flex;
            flex-direction: row;
            width: 100%;
            max-width: 100%;
            justify-content: space-between;
            gap: 64px;
          ">
          <!-- Empresa -->
          <div style="
              display: flex;
              flex-direction: column;
              justify-content: start;
              width: 33%;
            ">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('company') }}</div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{
                this.$langGetValue('companyAssociatedWithProcess') }}
              </span>
            </div>
            <div v-if="this.formData.status == 4">
              <div v-if="companySelected.length > 3" style="gap: 4px" class="flex">
                <Chip class="secondaryChip" v-for="(company, index) in companySelected.slice(0, 3)" :key="index"
                  :label="company?.label" />
                <Chip class="secondaryChip" label="..."
                  v-tooltip.top="this.companySelected.slice(3).map(company => company.label).join(';\n')" />
              </div>
              <div v-else style="gap: 4px" class="flex">
                <Chip class="secondaryChip" v-for="(company, index) in companySelected" :key="index"
                  :label="company?.label" />
              </div>
            </div>
            <div v-else v-tooltip.top="this.companySelectedTooltip">
              <MultiSelect style="height: fit-content; width:100%" v-model="companySelected" optionLabel="label"
                :disabled="!editMode" :options="filteredCompany" optionGroupLabel="label" optionGroupChildren="children"
                :placeholder="this.$langGetValue('selectOption')" :maxSelectedLabels="3"
                :class="{ 'p-invalid': isInvalidGeneral.selectedCompany }" :filter="true" @filter="filterCompany">
                <template #optiongroup="slotProps">
                  <div class="flex items-center">
                    <div>{{ slotProps.option.label }}</div>
                  </div>
                </template>
                <template #value>
                  <div class="flex" style="flex-wrap: nowrap;">
                    <span v-for="(value, index) in companySelected.slice(0, 3)" :key="index">
                      {{ value.label }}<span v-if="index < Math.min(2, companySelected.length - 1)">, </span>
                    </span>
                    <span v-if="companySelected.length > 3">...</span>
                  </div>
                </template>
              </MultiSelect>
            </div>
          </div>

          <!-- Capability -->
          <div style="
              display: flex;
              flex-direction: column;
              justify-content: start;
              width: 33%;
            ">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('capability') }}</div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{
                this.$langGetValue('capabilityAssociatedWithProcess') }}
              </span>
            </div>
            <div v-if="this.formData.status == 4">
              <Chip :label="capabilitySelected?.label" class="secondaryChip" />
            </div>
            <div v-else>
              <Dropdown style="width: 100%" v-model="capabilitySelected" :disabled="!editMode" optionLabel="label"
                :options="biaOptions.capability" :placeholder="this.$langGetValue('selectOption')"
                :class="{ 'p-invalid': isInvalidGeneral.selectedCapability }" filter />
            </div>
          </div>

          <!-- Área -->
          <div v-if="processArea != null" style="
              display: flex;
              flex-direction: column;
              justify-content: start;
              width: 33%;
            ">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('area') }}</div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{
                this.$langGetValue('areaToWhichProcessBelongs') }}
              </span>
            </div>
            <Chip :label="processArea" class="secondaryChip" />
          </div>
        </div>

        <!-- Descrição do processo -->
        <div style="display: flex; flex-direction: row; width: 100%; gap: 64px"
          v-if="'description' in this.formData.data.process">
          <div style="display: flex; flex-direction: column; width: 100%">
            <div style="display: flex; align-items: start; gap: 4px">
              <i v-if="checkRole(['bia.manager'])" class="pi pi-pencil" v-tooltip.top="'Editar'"
                @click="altDescriptionDialog = true" style="font-size: 1.1rem; cursor: pointer; color: red"></i>
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('processDescription') }}
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('processNarrative') }}
              </span>
            </div>
            <div class="body2" style="color: var(--color_gray_medium)">
              {{ formData.data.process.description }}
            </div>
          </div>
        </div>

        <!-- Colaboradoes e Colaboradores Backup do processo -->
        <div style="
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 64px;
            align-items: start;
          ">
          <!-- Colaboradoes do processo -->
          <div style="display: flex; flex-direction: column; width: 100%">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('involvedEmployees') }}
              </div>
              <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipIndicateInvolvedEmployees')"></i>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{
                this.$langGetValue('employeesInvolvedInProcess') }}
              </span>
            </div>
            <AutoComplete class="customAutoComplete" field="user" style="height: fit-content;" :multiple="true"
              :disabled="!editMode" v-model="generalDataSelected.employees" :suggestions="filteredUsers"
              @complete="searchUsersLara($event, 'user|group')" :forceSelection="true"
              :class="{ 'p-invalid': isInvalidGeneral.employees }"
              :placeholder="generalDataSelected.employees.length == 0 ? this.$langGetValue('addPerson') : ''" />
          </div>

          <!-- Colaboradoes backup do processo -->
          <div style="display: flex; flex-direction: column; width: 100%">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('backupEmployees') }}
              </div>
              <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipIndicateBackupEmployees')"></i>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('employeesAbleToExecute')
              }}
              </span>
            </div>
            <AutoComplete class="customAutoComplete" field="user" style="height: fit-content" :multiple="true"
              :disabled="!editMode" v-model="generalDataSelected.employeesBackup" :suggestions="filteredUsers"
              @complete="searchUsersLara($event, 'user|group')" :forceSelection="true"
              :class="{ 'p-invalid': isInvalidGeneral.employeesBackup }"
              :placeholder="generalDataSelected.employeesBackup.length == 0 ? this.$langGetValue('addPerson') : ''" />
          </div>
        </div>

        <!-- Local/Escritório e Periodo de execução -->
        <div style="
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 64px;
            align-items: start;
          ">
          <!-- Local/Escritório do processo -->
          <div style="display: flex; flex-direction: column; width: calc(50% - 32px)">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('executionLocations') }}</div>
              <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipIndicateCriticalPeriods')"></i>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('officeLocations') }}
              </span>
            </div>
            <div v-tooltip.top="this.locationSelectedTooltip">
              <MultiSelect style="height: fit-content; width:100%" v-model="generalDataSelected.selectedExecutionLocation"
                optionLabel="label" :disabled="!editMode" :options="biaOptions.executionLocation"
                :placeholder="this.$langGetValue('selectLocation')" :maxSelectedLabels="3"
                :class="{ 'p-invalid': isInvalidGeneral.selectedExecutionLocation }" />
            </div>
          </div>

          <!-- Frequência do processo  -->
          <div style="display: flex; flex-direction: column; width: calc(50% - 32px)">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('processExecutionFrequency') }}
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('executionPeriod') }}
              </span>
            </div>
            <Dropdown v-model="generalDataSelected.selectedPeriod" :disabled="!editMode" optionLabel="label"
              :options="biaOptions.executionPeriods" :placeholder="this.$langGetValue('selectPeriod')"
              :class="{ 'p-invalid': isInvalidGeneral.selectedPeriod }" />
          </div>
        </div>

        <!-- Frequencias -->
        <div style="
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 64px;
            align-items: start;
          ">

          <div v-if="generalDataSelected.selectedPeriod?.id == 1"
            style="display: flex; flex-direction: column; width: calc(50% - 32px)">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('executions') }}
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('dailyExecutionCount') }}
              </span>
            </div>
            <InputNumber :min="1" :disabled="!editMode" v-model="generalDataSelected.dailyFrequency" showButtons
              :suffix="generalDataSelected.dailyFrequency > 1 ? ' vezes' : ' vez'" :useGrouping="false"
              :class="{ 'p-invalid': isInvalidGeneral.dailyFrequency }" />
          </div>

          <div v-if="generalDataSelected.selectedPeriod?.id == 2"
            style="display: flex; flex-direction: column; width: calc(50% - 32px)">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('weekDays') }}
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('weekDaysExecution') }}
              </span>
            </div>
            <MultiSelect style="height: fit-content" v-model="generalDataSelected.weeklyFrequency" :disabled="!editMode"
              :options="biaOptions.weekDays" :placeholder="this.$langGetValue('selectOption')" optionLabel="label"
              :class="{ 'p-invalid': isInvalidGeneral.weeklyFrequency }" />
          </div>

          <div v-if="generalDataSelected.selectedPeriod?.id == 3"
            style="display: flex; flex-direction: column; width: calc(50% - 32px)">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('daysOfMonth') }}
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('daysOfMonthExecution') }}
              </span>
            </div>
            <DatePicker selectionMode="multiple" semester="0" :value="this.generalDataSelected.monthFrequency"
              :showMonth="false" @returned-days="setupValueMothlyOcurrence" :disabled="!editMode"
              :class="{ 'datepickerInvalid': isInvalidGeneral.monthFrequency }">
            </DatePicker>
          </div>

          <div v-if="generalDataSelected.selectedPeriod?.id == 4"
            style="display: flex; flex-direction: column; width: 50%">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('firstSemester') }}
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('firstSemesterExecution')
              }}
              </span>
            </div>
            <DatePicker selectionMode="single" :value="this.generalDataSelected.firstSemesterFrequency" :showMonth="true"
              semester="1" @returned-days="setupValueFirstSemesterOcurrence" :disabled="!editMode"
              :class="{ 'datepickerInvalid': isInvalidGeneral.firstSemesterFrequency }">
            </DatePicker>
          </div>

          <div v-if="generalDataSelected.selectedPeriod?.id == 4"
            style="display: flex; flex-direction: column; width: 50%">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('secondSemester') }}
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('secondSemesterExecution')
              }}
              </span>
            </div>
            <DatePicker selectionMode="single" :value="this.generalDataSelected.secondSemesterFrequency" :showMonth="true"
              semester="2" @returned-days="setupValueSecondSemesterOcurrence" :disabled="!editMode"
              :class="{ 'datepickerInvalid': isInvalidGeneral.secondSemesterFrequency }">
            </DatePicker>
          </div>

          <div v-if="generalDataSelected.selectedPeriod?.id == 5"
            style="display: flex; flex-direction: column; width: calc(50% - 32px)">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('dayAndMonth') }}
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('dayAndMonthSelection') }}
              </span>
            </div>
            <DatePicker selectionMode="single" :value="generalDataSelected.yearFrequency" :showMonth="true" semester="0"
              @returned-days="setupValueAnnualyOcurrence" :disabled="!editMode"
              :class="{ 'datepickerInvalid': isInvalidGeneral.yearFrequency }">
            </DatePicker>
          </div>

          <div v-if="generalDataSelected.selectedPeriod?.id == 6"
            style="display: flex; flex-direction: column; width: 100%">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('otherOption') }}
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('otherScenarios') }}
              </span>
            </div>
            <InputText type="text" v-model="generalDataSelected.otherFrequency"
              :class="{ 'inputText': true, 'p-invalid': isInvalidGeneral.otherFrequency }" />
          </div>
        </div>

        <Dialog class="hourDialog" v-model:visible="addPeriod" modal
          style="width: fit-content; border-radius: 8px 8px 0px 0px" :showHeader="false" :dismissable-mask="true">
          <div v-if="this.hoursErrorMessage1 == true" class="text-center mt-2" style="width: 100%">
            <InlineMessage severity="error">{{ this.$langGetValue('timeConflict') }}</InlineMessage>
          </div>
          <div v-if="this.hoursErrorMessage2 == true" class="text-center mt-2" style="width: 100%">
            <InlineMessage severity="error">{{ this.$langGetValue('criticalTimeExceedsRange') }}</InlineMessage>
          </div>
          <div v-if="!hourDialogCriticalMode" class="h4 text-center mt-4 mb-6" style="font-weight: bold">
            {{ this.$langGetValue('addExecutionTime') }}
          </div>
          <div v-else class="h4 text-center mt-4 mb-6" style="font-weight: bold">
            {{ this.$langGetValue('addCriticalExecutionTime') }}
          </div>
          <div v-if="hourDialogCriticalMode" class="mt-4">
            <div class="h5 text-left" style="margin-bottom: 4px; font-weight: bold">
              {{ this.$langGetValue('executionTimes') }}
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{
                this.$langGetValue('selectPeriodCriticalExecution') }}
              </span>
            </div>
            <Dropdown v-model="selectedExecutionPeriod" :options="generalDataSelected.executionTimeRange"
              placeholder="Período">
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                  <div class="flex" style="font-size: 13px; gap: 8px">
                    <div class="flex">
                      {{ slotProps.value.startTime.hour + ' : ' + slotProps.value.startTime.minute }}
                    </div>
                    <div class="flex">
                      {{ this.$langGetValue('log5') }}
                    </div>
                    <div class="flex">
                      {{ slotProps.value.endTime.hour + ' : ' + slotProps.value.endTime.minute }}
                    </div>
                  </div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="flex" style="font-size: 13px; gap: 8px">
                  <div class="flex">
                    {{ slotProps.option.startTime.hour + ' : ' + slotProps.option.startTime.minute }}
                  </div>
                  <div class="flex">
                    {{ this.$langGetValue('log5') }}
                  </div>
                  <div class="flex">
                    {{ slotProps.option.endTime.hour + ' : ' + slotProps.option.endTime.minute }}
                  </div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="flex align-items-center mt-4" style="gap: 20px">
            <div class="flex align-items-center" style="gap:15px">
              <div class="flex flex-column">
                <Button icon="pi pi-angle-up" aria-label="Submit" @click="changeHour('increment', 'start'), checkHour()"
                  style="border-radius: 8px 8px 0px 0px; background-color: transparent; color: rgb(51, 51, 51); border: none; outline: var(--color_brand_medium);" />
                <div class="text-center changeHourMinutes">{{ startHour }}</div>
                <Button icon="pi pi-angle-down" aria-label="Submit" @click="changeHour('decrement', 'start'), checkHour()"
                  style="border-radius: 0px 0px 8px 8px; background-color: transparent; color: rgb(51, 51, 51); border: none; outline: var(--color_brand_medium);" />
              </div>
              <div class="flex">
                :
              </div>
              <div class="flex flex-column">
                <Button icon="pi pi-angle-up" aria-label="Submit" @click="changeMinute('increment', 'start'), checkHour()"
                  style="border-radius: 8px 8px 0px 0px; background-color: transparent; color: rgb(51, 51, 51); border: none; outline: var(--color_brand_medium);" />
                <div class="text-center changeHourMinutes">{{ startMinute }}</div>
                <Button icon="pi pi-angle-down" aria-label="Submit"
                  @click="changeMinute('decrement', 'start'), checkHour()"
                  style="border-radius: 0px 0px 8px 8px; background-color: transparent; color: rgb(51, 51, 51); border: none; outline: var(--color_brand_medium);" />
              </div>
            </div>
            <div>
              {{ this.$langGetValue('log5') }}
            </div>
            <div class="flex align-items-center" style="gap:15px">
              <div class="flex flex-column">
                <Button icon="pi pi-angle-up" aria-label="Submit" @click="changeHour('increment', 'final'), checkHour()"
                  style="border-radius: 8px 8px 0px 0px; background-color: transparent; color: rgb(51, 51, 51); border: none; outline: var(--color_brand_medium);" />
                <div class="text-center changeHourMinutes">{{ finalHour }}</div>
                <Button icon="pi pi-angle-down" aria-label="Submit" @click="changeHour('decrement', 'final'), checkHour()"
                  style="border-radius: 0px 0px 8px 8px; background-color: transparent; color: rgb(51, 51, 51); border: none; outline: var(--color_brand_medium);" />
              </div>
              <div class="flex">
                :
              </div>
              <div class="flex flex-column">
                <Button icon="pi pi-angle-up" aria-label="Submit" @click="changeMinute('increment', 'final'), checkHour()"
                  style="border-radius: 8px 8px 0px 0px; background-color: transparent; color: rgb(51, 51, 51); border: none; outline: var(--color_brand_medium);" />
                <div class="text-center changeHourMinutes">{{ finalMinute }}</div>
                <Button icon="pi pi-angle-down" aria-label="Submit"
                  @click="changeMinute('decrement', 'final'), checkHour()"
                  style="border-radius: 0px 0px 8px 8px; background-color: transparent; color: rgb(51, 51, 51); border: none; outline: var(--color_brand_medium);" />
              </div>
            </div>
          </div>
          <template #footer>
            <div class="flex justify-content-center" style="width: 100%">
              <Button v-if="hourDialogCriticalMode" label="Salvar horário" @click="saveHour(true)"
                :disabled="saveHourButtonDisabled || selectedExecutionPeriod == null" text severity="secondary"
                autofocus />
              <Button v-else :label="this.$langGetValue('saveTime')" @click="saveHour(false)"
                :disabled="saveHourButtonDisabled" text severity="secondary" autofocus />
            </div>
          </template>
        </Dialog>

        <div class="flex" style="width: 100%">
          <div class="flex flex-column" style="width: 50%">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('addExecutionTimes') }}
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{
                this.$langGetValue('indicateExecutionPeriods') }}
              </span>
            </div>
            <div class="scroll" style="overflow: scroll; max-height:250px"
              :class="{ 'executionHoursInvalid': isInvalidGeneral.executionTimeRange }">
              <div class="flex justify-content-start align-items-center" style="width: 100%; gap: 24px"
                v-for="(range, index) in generalDataSelected.executionTimeRange" :key="index">
                <div class="flex" style="font-size: 15px; margin: 5px; gap: 15px; width:160px">
                  <div class="flex">
                    {{ range.startTime.hour + ' : ' + range.startTime.minute }}
                  </div>
                  <div class="flex">
                    {{ this.$langGetValue('toBia') }}
                  </div>
                  <div class="flex">
                    {{ range.endTime.hour + ' : ' + range.endTime.minute }}
                  </div>
                </div>
                <button v-if="editMode" @click="removeExecutionTime(index, false)" class="negativeButton">
                  <i class="pi pi-trash"></i>
                </button>
              </div>
              <button v-if="editMode"
                style="width: fit-content; display: flex; gap: 8px; margin-top: 16px; cursor: pointer" class="linkButton"
                @click="addExecutionTime()">
                <i class="pi pi-plus" style="color: var(--color_brand_medium)"></i>
                <div class="body2" style="color: var(--color_brand_medium)">
                  {{ this.$langGetValue('addTimeRange') }}
                </div>
              </button>
            </div>
          </div>

          <!-- Janela de execução -->
          <div class="flex flex-column" style="width: 50%">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('addCriticalExecutionTimes') }}
              </div>
              <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipIndicateCriticalPeriods')"></i>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{
                this.$langGetValue('indicateCriticalExecutionPeriods') }}
              </span>
            </div>
            <div class="scroll" style="overflow: scroll; max-height:250px"
              :class="{ 'executionHoursInvalid': isInvalidGeneral.executionTimeRangeCritical }">
              <div class="flex justify-content-start align-items-center" style="width: 100%; gap: 24px"
                v-for="(range, index) in generalDataSelected.executionTimeRangeCritical" :key="index">
                <div class="flex" style="font-size: 15px; margin: 5px; gap: 15px; width:160px">
                  <div class="flex">
                    {{ range.startTime.hour + ' : ' + range.startTime.minute }}
                  </div>
                  <div class="flex">
                    {{ this.$langGetValue('toBia') }}
                  </div>
                  <div class="flex">
                    {{ range.endTime.hour + ' : ' + range.endTime.minute }}
                  </div>
                </div>
                <button v-if="editMode" @click="removeExecutionTime(index, true)" class="negativeButton">
                  <i class="pi pi-trash"></i>
                </button>
              </div>
              <button v-if="editMode"
                style="width: fit-content; display: flex; gap: 8px; margin-top: 16px; cursor: pointer" class="linkButton"
                @click="addExecutionTime(true)">
                <i class="pi pi-plus" style="color: var(--color_brand_medium)"></i>
                <div class="body2" style="color: var(--color_brand_medium)">
                  {{ this.$langGetValue('addTimeRange') }}
                </div>
              </button>
            </div>
          </div>
        </div>

        <!-- Janela de execução -->
        <div style="
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 64px;
            align-items: start;">
          <div style="width: 100%;">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('timeWindow') }}
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('periodsWhenExecuted') }}
              </span>
            </div>
            <div>
              <div class="flex justify-content-between" style="width: 300px;">
                <div class="flex legend">
                  <div class="square" style="background-color:rgb(25, 90, 180);"></div>
                  <span>{{ this.$langGetValue('normalHours') }}</span>
                </div>
                <div class="flex legend">
                  <div class="square" style="background-color:rgb(255, 77, 77);"></div>
                  <span>{{ this.$langGetValue('criticalHours') }}</span>
                </div>
              </div>

              <div class="scroll card flex justify-content-center" style="width:100%">
                <div v-for="i in 24" :key="i" class="justify-content-center" style="width: calc(100% / 24)">
                  <div class="flex flex-row align-items-start" style="font-size: 10px;">
                    {{ getValueHoursScheduler(i) }}
                  </div>
                  <div class="flex" style="width: 100%; border: 1px solid #cacaca;" :id="'hour_' + i + '_c1'">
                    <div style="width: 100%;" v-for="j in 60" :key="j">
                      <div :id="'hour_' + i + '_minute_' + j + '_l1'" style="height: 30px;"></div>
                    </div>
                  </div>
                  <div class="flex" style="width: 100%; border: 1px solid #cacaca;" :id="'hour_' + i + '_c2'">
                    <div style="width: 100%;" v-for="j in 60" :key="j">
                      <div :id="'hour_' + i + '_minute_' + j + '_l2'" style="height: 30px;"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="h3" id="systemSection" style="margin-top: 30px">
          2. {{ this.$langGetValue('systems') }}
        </div>
        <div class="flex align-items-end" style="gap: 20px">

          <div style="display: flex; flex-direction: column; width: 33%">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('system') }}</div>
              <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipMapCriticalSystems')"></i>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('doesProcessUseSystem') }}
              </span>
            </div>
            <Dropdown :disabled="!editMode" style="width: 100%; height:45px" v-model="systemConditional"
              :options="[this.$langGetValue('yes'), this.$langGetValue('no')]"
              :class="{ 'p-invalid': isInvalidGeneral.system }" />
          </div>

          <div v-if="systemConditional == this.$langGetValue('yes') && editMode">
            <button class="primaryButton" style="
              display: flex; height:45px; cursor: pointer; width: 220px;
            " @click="openSystem">
              <i class="pi pi-plus"></i>
              <div class="body2" style="font-weight: 600; color: white">
                {{ this.$langGetValue('addSystem') }}
              </div>
            </button>
          </div>
        </div>

        <div v-if="systemConditional == this.$langGetValue('yes')" style="
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 16px;
            margin-top: -32px;
          ">
          <DataTable class="customDataTable " v-show="systemsListSelected.length > 0"
            v-model:expandedRows="expandedRowsSystem" :value="systemsListSelected" style="margin-top: 24px">
            <Column expander style="width: 3rem; padding-right: 0"> </Column>
            <Column style="padding-left: 0" field="header">
              <template #body="slotProps">
                <div class="flex justify-content-between">
                  <div class="flex align-items-center ml-2" style="height: 50px">
                    <Chip style="font-size: 14px" :label="slotProps.data.system.label" class="secondaryChip" />
                  </div>
                  <div class="flex" style="gap: 8px">
                    <button v-if="editMode" style="border: none; cursor: pointer" class="secondaryButton"
                      @click="systemEditMode = true; systemEditIndex = slotProps.index; systemSelected = { ...slotProps.data }; newSystem = true">
                      <i class="pi pi-pencil"></i>
                    </button>
                    <button v-if="editMode" style="border: none; color: #e96767; cursor: pointer" class="secondaryButton"
                      @click="handleDeleteSystem(slotProps.index)">
                      <i class="pi pi-trash"></i>
                    </button>
                  </div>
                </div>
              </template>
            </Column>

            <template #expansion="slotProps">
              <!-- {{ slotProps }} -->

              <div class="flex flex-column" style="padding: 24px 42px 24px 42px">
                <div class="flex" style="width: 100%; gap:20px">
                  <div class="flex flex-column justify-content-start" style="padding-top: 24px; width: calc(40% - 20px)">
                    <div class="flex">
                      <b>{{ this.$langGetValue('potentialToHaltProcess') }}</b>
                    </div>
                    <div class="flex" style="padding-top: 8px">
                      <Chip class="secondaryChip" :label="slotProps.data.paralyzingPotencial" />
                    </div>
                  </div>
                  <div v-if="slotProps.data.contingencyStrategy != null" class="flex flex-column justify-content-start"
                    style="padding-top: 24px; width: calc(40% - 20px)">
                    <div class="flex">
                      <b>{{ this.$langGetValue('contingencyStrategy') }}</b>
                    </div>
                    <div class="flex" style="padding-top: 8px">
                      <Chip class="secondaryChip" :label="slotProps.data.contingencyStrategy.label" />
                    </div>
                  </div>
                  <div v-if="slotProps.data.contingencyBackup != null" class="flex flex-column justify-content-start"
                    style="padding-top: 24px; ; width: calc(20% - 20px)">
                    <div class="flex">
                      <b>{{ this.$langGetValue('backupSystem') }}</b>
                    </div>
                    <div class="flex" style="padding-top: 8px">
                      <Chip class="secondaryChip" :label="slotProps.data.contingencyBackup.label" />
                    </div>
                  </div>
                </div>
                <div class="flex" style="padding: 30px 0px 24px 0px; width: 100%; gap:20px">
                  <div class="flex flex-column justify-content-start"
                    :style="{ width: slotProps.data.contingencyDescription !== null ? 'calc(40% - 20px)' : 'calc(80% - 20px)' }">
                    <div class="flex">
                      <b>{{ this.$langGetValue('systemUsageDescription') }}</b>
                    </div>
                    <div class="flex" style="padding-top: 8px">
                      {{ slotProps.data.description }}
                    </div>
                  </div>
                  <div v-if="slotProps.data.contingencyDescription !== null"
                    class="flex flex-column justify-content-start" style="width: calc(40% - 20px)">
                    <div class="flex">
                      <b>{{ this.$langGetValue('contingencyDescription') }}</b>
                    </div>
                    <div class="flex" style="padding-top: 8px">
                      {{ slotProps.data.contingencyDescription }}
                    </div>
                  </div>
                  <div style="width: calc(20% - 20px)">
                  </div>
                </div>
              </div>
            </template>
          </DataTable>

          <Dialog class="scrollDialog dialogBorder" v-model:visible="newSystem" modal :dismissable-mask="true"
            :header="this.systemEditMode ? this.$langGetValue('editSystem') : this.$langGetValue('addSystem')"
            style="width: 60%; max-width: 900px">
            <!-- Nome sistema -->
            <div style="
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                gap: 64px;
                align-items: start;
              ">
              <!-- Nome sistema -->
              <div style="
                  display: flex;
                  flex-direction: column;
                  min-width: fit-content;
                  width: 100%;
                  padding: 0px;
                ">
                <div style="display: flex; align-items: start; gap: 4px">
                  <i style="color: var(--color_feedback_negative); cursor: help"
                    :title="this.$langGetValue('fieldRequired')">*</i>
                  <div class="h4" style="margin-bottom: 4px">
                    {{ this.$langGetValue('system') }}
                  </div>
                </div>
                <div style="margin-bottom: 16px">
                  <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('findSystemInList') }}
                  </span>
                </div>
                <Dropdown v-model="systemSelected.system" filter :options="biaOptions.system"
                  :class="{ 'p-invalid': isInvalidSystem.system }" optionLabel="label"
                  :placeholder="this.$langGetValue('selectSystem')" style="width: 100%" />
              </div>
            </div>

            <!--Descrição sistema-->
            <div style="
                margin-top: 24px;
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                gap: 64px;
                align-items: start;
              ">
              <!-- Descrição -->
              <div style="
                  display: flex;
                  flex-direction: column;
                  min-width: fit-content;
                  width: 100%;
                ">
                <div style="display: flex; align-items: start; gap: 4px">
                  <i style="color: var(--color_feedback_negative); cursor: help"
                    :title="this.$langGetValue('fieldRequired')">*</i>
                  <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('description') }}</div>
                </div>
                <div style="margin-bottom: 16px">
                  <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('systemDescription')
                  }}
                  </span>
                </div>
                <Textarea v-model="systemSelected.description" :class="{ 'p-invalid': isInvalidSystem.description }"
                  rows="5" cols="30" style="resize: none;" />
              </div>
            </div>

            <!-- Potencial de paralisar o processo e Estratégia de contingência-->
            <div style="
                margin-top: 24px;
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                gap: 64px;
                align-items: start;
              ">
              <!-- Potencial de paralisar o processo -->
              <div style="display: flex; flex-direction: column; width: 100%">
                <div style="display: flex; align-items: start; gap: 4px">
                  <i style="color: var(--color_feedback_negative); cursor: help"
                    :title="this.$langGetValue('fieldRequired')">*</i>
                  <div class="h4" style="margin-bottom: 4px">
                    {{ this.$langGetValue('potentialToHaltProcess') }}
                  </div>
                </div>
                <div style="margin-bottom: 16px">
                  <span class="body3" style="color: var(--color_gray_medium)">{{
                    this.$langGetValue('couldInterruptionForceContingency') }}
                  </span>
                </div>
                <Dropdown v-model="systemSelected.paralyzingPotencial" :class="{ 'p-invalid': isInvalidSystem.freeze }"
                  :options="[this.$langGetValue('yes'), this.$langGetValue('no')]"
                  :placeholder="this.$langGetValue('selectOption')" style="width: 100%" />
              </div>
              <!-- Estratégia de contingência -->
              <div style="display: flex; flex-direction: column; width: 100%">
                <div style="display: flex; align-items: start; gap: 4px">
                  <i v-if="systemSelected.paralyzingPotencial == this.$langGetValue('yes')"
                    style="color: var(--color_feedback_negative); cursor: help"
                    :title="this.$langGetValue('fieldRequired')">*</i>
                  <div class="h4" style="margin-bottom: 4px">
                    {{ this.$langGetValue('contingencyStrategy') }}
                  </div>
                </div>
                <div style="margin-bottom: 16px">
                  <span class="body3" style="color: var(--color_gray_medium)">{{
                    this.$langGetValue('typesOfContingencyStrategies') }}
                  </span>
                </div>
                <Dropdown v-model="systemSelected.contingencyStrategy"
                  :class="{ 'p-invalid': isInvalidSystem.contingency }" :options="biaOptions.contigencyStrategySystem"
                  optionLabel="label" :placeholder="this.$langGetValue('selectOption')" style="width: 100%" />
              </div>
            </div>

            <!--Descrição contingência-->
            <div v-if="systemSelected.contingencyStrategy != null && systemSelected.contingencyStrategy.id != 1" style="
                margin-top: 24px;
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                gap: 64px;
                align-items: start;
              ">
              <!-- Descrição -->
              <div style="
                  display: flex;
                  flex-direction: column;
                  min-width: fit-content;
                  width: 100%;
                ">
                <div style="display: flex; align-items: start; gap: 4px">
                  <i style="color: var(--color_feedback_negative); cursor: help"
                    :title="this.$langGetValue('fieldRequired')">*</i>
                  <div class="h4" style="margin-bottom: 4px">
                    {{ this.$langGetValue('contingencyDescription') }}
                  </div>
                </div>
                <div style="margin-bottom: 16px">
                  <span class="body3" style="color: var(--color_gray_medium)">
                    {{ this.$langGetValue('contingencyDescriptionSubLabel') }}
                  </span>
                </div>
                <Textarea v-model="systemSelected.contingencyDescription"
                  :class="{ 'p-invalid': isInvalidSystem.contingencyDescription }" rows="5" cols="30"
                  style="resize: none;" />
              </div>
            </div>

            <!-- Sistema backup-->
            <div style="
                margin-top: 24px;
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                gap: 64px;
                align-items: start;
              " v-if="systemSelected.contingencyStrategy != null && systemSelected.contingencyStrategy.id == 4">
              <!-- Sistema backup -->
              <div style="
                  display: flex;
                  flex-direction: column;
                  min-width: fit-content;
                  width: 100%;
                ">
                <div style="display: flex; align-items: start; gap: 4px">
                  <i style="color: var(--color_feedback_negative); cursor: help"
                    :title="this.$langGetValue('fieldRequired')">*</i>
                  <div class="h4" style="margin-bottom: 4px">
                    {{ this.$langGetValue('backupSystem') }}
                  </div>
                </div>
                <div style="margin-bottom: 16px">
                  <span class="body3" style="color: var(--color_gray_medium); width: 100%">{{
                    this.$langGetValue('backupSystemSubLabel') }}
                  </span>
                </div>
                <Dropdown v-model="systemSelected.contingencyBackup" filter :options="biaOptions.system"
                  optionLabel="label" :placeholder="this.$langGetValue('selectSystem')"
                  :class="{ 'p-invalid': isInvalidSystem.backupSystem }" style="width: 100%" />
              </div>
            </div>

            <div style="
                margin-top: 48px;
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: center;
                gap: 64px;
                align-items: center;
              ">
              <button v-if="systemEditMode == false" class="primaryButton" style="
                  cursor:pointer;
                  display: flex;
                  align-items: center;
                  justify-content: start;
                  gap: 8px;
                  width: fit-content; cursor: pointer
                " @click="handleAddNewSystem">
                <i class="pi pi-plus"></i>
                <div class="body2" style="font-weight: 600; color: white">
                  {{ this.$langGetValue('addSystem') }}
                </div>
              </button>
              <button v-else class="primaryButton" style="
                  cursor:pointer;
                  display: flex;
                  align-items: center;
                  justify-content: start;
                  gap: 8px;
                  width: fit-content; cursor: pointer
                " @click="handleEditSystem">
                <i class="pi pi-plus"></i>
                <div class="body2" style="font-weight: 600; color: white">
                  {{ this.$langGetValue('editSystem') }}
                </div>
              </button>
            </div>
          </Dialog>
        </div>

        <div class="h3" id="thirdPartySection" style="margin-top: 30px">
          3. {{ this.$langGetValue('suppliers') }}
        </div>

        <div class="flex align-items-end" style="gap: 20px">
          <div style="display: flex; flex-direction: column; width: 33%">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('supplier') }}</div>
              <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipMapCriticalSuppliers')"></i>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{
                this.$langGetValue('doesProcessDependOnSupplier') }}
              </span>
            </div>
            <Dropdown :disabled="!editMode" style="width: 100%; height:45px" v-model="supplierConditional"
              :options="[this.$langGetValue('yes'), this.$langGetValue('no')]"
              :class="{ 'p-invalid': isInvalidGeneral.supplier }" />
          </div>

          <div v-if="supplierConditional == this.$langGetValue('yes') && editMode">
            <button class="primaryButton" style="display: flex; height:45px; cursor: pointer; width: 220px;"
              @click="openSupplier">
              <i class="pi pi-plus"></i>
              <div class="body2" style="font-weight: 600; color: white">
                {{ this.$langGetValue('addSupplier') }}
              </div>
            </button>
          </div>
        </div>

        <div style="
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 16px;
            margin-top: -32px;
          ">

          <DataTable class="customDataTable"
            v-show="supplierConditional == this.$langGetValue('yes') && supplierListSelected.length > 0"
            v-model:expandedRows="expandedRowsSystem" :value="supplierListSelected" style="margin-top: 24px">
            <Column expander style="width: 3rem; padding-right: 0"> </Column>
            <Column style="padding-left: 0" field="header">
              <template #body="slotProps">
                <div class="flex justify-content-between">
                  <div class="flex align-items-center ml-2" style="height: 50px">
                    <Chip style="font-size: 14px" :label="slotProps.data.supplier.label" class="secondaryChip" />
                  </div>
                  <div class="flex" style="gap: 8px">
                    <button v-if="editMode" style="border: none; cursor: pointer" class="secondaryButton"
                      @click="supplierEditMode = true; supplierEditIndex = slotProps.index; supplierSelected = { ...slotProps.data }; newSupplier = true">
                      <i class="pi pi-pencil"></i>
                    </button>
                    <button v-if="editMode" style="border: none; color: #e96767; cursor: pointer" class="secondaryButton"
                      @click="handleDeleteSupplier(slotProps.index)">
                      <i class="pi pi-trash"></i>
                    </button>
                  </div>
                </div>
              </template>
            </Column>

            <template #expansion="slotProps">
              <!-- {{ slotProps }} -->

              <div class="flex flex-column" style="padding: 24px 42px 24px 42px">
                <div class="flex" style="width: 100%; gap:20px">
                  <div class="flex flex-column justify-content-start" style="padding-top: 24px; width: calc(40% - 20px)">
                    <div class="flex">
                      <b>{{ this.$langGetValue('potentialToHaltProcess') }}</b>
                    </div>
                    <div class="flex" style="padding-top: 8px">
                      <Chip class="secondaryChip" :label="slotProps.data.paralyzingPotencial" />
                    </div>
                  </div>
                  <div v-if="slotProps.data.contingencyStrategy != null" class="flex flex-column justify-content-start"
                    style="padding-top: 24px; width: 40%">
                    <div class="flex">
                      <b>{{ this.$langGetValue('contingencyStrategy') }}</b>
                    </div>
                    <div class="flex" style="padding-top: 8px">
                      <Chip class="secondaryChip" :label="slotProps.data.contingencyStrategy.label" />
                    </div>
                  </div>
                </div>
                <div class="flex" style="padding: 30px 0px 24px 0px; width: 100%; gap:20px">
                  <div class="flex flex-column justify-content-start"
                    :style="{ width: slotProps.data.contingencyDescription !== null ? 'calc(40% - 20px)' : 'calc(80% - 20px)' }">
                    <div class="flex">
                      <b>{{ this.$langGetValue('supplierRoleDescription') }}</b>
                    </div>
                    <div class="flex" style="padding-top: 8px">
                      {{ slotProps.data.description }}
                    </div>
                  </div>
                  <div v-if="slotProps.data.contingencyDescription !== null"
                    class="flex flex-column justify-content-start" style="width: calc(40% - 20px)">
                    <div class="flex">
                      <b>{{ this.$langGetValue('contingencyDescription') }}</b>
                    </div>
                    <div class="flex" style="padding-top: 8px">
                      {{ slotProps.data.contingencyDescription }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </DataTable>

          <Dialog class="scrollDialog dialogBorder" v-model:visible="newSupplier" modal :dismissable-mask="true"
            :header="this.supplierEditMode ? this.$langGetValue('editSupplier') : this.$langGetValue('addSupplier')"
            style="width: 800px;">
            <!-- Tipo fornecedor e Nome fornecedor-->
            <div style="
                margin-top: 24px;
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                gap: 64px;
                align-items: start;
              ">
              <!-- Tipo -->
              <div style="display: flex; flex-direction: column; width: 100%">
                <div style="display: flex; align-items: start; gap: 4px">
                  <i style="color: var(--color_feedback_negative); cursor: help"
                    :title="this.$langGetValue('fieldRequired')">*</i>
                  <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('type') }}</div>
                </div>
                <div style="margin-bottom: 16px">
                  <span class="body3" style="color: var(--color_gray_medium)">{{
                    this.$langGetValue('typeCharacterizingSupplier') }}
                  </span>
                </div>
                <Dropdown v-model="supplierSelected.type" :class="{ 'p-invalid': isInvalidSupplier.type }"
                  :options="biaOptions.supplierType" optionLabel="label" :placeholder="this.$langGetValue('selectType')"
                  style="width: calc(379px - 32px)" />
              </div>
              <!-- Fornecedor -->
              <div style="display: flex; flex-direction: column; width: 100%">
                <div style="display: flex; align-items: start; gap: 4px">
                  <i style="color: var(--color_feedback_negative); cursor: help"
                    :title="this.$langGetValue('fieldRequired')">*</i>
                  <div class="h4" style="margin-bottom: 4px">
                    {{ this.$langGetValue('supplierName') }}
                  </div>
                </div>
                <div style="margin-bottom: 16px">
                  <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('enterSupplierName')
                  }}
                  </span>
                </div>
                <Dropdown v-model="supplierSelected.supplier" :class="{ 'p-invalid': isInvalidSupplier.supplier }" filter
                  :options="biaOptions.supplier" optionLabel="label" :placeholder="this.$langGetValue('addSupplier')"
                  style="width: calc(379px - 32px)" />
              </div>
            </div>

            <!--Descrição fornecedor-->
            <div style="
                margin-top: 24px;
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                gap: 64px;
                align-items: start;
              ">
              <!-- Descrição -->
              <div style="
                  display: flex;
                  flex-direction: column;
                  min-width: fit-content;
                  width: 100%;
                ">
                <div style="display: flex; align-items: start; gap: 4px">
                  <i style="color: var(--color_feedback_negative); cursor: help"
                    :title="this.$langGetValue('fieldRequired')">*</i>
                  <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('description') }}</div>
                </div>
                <div style="margin-bottom: 16px">
                  <span class="body3" style="color: var(--color_gray_medium)">{{
                    this.$langGetValue('describeSupplierInvolvement') }}
                  </span>
                </div>
                <Textarea v-model="supplierSelected.description" :class="{ 'p-invalid': isInvalidSupplier.description }"
                  rows="5" cols="30" style="resize: none;" />
              </div>
            </div>

            <!-- Potencial de paralisar o processo e Estratégia de contingência-->
            <div style="
                margin-top: 24px;
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                gap: 64px;
                align-items: start;
              ">
              <!-- Potencial de paralisar o processo -->
              <div style="display: flex; flex-direction: column; width: 100%">
                <div style="display: flex; align-items: start; gap: 4px">
                  <i style="color: var(--color_feedback_negative); cursor: help"
                    :title="this.$langGetValue('fieldRequired')">*</i>
                  <div class="h4" style="margin-bottom: 4px">
                    {{ this.$langGetValue('potentialToHaltProcess') }}
                  </div>
                </div>
                <div style="margin-bottom: 16px">
                  <span class="body3" style="color: var(--color_gray_medium)">{{
                    this.$langGetValue('couldSupplierAbsenceForceContingency') }}
                  </span>
                </div>
                <Dropdown v-model="supplierSelected.paralyzingPotencial"
                  :class="{ 'p-invalid': isInvalidSupplier.freeze }"
                  :options="[this.$langGetValue('yes'), this.$langGetValue('no')]"
                  :placeholder="this.$langGetValue('selectOption')" style="width: 100%" />
              </div>
              <!-- Estratégia de contingência -->
              <div style="display: flex; flex-direction: column; width: 100%">
                <div style="display: flex; align-items: start; gap: 4px">
                  <i v-if="supplierSelected.paralyzingPotencial == this.$langGetValue('yes')"
                    style="color: var(--color_feedback_negative); cursor: help"
                    :title="this.$langGetValue('fieldRequired')">*</i>
                  <div class="h4" style="margin-bottom: 4px">
                    {{ this.$langGetValue('contingencyStrategy') }}
                  </div>
                </div>
                <div style="margin-bottom: 16px">
                  <span class="body3" style="color: var(--color_gray_medium)">{{
                    this.$langGetValue('typesOfContingencyStrategiesSupplier') }}
                  </span>
                </div>
                <Dropdown v-model="supplierSelected.contingencyStrategy"
                  :class="{ 'p-invalid': isInvalidSupplier.contingency }" :options="biaOptions.supplierContingency"
                  :placeholder="this.$langGetValue('selectOption')" optionLabel="label" style="width: 100%" />
              </div>
            </div>

            <!--Descrição da contingência-->
            <div style="
                margin-top: 24px;
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                gap: 64px;
                align-items: start;
              " v-if="supplierSelected.contingencyStrategy != null && supplierSelected.contingencyStrategy.id != 1">
              <!-- Descrição -->
              <div style="
                  display: flex;
                  flex-direction: column;
                  min-width: fit-content;
                  width: 100%;
                ">
                <div style="display: flex; align-items: start; gap: 4px">
                  <i style="color: var(--color_feedback_negative); cursor: help"
                    :title="this.$langGetValue('fieldRequired')">*</i>
                  <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('contingencyDescription') }}</div>
                </div>
                <div style="margin-bottom: 16px">
                  <span class="body3" style="color: var(--color_gray_medium)">{{
                    this.$langGetValue('contingencyDescriptionSubLabel') }}
                  </span>
                </div>
                <Textarea v-model="supplierSelected.contingencyDescription"
                  :class="{ 'p-invalid': isInvalidSupplier.contingencyDescription }" rows="5" cols="30"
                  style="resize: none;" />
              </div>
            </div>
            <div style="
                margin-top: 48px;
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: center;
                gap: 64px;
                align-items: center;
              ">
              <button v-if="supplierEditMode == false" class="primaryButton" style="
                  cursor:pointer;
                  display: flex;
                  align-items: center;
                  justify-content: start;
                  gap: 8px;
                  width: fit-content;
                " @click="handleAddNewSupplier">
                <i class="pi pi-plus"></i>
                <div class="body2" style="font-weight: 600; color: white">
                  {{ this.$langGetValue('addSupplier') }}
                </div>
              </button>
              <button v-else class="primaryButton" style="
                  cursor:pointer;
                  display: flex;
                  align-items: center;
                  justify-content: start;
                  gap: 8px;
                  width: fit-content;
                " @click="handleEditSupplier">
                <i class="pi pi-plus"></i>
                <div class="body2" style="font-weight: 600; color: white">
                  {{ this.$langGetValue('editSupplier') }}
                </div>
              </button>
            </div>
          </Dialog>
        </div>

        <div class="h3" id="internalDependencySection" style="margin-top: 30px">
          4. {{ this.$langGetValue('internalDependencies') }}
        </div>

        <div class="flex align-items-end" style="gap: 20px">
          <div style="display: flex; flex-direction: column; width: 33%">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('internalDependency') }}</div>
              <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipIndicateInternalDependencies')"></i>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{
                this.$langGetValue('doesProcessDependOnOtherAreas') }}
              </span>
            </div>
            <Dropdown :disabled="!editMode" style="width: 100%; height:45px" v-model="internalDependencyConditional"
              :class="{ 'p-invalid': isInvalidGeneral.internalDependency }"
              :options="[this.$langGetValue('yes'), this.$langGetValue('no')]" />
          </div>

          <div v-if="internalDependencyConditional == this.$langGetValue('yes') && editMode">
            <button class="primaryButton" style="display: flex; height:45px; cursor: pointer; width: 220px;"
              @click="openDependency">
              <i class="pi pi-plus"></i>
              <div class="body2" style="font-weight: 600; color: white">
                {{ this.$langGetValue('addDependency') }}
              </div>
            </button>
          </div>
        </div>

        <div style="
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 16px;
            margin-top: -32px;
          ">

          <DataTable class="customDataTable"
            v-show="internalDependencyConditional == this.$langGetValue('yes') && internalDependencyListSelected.length > 0"
            v-if="reloadInternalDependency" v-model:expandedRows="expandedRowsSystem"
            :value="internalDependencyListSelected" style="margin-top: 24px">
            <Column expander style="width: 3rem; padding-right: 0"> </Column>
            <Column style="padding-left: 0" field="header">
              <template #body="slotProps">
                <div class="flex justify-content-between">
                  <div class="flex align-items-center ml-2" style="height: 50px">
                    <HierarchyComponent mode="view" :selectedAreas="slotProps.data.area" selection="single"
                      type="originated"></HierarchyComponent>
                  </div>
                  <div class="flex" style="gap: 8px">
                    <button v-if="editMode" style="border: none; cursor: pointer" class="secondaryButton"
                      @click="internalDependencyEditMode = true; internalDependencyIndex = slotProps.index; internalDependencySelected = { ...slotProps.data }; newInternalDependency = true">
                      <i class="pi pi-pencil"></i>
                    </button>
                    <button v-if="editMode" style="border: none; color: #e96767; cursor: pointer" class="secondaryButton"
                      @click="handleDeleteInternalDependency(slotProps.index)">
                      <i class="pi pi-trash"></i>
                    </button>
                  </div>
                </div>
              </template>
            </Column>

            <template #expansion="slotProps">
              <!-- {{ slotProps }} -->

              <div class="flex flex-column" style="padding: 24px 42px 24px 42px">
                <div class="flex" style="width: 100%; gap:20px">
                  <div class="flex flex-column justify-content-start" style="padding-top: 24px; width:40%">
                    <div class="flex">
                      <b>{{ this.$langGetValue('dependencyType') }}</b>
                    </div>
                    <div class="flex" style="padding-top: 8px">
                      <Chip class="secondaryChip" :label="slotProps.data.type.label" />
                    </div>
                  </div>
                </div>
                <div class="flex" style="padding: 30px 0px 24px 0px; width: 100%; gap:20px">
                  <div class="flex flex-column justify-content-start" :style="{ width: '80%' }">
                    <div class="flex">
                      <b>{{ this.$langGetValue('dependencyRoleDescription') }}</b>
                    </div>
                    <div class="flex" style="padding-top: 8px">
                      {{ slotProps.data.description }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </DataTable>

          <Dialog class="customDialogDependency" v-model:visible="newInternalDependency" modal
            :header="internalDependencyEditMode == false ? this.$langGetValue('addDependency') : this.$langGetValue('editDependency')"
            :dismissable-mask="true">

            <div class="flex" style="width:100%; padding:20px;">
              <div style="width: calc(50% - 10px); background: white; padding: 15px; border-radius: 12px">
                <!-- Área -->
                <div style="display: flex; flex-direction: column; width: 100%; margin-top: 20px">
                  <div style="display: flex; align-items: start; gap: 4px">
                    <i style="color: var(--color_feedback_negative); cursor: help"
                      :title="this.$langGetValue('fieldRequired')">*</i>
                    <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('area') }}</div>
                  </div>
                  <div style="margin-bottom: 16px">
                    <span class="body3" style="color: var(--color_gray_medium)">{{
                      this.$langGetValue('selectAreaForDependency') }}
                    </span>
                  </div>
                  <div style="border-radius: 8px"
                    :style="{ 'border': isInvalidDependency.area ? '1px solid red' : 'none' }">
                    <HierarchyComponent v-if="showHierarchy" size="280px" mode="edit" selection="single" type="originated"
                      @returned-areas="setAreaDependency" :selectedAreas="internalDependencySelected.area">
                    </HierarchyComponent>
                  </div>
                </div>
              </div>

              <div style="width: 10px; background: transparent"></div>

              <!-- fim -->
              <div style="width: calc(50% - 10px); background: white; padding: 15px; border-radius: 12px">
                <div class="flex justify-content-end" style="width: 100%; height: 20px">
                  <Button style="border: none; background: white; color: black;" icon="pi pi-times" severity="danger"
                    @click="newInternalDependency = false" text rounded />
                </div>
                <!-- Tipo fornecedor e Área de dependência interna -->
                <div style="
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                gap: 64px;
                align-items: start;
              ">
                  <!-- Tipo -->
                  <div style="display: flex; flex-direction: column; width: 100%">
                    <div style="display: flex; align-items: start; gap: 4px">
                      <i style="color: var(--color_feedback_negative); cursor: help"
                        :title="this.$langGetValue('fieldRequired')">*</i>
                      <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('type') }}</div>
                    </div>
                    <div style="margin-bottom: 16px">
                      <span class="body3" style="color: var(--color_gray_medium)">{{
                        this.$langGetValue('chooseDependencyType') }}
                      </span>
                    </div>
                    <Dropdown :class="{ 'p-invalid': isInvalidDependency.type }" v-model="internalDependencySelected.type"
                      :options="biaOptions.dependencyType" optionLabel="label"
                      :placeholder="this.$langGetValue('selectType')" :style="{ width: '100%', }" />
                  </div>
                </div>

                <!--Descrição fornecedor-->
                <div style="
                margin-top: 24px;
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                gap: 64px;
                align-items: start;
              ">
                  <!-- Descrição -->
                  <div style="
                  display: flex;
                  flex-direction: column;
                  min-width: fit-content;
                  width: 100%;
                ">
                    <div style="display: flex; align-items: start; gap: 4px">
                      <i style="color: var(--color_feedback_negative); cursor: help"
                        :title="this.$langGetValue('fieldRequired')">*</i>
                      <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('description') }}</div>
                    </div>
                    <div style="margin-bottom: 16px">
                      <span class="body3" style="color: var(--color_gray_medium)">{{
                        this.$langGetValue('describeDependency') }}
                      </span>
                    </div>
                    <Textarea :class="{ 'p-invalid': isInvalidDependency.description }"
                      v-model="internalDependencySelected.description" rows="13" cols="30" style="resize: none;" />
                  </div>
                </div>

                <div class="flex justify-content-center mt-4" style="width:100%">
                  <button v-if="internalDependencyEditMode == false" class="primaryButton" style="
                  cursor:pointer;
                  display: flex;
                  align-items: center;
                  justify-content: start;
                  gap: 8px;
                  width: fit-content;
                " @click="handleAddNewInternalDependency">
                    <i class="pi pi-plus"></i>
                    <div class="body2" style="font-weight: 600; color: white">
                      {{ this.$langGetValue('addDependency') }}
                    </div>
                  </button>
                  <button v-else class="primaryButton" style="
                  cursor:pointer;
                  display: flex;
                  align-items: center;
                  justify-content: start;
                  gap: 8px;
                  width: fit-content;
                " @click="handleEditInternalDependency">
                    <i class="pi pi-plus"></i>
                    <div class="body2" style="font-weight: 600; color: white">
                      {{ this.$langGetValue('editDependency') }}
                    </div>
                  </button>
                </div>
              </div>
            </div>

          </Dialog>
        </div>

        <div>
          <div class="h3" id="dataSection" style="margin-top: 80px">5. {{ this.$langGetValue('personalDataHandlingLGPD')
          }}
          </div>
          <div style="margin-bottom: 16px; margin-top: 5px;">
            <span class="body3" style="color: var(--color_gray_medium)">{{
              this.$langGetValue('personalDataHandlingSublabel') }}
            </span>
          </div>
        </div>

        <!-- Informações pessoais, Informações condifenciais e Informações sensíveis -->
        <div style="
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 64px;
            align-items: start;
            margin-top: -32px;
          ">
          <!-- Informações pessoais -->
          <div style="display: flex; flex-direction: column; width: 100%">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('personalInformation') }}
              </div>
              <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipPersonalInfoDescription')"></i>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{
                this.$langGetValue('doesProcessHandlePersonalInformation') }}
              </span>
            </div>
            <Dropdown :disabled="!editMode" v-model="dataSectionSelected.personalInformation"
              :options="[this.$langGetValue('yes'), this.$langGetValue('no')]"
              :placeholder="this.$langGetValue('selectOption')"
              :class="{ 'p-invalid': isInvalidGeneral.personalInformation }" />
          </div>

          <!-- Informações condifenciais -->
          <div style="display: flex; flex-direction: column; width: 100%">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('sensitiveInformation') }}
              </div>
              <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipSensitiveInfoDescription')"></i>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{
                this.$langGetValue('doesProcessHandleSensitiveInformation') }}
              </span>
            </div>
            <Dropdown :disabled="!editMode" v-model="dataSectionSelected.sensibleInformation"
              :options="[this.$langGetValue('yes'), this.$langGetValue('no')]"
              :placeholder="this.$langGetValue('selectOption')"
              :class="{ 'p-invalid': isInvalidGeneral.sensibleInformation }" />
          </div>

          <!-- Informações sensíveis -->
          <div style="display: flex; flex-direction: column; width: 100%">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('confidentialInformation') }}
              </div>
              <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipConfidentialInfoDescription')"></i>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{
                this.$langGetValue('doesProcessHandleConfidentialInformation') }}
              </span>
            </div>
            <Dropdown :disabled="!editMode" v-model="dataSectionSelected.confidentialInformation"
              :options="[this.$langGetValue('yes'), this.$langGetValue('no')]"
              :placeholder="this.$langGetValue('selectOption')"
              :class="{ 'p-invalid': isInvalidGeneral.confidentialInformation }" />
          </div>
        </div>

        <div class="h3" id="impactSection" style="margin-top: 80px">
          6. {{ this.$langGetValue('criticalityClassification') }}
        </div>

        <img src="images/biaImpactTableOne.png" class="button-like" @click="expandTableImage = true" />

        <Dialog v-model:visible="expandTableImage" class="image-dialog scrollDialog" modal header=" "
          style="max-width: 100%; box-shadow: none;" :dismissable-mask="true" @click="expandTableImage = false">
          <div class="flex justify-content-center">
            <img src="images/biaImpactTable.png" class="image-style" style="max-width: 100%" />
          </div>
          <!-- <div style="margin-top: 20px" class="flex justify-content-center">
            <img src="images/biaImpactTableOne.png" class="image-style" />
          </div> -->
        </Dialog>

        <div style="
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 64px;
            align-items: start;
            margin-top: -16px;
          ">
          <div :class="{ 'impactInvalid': isInvalidGeneral.impact }" style="width: 100%">
            <table style="width: 100%">
              <thead style="width: 100%; border-radius: 4px 4px 0px 0px">
                <tr style="background-color: var(--color_gray_xlight)">
                  <th style="padding: 16px 0px 16px 0px">{{ this.$langGetValue('impacts') }}</th>
                  <th style="padding: 16px 0px 16px 0px">{{ this.$langGetValue('oneHour') }}</th>
                  <th style="padding: 16px 0px 16px 0px">{{ this.$langGetValue('threeHours') }}</th>
                  <th style="padding: 16px 0px 16px 0px">{{ this.$langGetValue('sixHours') }}</th>
                  <th style="padding: 16px 0px 16px 0px">{{ this.$langGetValue('twelveHours') }}</th>
                  <th style="padding: 16px 0px 16px 0px">{{ this.$langGetValue('twentyFourHours') }}</th>
                  <th style="padding: 16px 0px 16px 0px" v-tooltip.top="this.$langGetValue('tooltipJustifyHighImpact')"
                    v-if="financialJustify || regulatoryJustify || reputationalJustify || operationalJustify || clienDropdownmultitsJustify">
                    {{ this.$langGetValue('justification') }}
                    <i class="pi pi-info-circle"></i>
                  </th>
                </tr>
              </thead>
              <tbody style="width: 100%">
                <tr style="width: 100%">
                  <td style="width: 200px; height: 100%; text-align: center">
                    <div class="flex align-item-center justify-content-center" style="height: 100%">
                      <div style="width: 100px" class="flex">
                        {{ this.$langGetValue('financial') }}
                      </div>
                      <div style="width: 20px" class="flex ml-1">
                        <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipFinancialImpact')"></i>
                      </div>
                    </div>
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(financial.hora1?.id) }"
                      optionLabel="label" v-model="financial.hora1" :options="biaOptions.impactsFinancial1" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(financial.horas3?.id) }"
                      optionLabel="label" v-model="financial.horas3" :options="biaOptions.impactsFinancial3" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(financial.horas6?.id) }"
                      optionLabel="label" v-model="financial.horas6" :options="biaOptions.impactsFinancial6" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(financial.horas12?.id) }"
                      optionLabel="label" v-model="financial.horas12" :options="biaOptions.impactsFinancial12" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(financial.horas24?.id) }"
                      optionLabel="label" v-model="financial.horas24" :options="biaOptions.impactsFinancial24" />
                  </td>
                  <td style="width: 13%" v-if="financialJustify">
                    <button
                      v-if="financial.justify == null || (financial.justify != null && financial.justify.trim().length < 30)"
                      style="width: 100%;; display: flex; gap: 8px; align-items: center; justify-content: center; cursor: pointer"
                      class="linkButton" @click="financialDialog = true">
                      <i class="pi pi-plus" style="color: var(--color_brand_medium)"></i>
                      <div class="body2" style="color: var(--color_brand_medium)">
                        {{ this.$langGetValue('addJustification') }}
                        <i style="color: var(--color_feedback_negative); cursor: help"
                          :title="this.$langGetValue('fieldRequired')">*</i>
                      </div>
                    </button>
                    <button v-else
                      style="width: 100%;; display: flex; gap: 8px; align-items: center; justify-content: center ;cursor: pointer"
                      class="linkButton" @click="financialDialog = true">
                      <i class="pi pi-comment" style="color: var(--color_brand_medium)"></i>
                      <div class="body2" style="color: var(--color_brand_medium)">
                        {{ this.$langGetValue('openJustification') }}
                      </div>
                    </button>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 200px; height: 100%; text-align: center">
                    <div class="flex align-item-center justify-content-center" style="height: 100%">
                      <div style="width: 100px" class="flex">
                        {{ this.$langGetValue('regulatory') }}
                      </div>
                      <div style="width: 20px" class="flex ml-1">
                        <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipRegulatoryImpact')"></i>
                      </div>
                    </div>
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(regulatory.hora1?.id) }"
                      optionLabel="label" v-model="regulatory.hora1" :options="biaOptions.impactsRegulatory1" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(regulatory.horas3?.id) }"
                      optionLabel="label" v-model="regulatory.horas3" :options="biaOptions.impactsRegulatory3" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(regulatory.horas6?.id) }"
                      optionLabel="label" v-model="regulatory.horas6" :options="biaOptions.impactsRegulatory6" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(regulatory.horas12?.id) }"
                      optionLabel="label" v-model="regulatory.horas12" :options="biaOptions.impactsRegulatory12" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(regulatory.horas24?.id) }"
                      optionLabel="label" v-model="regulatory.horas24" :options="biaOptions.impactsRegulatory24" />
                  </td>
                  <td style="width: 13%" v-if="regulatoryJustify">
                    <button
                      v-if="regulatory.justify == null || (regulatory.justify != null && regulatory.justify.trim().length < 30)"
                      style="width: 100%;; display: flex; gap: 8px; align-items: center; justify-content: center; cursor: pointer"
                      class="linkButton" @click="regulatoryDialog = true">
                      <i class="pi pi-plus" style="color: var(--color_brand_medium)"></i>
                      <div class="body2" style="color: var(--color_brand_medium)">
                        {{ this.$langGetValue('addJustification') }}
                        <i style="color: var(--color_feedback_negative); cursor: help"
                          :title="this.$langGetValue('fieldRequired')">*</i>
                      </div>
                    </button>
                    <button v-else
                      style="width: 100%;; display: flex; gap: 8px; align-items: center; justify-content: center ;cursor: pointer"
                      class="linkButton" @click="regulatoryDialog = true">
                      <i class="pi pi-comment" style="color: var(--color_brand_medium)"></i>
                      <div class="body2" style="color: var(--color_brand_medium)">
                        {{ this.$langGetValue('openJustification') }}
                      </div>
                    </button>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 200px; height: 100%; text-align: center">
                    <div class="flex align-item-center justify-content-center" style="height: 100%">
                      <div style="width: 100px" class="flex">
                        {{ this.$langGetValue('reputational') }}
                      </div>
                      <div style="width: 20px" class="flex ml-1">
                        <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipReputationalImpact')"></i>
                      </div>
                    </div>
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(reputational.hora1?.id) }"
                      optionLabel="label" v-model="reputational.hora1" :options="biaOptions.impactsReputational1" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(reputational.horas3?.id) }"
                      optionLabel="label" v-model="reputational.horas3" :options="biaOptions.impactsReputational3" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(reputational.horas6?.id) }"
                      optionLabel="label" v-model="reputational.horas6" :options="biaOptions.impactsReputational6" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(reputational.horas12?.id) }"
                      optionLabel="label" v-model="reputational.horas12" :options="biaOptions.impactsReputational12" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(reputational.horas24?.id) }"
                      optionLabel="label" v-model="reputational.horas24" :options="biaOptions.impactsReputational24" />
                  </td>
                  <td style="width: 13%" v-if="reputationalJustify">
                    <button
                      v-if="reputational.justify == null || (reputational.justify != null && reputational.justify.trim().length < 30)"
                      style="width: 100%;; display: flex; gap: 8px; align-items: center; justify-content: center; cursor: pointer"
                      class="linkButton" @click="reputationalDialog = true">
                      <i class="pi pi-plus" style="color: var(--color_brand_medium)"></i>
                      <div class="body2" style="color: var(--color_brand_medium)">
                        {{ this.$langGetValue('addJustification') }}
                        <i style="color: var(--color_feedback_negative); cursor: help"
                          :title="this.$langGetValue('fieldRequired')">*</i>
                      </div>
                    </button>
                    <button v-else
                      style="width: 100%;; display: flex; gap: 8px; align-items: center; justify-content: center ;cursor: pointer"
                      class="linkButton" @click="reputationalDialog = true">
                      <i class="pi pi-comment" style="color: var(--color_brand_medium)"></i>
                      <div class="body2" style="color: var(--color_brand_medium)">
                        {{ this.$langGetValue('openJustification') }}
                      </div>
                    </button>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 200px; height: 100%; text-align: center">
                    <div class="flex align-item-center justify-content-center" style="height: 100%">
                      <div style="width: 100px" class="flex">
                        {{ this.$langGetValue('operational') }}
                      </div>
                      <div style="width: 20px" class="flex mr-1">
                        <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipOperationalImpact')"></i>
                      </div>
                    </div>
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(operational.hora1?.id) }"
                      optionLabel="label" v-model="operational.hora1" :options="biaOptions.impactsOperational1" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(operational.horas3?.id) }"
                      optionLabel="label" v-model="operational.horas3" :options="biaOptions.impactsOperational3" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(operational.horas6?.id) }"
                      optionLabel="label" v-model="operational.horas6" :options="biaOptions.impactsOperational6" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(operational.horas12?.id) }"
                      optionLabel="label" v-model="operational.horas12" :options="biaOptions.impactsOperational12" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(operational.horas24?.id) }"
                      optionLabel="label" v-model="operational.horas24" :options="biaOptions.impactsOperational24" />
                  </td>
                  <td style="width: 13%" v-if="operationalJustify">
                    <button
                      v-if="operational.justify == null || (operational.justify != null && operational.justify.trim().length < 30)"
                      style="width: 100%;; display: flex; gap: 8px; align-items: center; justify-content: center; cursor: pointer"
                      class="linkButton" @click="operationalDialog = true">
                      <i class="pi pi-plus" style="color: var(--color_brand_medium)"></i>
                      <div class="body2" style="color: var(--color_brand_medium)">
                        {{ this.$langGetValue('addJustification') }}
                        <i style="color: var(--color_feedback_negative); cursor: help"
                          :title="this.$langGetValue('fieldRequired')">*</i>
                      </div>
                    </button>
                    <button v-else
                      style="width: 100%;; display: flex; gap: 8px; align-items: center; justify-content: center ;cursor: pointer"
                      class="linkButton" @click="operationalDialog = true">
                      <i class="pi pi-comment" style="color: var(--color_brand_medium)"></i>
                      <div class="body2" style="color: var(--color_brand_medium)">
                        {{ this.$langGetValue('openJustification') }}
                      </div>
                    </button>
                  </td>
                </tr>
                <tr style="width: 100%">
                  <td style="width: 200px; height: 100%; text-align: center">
                    <div class="flex align-item-center justify-content-center" style="height: 100%">
                      <div style="width: 100px" class="flex">
                        {{ this.$langGetValue('customers') }}
                      </div>
                      <div style="width: 20px" class="flex ml-1">
                        <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipClientImpact')"></i>
                      </div>
                    </div>
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(clients.hora1?.id) }"
                      optionLabel="label" v-model="clients.hora1" :options="biaOptions.impactsClient1" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(clients.horas3?.id) }"
                      optionLabel="label" v-model="clients.horas3" :options="biaOptions.impactsClient3" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(clients.horas6?.id) }"
                      optionLabel="label" v-model="clients.horas6" :options="biaOptions.impactsClient6" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(clients.horas12?.id) }"
                      optionLabel="label" v-model="clients.horas12" :options="biaOptions.impactsClient12" />
                  </td>
                  <td style="width: 13%; justify-content: center">
                    <Dropdown class="customDropdownImpact" :disabled="!editMode"
                      :style="{ 'width': '100%', 'backgroundColor': this.getImpactSeverityColor(clients.horas24?.id) }"
                      optionLabel="label" v-model="clients.horas24" :options="biaOptions.impactsClient24" />
                  </td>
                  <td style="width: 13%" v-if="clientsJustify">
                    <button
                      v-if="clients.justify == null || (clients.justify != null && clients.justify.trim().length < 30)"
                      style="width: 100%; display: flex; gap: 8px; align-items: center; justify-content: center; cursor: pointer"
                      class="linkButton" @click="clientsDialog = true">
                      <i class="pi pi-plus" style="color: var(--color_brand_medium)"></i>
                      <div class="body2" style="color: var(--color_brand_medium)">
                        {{ this.$langGetValue('addJustification') }}
                        <i style="color: var(--color_feedback_negative); cursor: help"
                          :title="this.$langGetValue('fieldRequired')">*</i>
                      </div>
                    </button>
                    <button v-else
                      style="width: 100%; display: flex; gap: 8px; align-items: center; justify-content: center ;cursor: pointer"
                      class="linkButton" @click="clientsDialog = true">
                      <i class="pi pi-comment" style="color: var(--color_brand_medium)"></i>
                      <div class="body2" style="color: var(--color_brand_medium)">
                        {{ this.$langGetValue('openJustification') }}
                      </div>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <Dialog v-model:visible="financialDialog" modal header=" " :dismissable-mask="true">
          <div style="display: flex; flex-direction: column; width: 550px">
            <div style="display: flex; align-items: start; gap: 4px">
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('justification') }} ({{ this.$langGetValue('financial') }})
              </div>
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('impactDescription') }}
              </span>
            </div>
            <Textarea :class="{ 'p-invalid': isInvalidGeneral.financialJustify }" :disabled="!editMode"
              v-model="financial.justify" rows="5" cols="30" style="resize: none;" />
          </div>
          <template #footer>
            <div class="mt-4 text-center">
              <Button v-if="editMode" style="background: var(--color_brand_medium); color: white "
                @click="verifyFinancialJustify">OK</Button>
            </div>
          </template>
        </Dialog>

        <Dialog v-model:visible="regulatoryDialog" modal header=" " :dismissable-mask="true">
          <div style="display: flex; flex-direction: column; width: 550px">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('justification') }} ({{ this.$langGetValue('regulatory') }})
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('impactDescription') }}
              </span>
            </div>
            <Textarea :class="{ 'p-invalid': isInvalidGeneral.regulatoryJustify }" :disabled="!editMode"
              v-model="regulatory.justify" rows="5" cols="30" style="resize: none;" />
          </div>
          <template #footer>
            <div class="mt-4 text-center">
              <Button v-if="editMode" style="background: var(--color_brand_medium); color: white "
                @click="verifyRegulatoryJustify">OK</Button>
            </div>
          </template>
        </Dialog>

        <Dialog v-model:visible="reputationalDialog" modal header=" " :dismissable-mask="true">
          <div style="display: flex; flex-direction: column; width: 550px">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('justification') }} ({{ this.$langGetValue('reputational') }})
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('impactDescription') }}
              </span>
            </div>
            <Textarea :class="{ 'p-invalid': isInvalidGeneral.reputationalJustify }" :disabled="!editMode"
              v-model="reputational.justify" rows="5" cols="30" style="resize: none;" />
          </div>
          <template #footer>
            <div class="mt-4 text-center">
              <Button v-if="editMode" style="background: var(--color_brand_medium); color: white "
                @click="verifyReputaionalJustify">OK</Button>
            </div>
          </template>
        </Dialog>

        <Dialog v-model:visible="operationalDialog" modal header=" " :dismissable-mask="true">
          <div style="display: flex; flex-direction: column; width: 550px">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('justification') }} ({{ this.$langGetValue('operational') }})
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('impactDescription') }}
              </span>
            </div>
            <Textarea :class="{ 'p-invalid': isInvalidGeneral.operationalJustify }" :disabled="!editMode"
              v-model="operational.justify" rows="5" cols="30" style="resize: none;" />
          </div>
          <template #footer>
            <div class="mt-4 text-center">
              <Button v-if="editMode" style="background: var(--color_brand_medium); color: white "
                @click="verifyOperationalJustify">OK</Button>
            </div>
          </template>
        </Dialog>

        <Dialog v-model:visible="clientsDialog" modal header=" " :dismissable-mask="true">
          <div style="display: flex; flex-direction: column; width: 550px">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('justification') }} ({{ this.$langGetValue('customers') }})
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('impactDescription') }}
              </span>
            </div>
            <Textarea :class="{ 'p-invalid': isInvalidGeneral.clientsJustify }" :disabled="!editMode"
              v-model="clients.justify" rows="5" cols="30" style="resize: none;" />
          </div>
          <template #footer>
            <div class="mt-4 text-center">
              <Button v-if="editMode" style="background: var(--color_brand_medium); color: white "
                @click="verifyClientsJustify">OK</Button>
            </div>
          </template>
        </Dialog>

        <!-- Impacto calculado, Impacto RO e Impacto Final e justificativa -->
        <div v-if="checkRole(['team.op-risk', 'bia.manager']) || formData.status == 4">
          <div style="display: flex; flex-direction: row; width: 100%">
            <!-- Impacto calculado -->
            <div v-if="impactedSelected.calculated != null" style="
              display: flex;
              flex-direction: column;
              justify-content: start;
              width: 33%;
              padding-right: 64px;
            ">
              <div style="display: flex; align-items: start; gap: 4px">
                <i style="color: var(--color_feedback_negative); cursor: help"
                  :title="this.$langGetValue('fieldRequired')">*</i>
                <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('calculatedImpact') }}</div>
              </div>
              <div style="margin-bottom: 16px">
                <span class="body3" style="color: var(--color_gray_medium)">{{
                  this.$langGetValue('calculatedImpactFromValues') }}
                </span>
              </div>
              <Chip :label="impactedSelected.calculated.label" class="secondaryChip"
                :style="{ 'backgroundColor': getImpactSeverityColor(impactedSelected.calculated.id), 'color': getImpactSeverityColor(impactedSelected.calculated.id) == '#FFCA43' ? 'black' : 'white', 'fontSize': '14px' }" />
            </div>

            <!-- Análise de impacto RO -->
            <div style="
              display: flex;
              flex-direction: column;
              justify-content: start;
              width: 33%;
              padding-right: 64px;
            ">
              <div style="display: flex; align-items: start; gap: 4px">
                <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('roImpact') }}</div>
              </div>
              <div style="margin-bottom: 16px">
                <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('roTeamAnalysisImpact')
                }}
                </span>
              </div>
              <Dropdown :disabled="!editMode" style="width: 100%" v-model="impactedSelected.ROSelected"
                :options="biaOptions.impactsFinancial1.filter(item => item.id != impactedSelected.calculated?.id)"
                showClear optionLabel="label" />
            </div>

            <!-- Justificativa RO -->
            <div v-if="impactedSelected.ROSelected != null" style="width: 33%;">
              <div style="
              display: flex;
              flex-direction: column;
              justify-content: start;
            ">
                <div style="display: flex; align-items: start; gap: 4px">
                  <i style="color: var(--color_feedback_negative); cursor: help"
                    :title="this.$langGetValue('fieldRequired')">*</i>
                  <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('justification') }}</div>
                </div>
                <div style="margin-bottom: 16px">
                  <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('justifyImpactValue')
                  }}
                  </span>
                </div>
                <InputText :disabled="!editMode" style="width: 100%" class="inputText" type="text"
                  v-model="impactedSelected.ROSelectedJustification"
                  :class="{ 'p-invalid': isInvalidGeneral.ROSelectedJustification }" />
              </div>
            </div>

            <!-- Impacto Final -->
            <div v-if="impactedSelected.ROSelected == null && impactedSelected.calculated !== null" style="
              display: flex;
              flex-direction: column;
              justify-content: start;
              width: 33%;
              padding-right: 64px;">
              <div style="display: flex; align-items: start; gap: 4px">
                <i style="color: var(--color_feedback_negative); cursor: help"
                  :title="this.$langGetValue('fieldRequired')">*</i>
                <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('finalImpact') }}</div>
              </div>
              <div style="margin-bottom: 16px">
                <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('finalImpactValue') }}
                </span>
              </div>
              <div>
                <Chip :label="impactedSelected.calculated.label" class="secondaryChip"
                  :style="{ 'backgroundColor': getImpactSeverityColor(impactedSelected.calculated.id), 'color': getImpactSeverityColor(impactedSelected.calculated.id) == '#FFCA43' ? 'black' : 'white', 'fontSize': '14px' }" />
              </div>
            </div>
          </div>

          <div style="display: flex; flex-direction: row; width: 100%; margin-top: 56px"
            v-if="impactedSelected.ROSelected !== null && impactedSelected.calculated !== null">
            <!-- Impacto Final -->
            <div style="
              display: flex;
              flex-direction: column;
              justify-content: start;
              width: 33%;
              padding-right: 64px;">
              <div style="display: flex; align-items: start; gap: 4px">
                <i style="color: var(--color_feedback_negative); cursor: help"
                  :title="this.$langGetValue('fieldRequired')">*</i>
                <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('finalImpact') }}</div>
              </div>
              <div style="margin-bottom: 16px">
                <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('finalImpactValue') }}
                </span>
              </div>
              <div>
                <Chip :label="impactedSelected.ROSelected.label" class="secondaryChip"
                  :style="{ 'backgroundColor': getImpactSeverityColor(impactedSelected.ROSelected.id), 'color': getImpactSeverityColor(impactedSelected.ROSelected.id) == '#FFCA43' ? 'black' : 'white', 'fontSize': '14px' }" />
              </div>
            </div>
          </div>
        </div>

        <!-- RTO / RPO -->
        <div style="
            display: flex;
            flex-direction: row;
            width: 100%;
            padding-top: -64px;
          ">

        </div>

        <div class="h3" id="timeSection">
          7. {{ this.$langGetValue('rtoRpo') }}
        </div>

        <!-- Tempo de recuperação calculado, Tempo de recuperação usuário e Justificativa usuário -->
        <div v-if="timeSelected.rtoCalculated !== null" style="
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            padding-top: -32px;
          ">
          <!-- Tempo de recuperação calculado -->
          <div style="
              display: flex;
              flex-direction: column;
              justify-content: start;
              width: 33%;
              padding-right: 64px;
            ">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                Recovery Time Objective
              </div>
              <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipRtoDefinition')"></i>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('calculatedRTOValue') }}
              </span>
            </div>
            <Chip :label="timeSelected.rtoCalculated?.label" style="background: black; color: white; font-size: 13px"
              class="secondaryChip" />
          </div>

          <!-- Tempo de recuperação usuário -->
          <div style="
              display: flex;
              flex-direction: column;
              width: 33%;
              justify-content: start;
              padding-right: 64px;
            ">
            <div style="display: flex; align-items: start; gap: 4px">
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('userRecoveryTimeObjective') }}
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('chooseRTOValue') }}
              </span>
            </div>
            <Dropdown :disabled="!editMode" style="width: 100%" v-model="timeSelected.rtoUser"
              :options="biaOptions.impactHoursRTO" showClear optionLabel="label" />
          </div>

          <!-- Justificativa usuário -->
          <div style="width: 33%;">
            <div v-if="timeSelected.rtoUser !== null" style="
              display: flex;
              flex-direction: column;
              justify-content: start;
            ">
              <div style="display: flex; align-items: start; gap: 4px">
                <i style="color: var(--color_feedback_negative); cursor: help"
                  :title="this.$langGetValue('fieldRequired')">*</i>
                <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('justification') }}</div>
              </div>
              <div style="margin-bottom: 16px">
                <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('justifyRTOValue') }}
                </span>
              </div>
              <InputText :disabled="!editMode" style="width: 100%" class="inputText" type="text"
                v-model="timeSelected.rtoUserJustify" :class="{ 'p-invalid': isInvalidGeneral.rtoUserJustify }" />
            </div>
          </div>
        </div>

        <!-- Recovery Point Objective  -->
        <div style="
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            padding-top: -64px;
          ">
          <!-- Recovery Point Objective  -->
          <div style="
              display: flex;
              flex-direction: column;
              justify-content: start;
              width: 33%;
              padding-right: 64px;
            ">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                Recovery Point Objective
              </div>
              <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipRpoDefinition')"></i>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('maxTolerableDataLoss') }}
              </span>
            </div>
            <Dropdown :disabled="!editMode" style="width: 100%" v-model="timeSelected.rpoUser"
              :options="biaOptions.impactHoursRPO" optionLabel="label" :class="{ 'p-invalid': isInvalidGeneral.rpo }" />
          </div>
        </div>

        <div class="h3" id="contingencySection" style="margin-top: 80px">
          8. {{ this.$langGetValue('contingency') }}
        </div>

        <!-- Quantidade mínima de pessoas, Pessoas chaves -->
        <div style="
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            padding-top: -32px;
            gap: 64px;
          ">
          <!-- Quantidade mínima de pessoas -->
          <div style="
              display: flex;
              flex-direction: column;
              justify-content: start;
              width: 100%;
            ">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('minimumNumberOfPeople') }}
              </div>
              <i class="pi pi-info-circle" v-tooltip.top="this.$langGetValue('tooltipIndicateMinimumRequiredPeople')"></i>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{
                this.$langGetValue('minimumPeopleForContingency') }}
              </span>
            </div>
            <InputNumber :disabled="!editMode" :min="1" v-model="contingencySelected.contingencyMinimumRequiredPeople"
              showButtons :useGrouping="false"
              :class="{ 'p-invalid': isInvalidGeneral.contingencyMinimumRequiredPeople }" />
          </div>

          <!-- Pessoas chaves -->
          <div style="
              display: flex;
              flex-direction: column;
              width: 100%;
              justify-content: start;
            ">
            <div style="display: flex; align-items: start; gap: 4px">
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
              <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('keyPersonnel') }}</div>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue('keyPersonnelForCrisis')
              }}
              </span>
            </div>
            <AutoComplete class="customAutoComplete" field="user" style="height: fit-content" :multiple="true"
              :disabled="!editMode" v-model="contingencySelected.contingencyEmployees" :suggestions="filteredUsers"
              @complete="searchUsersLara($event)" :forceSelection="true"
              :class="{ 'p-invalid': isInvalidGeneral.contingencyEmployees }" />
          </div>
        </div>
        <!-- Se status for pendente de preenchimento  -->
        <div style="height: 50px"></div>
        <div v-if="(formData.status == 1 || formData.status == 5 || formData.status == 6) && editMode"
          class="flex justify-content-center mb-6" style="width: 100%; gap: 24px">
          <Button :label="this.$langGetValue('saveDraft')" @click="sendDraft" class="primaryButton" style="width:150px" />
          <Button :label="this.$langGetValue('submitForm')" class="primaryButton"
            style="background-color: var(--color_brand_dark) !important; width:150px" @click="checkAllFields" />
        </div>
        <div v-else-if="formData.status == 2 && editMode" class="flex justify-content-center mb-6"
          style="width: 100%; gap: 24px">
          <Button :label="this.$langGetValue('reject')" @click="bpmRejectionDialog = true" class="primaryButton"
            style="width:150px" />
          <Button :label="this.$langGetValue('approve')" class="primaryButton"
            style="background-color: var(--color_brand_dark) !important; width:150px" @click="checkAllFields" />
        </div>
        <div v-else-if="formData.status == 3 && editMode" class="flex justify-content-center mb-6"
          style="width: 100%; gap: 24px">
          <Button :label="this.$langGetValue('reject')" @click="roRejectionDialog = true" class="primaryButton"
            style="width:150px" />
          <Button :label="this.$langGetValue('approve')" class="primaryButton"
            style="background-color: var(--color_brand_dark) !important; width:150px" @click="checkAllFields" />
        </div>

        <LogsComponent :logObject="this.formData.data.logs" :fieldTreatment="fieldMap"
          :logName="this.$langGetValue('biaForm')" :migrated="false">
        </LogsComponent>

        <Dialog style="width: 450px" v-model:visible="warningDialogREX" modal header=" " :dismissable-mask="true">
          <div class="flex" style="width: 100%">
            <div class="flex align-items-center"
              style="border: 3px solid rgb(237 197 15); border-radius: 12px 0px 0px 12px; padding: 16px; background: antiquewhite;">
              <i class="pi pi-exclamation-triangle" style="font-size: 2.5rem"></i>
            </div>
            <div class="flex"
              style="border: 3px solid rgb(237 197 15); border-radius: 0px 12px 12px 0px; padding: 16px; font-weight: 500; background: antiquewhite; border-left: none;">
              {{ this.$langGetValue('submitFormInstructions') }}
            </div>
          </div>
          <template #footer>
            <div style="width:100%" class="text-center">
              <Button :label="this.$langGetValue('confirmSubmission')" class="primaryButton" @click="sendForm" />
            </div>
          </template>
        </Dialog>

        <Dialog style="width: 450px" v-model:visible="warningDialogBPM" modal header=" " :dismissable-mask="true">
          <div class="flex" style="width: 100%">
            <div class="flex align-items-center"
              style="border: 3px solid rgb(237 197 15); border-radius: 12px 0px 0px 12px; padding: 16px; background: antiquewhite;">
              <i class="pi pi-exclamation-triangle" style="font-size: 2.5rem"></i>
            </div>
            <div class="flex"
              style="border: 3px solid rgb(237 197 15); border-radius: 0px 12px 12px 0px; padding: 16px; font-weight: 500; background: antiquewhite; border-left: none;">
              {{ this.$langGetValue('confirmApprovalNote') }}
            </div>
          </div>
          <template #footer>
            <div style="width:100%" class="text-center">
              <Button :label="this.$langGetValue('confirmApproval')" class="primaryButton" @click="bpmApproveForm" />
            </div>
          </template>
        </Dialog>

        <Dialog style="width: 450px" v-model:visible="warningDialogRO" modal header=" " :dismissable-mask="true">
          <div class="flex" style="width: 100%">
            <div class="flex align-items-center"
              style="border: 3px solid rgb(237 197 15); border-radius: 12px 0px 0px 12px; padding: 16px; background: antiquewhite;">
              <i class="pi pi-exclamation-triangle" style="font-size: 2.5rem"></i>
            </div>
            <div class="flex"
              style="border: 3px solid rgb(237 197 15); border-radius: 0px 12px 12px 0px; padding: 16px; font-weight: 500; background: antiquewhite; border-left: none;">
              {{ this.$langGetValue('confirmFinalizationNote') }}
            </div>
          </div>
          <template #footer>
            <div style="width:100%" class="text-center">
              <Button :label="this.$langGetValue('confirmApproval')" class="primaryButton" @click="roApproveForm" />
            </div>
          </template>
        </Dialog>

        <Dialog v-model:visible="bpmRejectionDialog" modal header=" " :dismissable-mask="true" style="width: 450px">
          <div style="display: flex; flex-direction: column; width: 100%">
            <div style="display: flex; align-items: start; gap: 4px">
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('rejectionJustification') }}
              </div>
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{
                this.$langGetValue('rejectionReasonDescription') }}
              </span>
            </div>
            <Textarea v-model="bpmRejectionJustification" rows="6" cols="30" style="resize: none;" />
          </div>
          <template #footer>
            <div style="width:100%" class="text-center">
              <Button :label="this.$langGetValue('reject')" @click="bpmRejectForm" class="primaryButton"
                style="width:150px" />
            </div>
          </template>
        </Dialog>


        <Dialog v-model:visible="roRejectionDialog" modal header=" " :dismissable-mask="true" style="width: 450px">
          <div style="display: flex; flex-direction: column; width: 100%">
            <div style="display: flex; align-items: start; gap: 4px">
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('rejectionJustification') }}
              </div>
              <i style="color: var(--color_feedback_negative); cursor: help"
                :title="this.$langGetValue('fieldRequired')">*</i>
            </div>
            <div style="margin-bottom: 16px">
              <span class="body3" style="color: var(--color_gray_medium)">{{
                this.$langGetValue('rejectionReasonDescription') }}
              </span>
            </div>
            <Textarea v-model="roRejectionJustification" rows="6" cols="30" style="resize: none;" />
          </div>
          <template #footer>
            <div style="width:100%" class="text-center">
              <Button :label="this.$langGetValue('reject')" @click="roRejectForm" class="primaryButton"
                style="width:150px" />
            </div>
          </template>
        </Dialog>

        <Dialog v-model:visible="rejectJustificationDialog" modal header=" " :dismissable-mask="true"
          style="width: 450px;">
          <div style="display: flex; flex-direction: column; width: 100%">
            <div style="display: flex; align-items: start; gap: 4px" class="mb-4">
              <div class="h4" style="margin-bottom: 4px">
                {{ this.$langGetValue('rejectionJustification') }}
              </div>
            </div>
            <div v-if="this.formData.status == 5">
              <Textarea disabled="true" :value="this.formData.data.fields.bpmRejectionJustification" rows="6" cols="30"
                style="resize: none; width:100%; opacity: 1" />
            </div>
            <div v-if="this.formData.status == 6">
              <Textarea disabled="true" :value="this.formData.data.fields.roRejectionJustification" rows="6" cols="30"
                style="resize: none; width:100%; opacity: 1" />
            </div>
          </div>
          <template #footer>
            <div class="mt-4"></div>
          </template>
        </Dialog>

      </div>
    </div>
  </div>
</template>

<script>
import Util from "../service/Util"
import BiaFormStepper from "../components/BiaFormStepper.vue";
import DatePicker from "../components/DatePicker.vue";
import IncidentService from "../service/IncidentService";
import LaraService from "../service/LaraService";
// import BiaTempFields from "../service/BiaTempFields";
import HierarchyComponent from "../components/HierarchyComponent.vue";
import UserService from "../service/UserService";
import BiaService from "../service/BiaService";
import FieldService from "../service/FieldService";
import LogsComponent from '../components/LogsComponent.vue';

export default {
  components: {
    LogsComponent,
    HierarchyComponent,
    BiaFormStepper,
    DatePicker,
  },
  props: {
    formData: Object,
    mode: String,
  },
  data() {
    return {
      mountingPage: false,
      menuCheck: null,
      menuIsOpen: Util.menuIsOpen,
      filteredCompany: null,
      newName: null,
      newBpm: null,
      newRex: null,
      newDescription: null,
      altNameDialog: false,
      altBpmDialog: false,
      altRexDialog: false,
      altDescriptionDialog: false,
      windowWidth: null,
      allAreasList: [],
      areaHierarchy: IncidentService.incidentFields.hierarchy.data,
      expandTableImage: false,
      expandedRowsSystem: [],
      expandedRowsSupplier: [],
      selectedExecutionPeriod: null,
      hourDialogCriticalMode: false,
      hoursErrorMessage1: false,
      hoursErrorMessage2: false,
      saveHourButtonDisabled: true,
      startHour: "00",
      startMinute: "00",
      finalHour: "00",
      finalMinute: "00",
      addPeriod: false,
      filteredUsers: [],
      status: "pendingBpmApproval",
      companySelected: [],
      capabilitySelected: null,
      descriptionSelected: null,
      processArea: null,

      fieldMap: {
        "biaFormStatus": {
          "label": this.$langGetValue('biaFieldFormStatus'),
          "isDate": false,
          "showChanges": true
        },
        "dailyExecutionTeam": {
          "label": this.$langGetValue('biaFieldDailyExecutionTeam'),
          "isDate": false,
          "showChanges": true
        },
        "backupExecutionTeam": {
          "label": this.$langGetValue('biaFieldBackupExecutionTeam'),
          "isDate": false,
          "showChanges": true
        },
        "executionLocations": {
          "label": this.$langGetValue('biaFieldNormalExecutionLocations'),
          "isDate": false,
          "showChanges": true
        },
        "executionHours": {
          "label": this.$langGetValue('biaFieldExecutionHours'),
          "isDate": false,
          "showChanges": false
        },
        "criticalExecutionHours": {
          "label": this.$langGetValue('biaFieldCriticalExecutionHours'),
          "isDate": false,
          "showChanges": false
        },
        "system": {
          "label": this.$langGetValue('biaFieldSystem'),
          "isDate": false,
          "showChanges": false
        },
        "supplier": {
          "label": this.$langGetValue('biaFieldSupplier'),
          "isDate": false,
          "showChanges": false
        },
        "internalDependency": {
          "label": this.$langGetValue('biaFieldInternalDependency'),
          "isDate": false,
          "showChanges": false
        },
        "personalInfo": {
          "key": "personalInfo",
          "label": this.$langGetValue('biaFieldPersonalInfo'),
          "isDate": false,
          "showChanges": true
        },
        "sensitiveInfo": {
          "label": this.$langGetValue('biaFieldSensitiveInfo'),
          "isDate": false,
          "showChanges": true
        },
        "confidentialInfo": {
          "label": this.$langGetValue('biaFieldConfidentialInfo'),
          "isDate": false,
          "showChanges": true
        },
        "impact": {
          "label": this.$langGetValue('biaFieldImpact'),
          "isDate": false,
          "showChanges": false
        },
        "calculatedImpact": {
          "label": this.$langGetValue('biaFieldCalculatedImpact'),
          "isDate": false,
          "showChanges": true
        },
        "roImpact": {
          "label": this.$langGetValue('roImpact'),
          "isDate": false,
          "showChanges": true
        },
        "roImpactJustification": {
          "key": "roImpactJustification",
          "label": this.$langGetValue('biaFieldRoImpactJustification'),
          "isDate": false,
          "showChanges": false
        },
        "calculatedRto": {
          "label": this.$langGetValue('calculatedRTOValue'),
          "isDate": false,
          "showChanges": true
        },
        "userRto": {
          "label": this.$langGetValue('userRecoveryTimeObjective'),
          "isDate": false,
          "showChanges": true
        },
        "userRtoJustification": {
          "label": this.$langGetValue('biaFieldUserRtoJustification'),
          "isDate": false,
          "showChanges": false
        },
        "rpo": {
          "label": this.$langGetValue('recoveryPointObjective'),
          "isDate": false,
          "showChanges": true
        },
        "contingencyMinimumRequiredPeople": {
          "label": this.$langGetValue('biaFieldContingencyMinimumRequiredPeople'),
          "isDate": false,
          "showChanges": true
        },
        "contingencyEmployees": {
          "label": this.$langGetValue('biaFieldContingencyEmployees'),
          "isDate": false,
          "showChanges": true
        },
        "periodType": {
          "label": this.$langGetValue('biaFieldPeriodType'),
          "isDate": false,
          "showChanges": true
        },
        "bpmRejectionJustification": {
          "label": this.$langGetValue('biaFieldBpmRejectionJustification'),
          "isDate": false,
          "showChanges": false
        },
        "roRejectionJustification": {
          "label": this.$langGetValue('biaFieldRoRejectionJustification'),
          "isDate": false,
          "showChanges": false
        },
        "company": {
          "label": this.$langGetValue('company'),
          "isDate": false,
          "showChanges": true
        },
        "capability": {
          "label": this.$langGetValue('capability'),
          "isDate": false,
          "showChanges": true
        }
      },

      // biaOptions: FieldService.data.bia

      biaOptions: {
        capability: FieldService.data.bia.capability,
        company: FieldService.data.bia.company.sort((a, b) => { if (a.id === 43) return -1; if (b.id === 43) return 1; return 0; }),
        // sistema
        // system: IncidentService.incidentFields.system.data.sort((a, b) => a.label.localeCompare(b.label)),
        system: FieldService.data.bia.systemBia.sort((a, b) => a.label.localeCompare(b.label)),
        contigencyStrategySystem: FieldService.data.bia.contigencyStrategySystem,
        // 
        executionLocation: FieldService.data.bia.executionLocations,
        executionPeriods: FieldService.data.bia.periodType,
        weekDays: FieldService.data.bia.weekDays,
        // Fornecedores
        supplier: FieldService.data.bia.supplierInvolved,
        supplierContingency: FieldService.data.bia.supplierContingency,
        supplierType: FieldService.data.bia.supplierType,
        // Dependencia interna
        areas: null,
        dependencyType: FieldService.data.bia.dependencyType,
        // Impactos 
        impactsFinancial1: FieldService.data.bia.impactsBia,
        impactsFinancial3: FieldService.data.bia.impactsBia,
        impactsFinancial6: FieldService.data.bia.impactsBia,
        impactsFinancial12: FieldService.data.bia.impactsBia,
        impactsFinancial24: FieldService.data.bia.impactsBia,

        impactsRegulatory1: FieldService.data.bia.impactsBia,
        impactsRegulatory3: FieldService.data.bia.impactsBia,
        impactsRegulatory6: FieldService.data.bia.impactsBia,
        impactsRegulatory12: FieldService.data.bia.impactsBia,
        impactsRegulatory24: FieldService.data.bia.impactsBia,

        impactsReputational1: FieldService.data.bia.impactsBia,
        impactsReputational3: FieldService.data.bia.impactsBia,
        impactsReputational6: FieldService.data.bia.impactsBia,
        impactsReputational12: FieldService.data.bia.impactsBia,
        impactsReputational24: FieldService.data.bia.impactsBia,

        impactsOperational1: FieldService.data.bia.impactsBia,
        impactsOperational3: FieldService.data.bia.impactsBia,
        impactsOperational6: FieldService.data.bia.impactsBia,
        impactsOperational12: FieldService.data.bia.impactsBia,
        impactsOperational24: FieldService.data.bia.impactsBia,

        impactsClient1: FieldService.data.bia.impactsBia,
        impactsClient3: FieldService.data.bia.impactsBia,
        impactsClient6: FieldService.data.bia.impactsBia,
        impactsClient12: FieldService.data.bia.impactsBia,
        impactsClient24: FieldService.data.bia.impactsBia,

        impactHoursRTO: FieldService.data.bia.impactHours,
        impactHoursRPO: FieldService.data.bia.impactHours,
      },

      isInvalidSystem: {
        system: false,
        description: false,
        freeze: false,
        contingency: false,
        contingencyDescription: false,
        backupSystem: false
      },

      isInvalidSupplier: {
        type: false,
        supplier: false,
        description: false,
        freeze: false,
        contingency: false,
        contingencyDescription: false,
      },

      isInvalidDependency: {
        type: false,
        area: false,
        description: false,
      },

      isInvalidGeneral: {
        employees: false,
        employeesBackup: false,
        selectedExecutionLocation: false,
        selectedPeriod: false,
        dailyFrequency: false,
        weeklyFrequency: false,
        monthFrequency: false,
        firstSemesterFrequency: false,
        secondSemesterFrequency: false,
        yearFrequency: false,
        otherFrequency: false,
        executionTimeRange: false,
        executionTimeRangeCritical: false,
        system: false,
        supplier: false,
        internalDependency: false,
        personalInformation: false,
        sensibleInformation: false,
        confidentialInformation: false,
        impact: false,
        financialJustify: false,
        regulatoryJustify: false,
        reputationalJustify: false,
        operationalJustify: false,
        clientsJustify: false,
        rtoUserJustify: false,
        rpo: false,
        contingencyMinimumRequiredPeople: false,
        contingencyEmployees: false,
        ROSelectedJustification: false,
        selectedCapability: false,
        selectedCompany: false,
      },

      generalDataSelected: {
        employees: [],
        employeesBackup: [],
        selectedExecutionLocation: [],
        selectedPeriod: null,
        monthFrequency: null,
        yearFrequency: null,
        firstSemesterFrequency: null,
        secondSemesterFrequency: null,
        otherFrequency: null,
        dailyFrequency: null,
        weeklyFrequency: [],
        executionTimeRange: [],
        executionTimeRangeCritical: [],
      },
      generalDataFilled: false,

      editMode: false,

      fixedPosition: false,
      initialPosition: null,
      divWidthSummary: null,

      systemEditMode: false,
      systemEditIndex: null,
      systemConditional: null,
      systemsListSelected: [],
      newSystem: false,
      systemSelected: {
        system: null,
        description: null,
        paralyzingPotencial: null,
        contingencyDescription: null,
        contingencyStrategy: null,
        contingencyBackup: null,
      },

      supplierEditMode: false,
      supplierEditIndex: null,
      supplierConditional: null,
      supplierListSelected: [],
      newSupplier: false,
      supplierSelected: {
        type: null,
        supplier: null,
        description: null,
        paralyzingPotencial: null,
        contingencyStrategy: null,
        contingencyDescription: null,
      },

      showHierarchy: true,
      reloadInternalDependency: true,
      internalDependencyEditMode: false,
      internalDependencyIndex: null,
      internalDependencyConditional: null,
      internalDependencyListSelected: [],
      newInternalDependency: false,
      internalDependencySelected: {
        type: null,
        area: null,
        description: null,
      },

      dataSectionSelected: {
        personalInformation: null,
        confidentialInformation: null,
        sensibleInformation: null,
      },
      dataSectionFilled: false,

      financialJustify: false,
      regulatoryJustify: false,
      reputationalJustify: false,
      operacionalJustify: false,
      clientsJustify: false,

      financialDialog: false,
      regulatoryDialog: false,
      reputationalDialog: false,
      operationalDialog: false,
      clientsDialog: false,

      financial: {
        hora1: null,
        horas3: null,
        horas6: null,
        horas12: null,
        horas24: null,
        justify: null,
      },
      regulatory: {
        hora1: null,
        horas3: null,
        horas6: null,
        horas12: null,
        horas24: null,
        justify: null,
      },
      reputational: {
        hora1: null,
        horas3: null,
        horas6: null,
        horas12: null,
        horas24: null,
        justify: null,
      },
      clients: {
        hora1: null,
        horas3: null,
        horas6: null,
        horas12: null,
        horas24: null,
        justify: null,
      },
      operational: {
        hora1: null,
        horas3: null,
        horas6: null,
        horas12: null,
        horas24: null,
        justify: null,
      },

      impactedSelected: {
        calculated: null,
        ROSelected: null,
        ROSelectedJustification: null,
      },
      impactedSFilled: false,

      timeSelected: {
        rtoCalculated: null,
        rtoUser: null,
        rtoUserJustify: null,
        rpoUser: null,
      },

      contingencySelected: {
        contingencyMinimumRequiredPeople: null,
        contingencyEmployees: [],
      },

      warningDialogREX: false,
      warningDialogBPM: false,
      warningDialogRO: false,

      bpmRejectionDialog: false,
      roRejectionDialog: false,

      bpmRejectionJustification: null,
      roRejectionJustification: null,

      rejectJustificationDialog: false,

      companySelectedTooltip: " ",
      locationSelectedTooltip: " ",
    };
  },
  watch: {
    'generalDataSelected.weeklyFrequency': function () {
      console.log(this.generalDataSelected.weeklyFrequency)
    },
    // Financeiro
    'financial.hora1': function () {
      if (this.financial.hora1?.id > this.financial.horas3?.id) {
        this.financial.horas3 = null
      }
      this.biaOptions.impactsFinancial3 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.financial.hora1.id)
    },
    'financial.horas3': function () {
      if (this.financial.horas3?.id > this.financial.horas6?.id) {
        this.financial.horas6 = null
      }
      if (this.financial.horas3 != null) {
        this.biaOptions.impactsFinancial6 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.financial.horas3.id)
      }
    },
    'financial.horas6': function () {
      if (this.financial.horas6?.id > this.financial.horas12?.id) {
        this.financial.horas12 = null
      }
      if (this.financial.horas6 != null) {
        this.biaOptions.impactsFinancial12 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.financial.horas6.id)
      }
    },
    'financial.horas12': function () {
      if (this.financial.horas12?.id > this.financial.horas24?.id) {
        this.financial.horas24 = null
      }
      if (this.financial.horas12 != null) {
        this.biaOptions.impactsFinancial24 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.financial.horas12.id)
      }
    },
    // Regulatório
    'regulatory.hora1': function () {
      if (this.regulatory.hora1?.id > this.regulatory.horas3?.id) {
        this.regulatory.horas3 = null;
      }
      this.biaOptions.impactsRegulatory3 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.regulatory.hora1.id);
    },
    'regulatory.horas3': function () {
      if (this.regulatory.horas3?.id > this.regulatory.horas6?.id) {
        this.regulatory.horas6 = null;
      }
      if (this.regulatory.horas3 != null) {
        this.biaOptions.impactsRegulatory6 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.regulatory.horas3.id);
      }
    },
    'regulatory.horas6': function () {
      if (this.regulatory.horas6?.id > this.regulatory.horas12?.id) {
        this.regulatory.horas12 = null;
      }
      if (this.regulatory.horas6 != null) {
        this.biaOptions.impactsRegulatory12 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.regulatory.horas6.id);
      }
    },
    'regulatory.horas12': function () {
      if (this.regulatory.horas12?.id > this.regulatory.horas24?.id) {
        this.regulatory.horas24 = null;
      }
      if (this.regulatory.horas12 != null) {
        this.biaOptions.impactsRegulatory24 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.regulatory.horas12.id);
      }
    },
    // Reputacional
    'reputational.hora1': function () {
      if (this.reputational.hora1?.id > this.reputational.horas3?.id) {
        this.reputational.horas3 = null;
      }
      this.biaOptions.impactsReputational3 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.reputational.hora1.id);
    },
    'reputational.horas3': function () {
      if (this.reputational.horas3?.id > this.reputational.horas6?.id) {
        this.reputational.horas6 = null;
      }
      if (this.reputational.horas3 != null) {
        this.biaOptions.impactsReputational6 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.reputational.horas3.id);
      }
    },
    'reputational.horas6': function () {
      if (this.reputational.horas6?.id > this.reputational.horas12?.id) {
        this.reputational.horas12 = null;
      }
      if (this.reputational.horas6 != null) {
        this.biaOptions.impactsReputational12 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.reputational.horas6.id);
      }
    },
    'reputational.horas12': function () {
      if (this.reputational.horas12?.id > this.reputational.horas24?.id) {
        this.reputational.horas24 = null;
      }
      if (this.reputational.horas12 != null) {
        this.biaOptions.impactsReputational24 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.reputational.horas12.id);
      }
    },
    // Operacional
    'operational.hora1': function () {
      if (this.operational.hora1?.id > this.operational.horas3?.id) {
        this.operational.horas3 = null;
      }
      this.biaOptions.impactsOperational3 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.operational.hora1.id);
    },
    'operational.horas3': function () {
      if (this.operational.horas3?.id > this.operational.horas6?.id) {
        this.operational.horas6 = null;
      }
      if (this.operational.horas3 != null) {
        this.biaOptions.impactsOperational6 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.operational.horas3.id);
      }
    },
    'operational.horas6': function () {
      if (this.operational.horas6?.id > this.operational.horas12?.id) {
        this.operational.horas12 = null;
      }
      if (this.operational.horas6 != null) {
        this.biaOptions.impactsOperational12 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.operational.horas6.id);
      }
    },
    'operational.horas12': function () {
      if (this.operational.horas12?.id > this.operational.horas24?.id) {
        this.operational.horas24 = null;
      }
      if (this.operational.horas12 != null) {
        this.biaOptions.impactsOperational24 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.operational.horas12.id);
      }
    },
    // Cliente
    'clients.hora1': function () {
      if (this.clients.hora1?.id > this.clients.horas3?.id) {
        this.clients.horas3 = null;
      }
      this.biaOptions.impactsClient3 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.clients.hora1.id);
    },
    'clients.horas3': function () {
      if (this.clients.horas3?.id > this.clients.horas6?.id) {
        this.clients.horas6 = null;
      }
      if (this.clients.horas3 != null) {
        this.biaOptions.impactsClient6 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.clients.horas3.id);
      }
    },
    'clients.horas6': function () {
      if (this.clients.horas6?.id > this.clients.horas12?.id) {
        this.clients.horas12 = null;
      }
      if (this.clients.horas6 != null) {
        this.biaOptions.impactsClient12 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.clients.horas6.id);
      }
    },
    'clients.horas12': function () {
      if (this.clients.horas12?.id > this.clients.horas24?.id) {
        this.clients.horas24 = null;
      }
      if (this.clients.horas12 != null) {
        this.biaOptions.impactsClient24 = FieldService.data.bia.impactsBia.filter(item => item.id >= this.clients.horas12.id);
      }
    },
    //
    financial: {
      handler(newValue) {
        var values = [4, 5]

        if (values.includes(newValue.hora1?.id) || values.includes(newValue.horas3?.id) || values.includes(newValue.horas6?.id) || values.includes(newValue.horas12?.id) || values.includes(newValue.horas24?.id)) {

          this.financialJustify = true
        }
        else {
          this.financialJustify = false
        }
        this.valuateImpact()
        this.valuateRTO()
      },
      deep: true,
    },
    regulatory: {
      handler(newValue) {
        var values = [4, 5]

        if (values.includes(newValue.hora1?.id) || values.includes(newValue.horas3?.id) || values.includes(newValue.horas6?.id) || values.includes(newValue.horas12?.id) || values.includes(newValue.horas24?.id)) {

          this.regulatoryJustify = true
        }
        else {
          this.regulatoryJustify = false
        }
        this.valuateImpact()
        this.valuateRTO()
      },
      deep: true,
    },
    reputational: {
      handler(newValue) {
        var values = [4, 5]

        if (values.includes(newValue.hora1?.id) || values.includes(newValue.horas3?.id) || values.includes(newValue.horas6?.id) || values.includes(newValue.horas12?.id) || values.includes(newValue.horas24?.id)) {
          this.reputationalJustify = true
        }
        else {
          this.reputationalJustify = false
        }
        this.valuateImpact()
        this.valuateRTO()
      },
      deep: true,
    },
    operational: {
      handler(newValue) {
        var values = [4, 5]

        if (values.includes(newValue.hora1?.id) || values.includes(newValue.horas3?.id) || values.includes(newValue.horas6?.id) || values.includes(newValue.horas12?.id) || values.includes(newValue.horas24?.id)) {

          this.operationalJustify = true
        }
        else {
          this.operationalJustify = false
        }
        this.valuateImpact()
        this.valuateRTO()
      },
      deep: true,
    },
    clients: {
      handler(newValue) {
        var values = [4, 5]

        if (values.includes(newValue.hora1?.id) || values.includes(newValue.horas3?.id) || values.includes(newValue.horas6?.id) || values.includes(newValue.horas12?.id) || values.includes(newValue.horas24?.id)) {
          this.clientsJustify = true
        }
        else {
          this.clientsJustify = false
        }
        this.valuateImpact()
        this.valuateRTO()
      },
      deep: true,
    },
    'timeSelected.rtoCalculated': function () {
      if (this.timeSelected.rtoCalculated != null) {
        this.biaOptions.impactHoursRTO = FieldService.data.bia.impactHours.filter(item => item.id != this.timeSelected.rtoCalculated.id)
      }
    },
    systemConditional() {
      if (this.systemConditional == this.$langGetValue('yes') && this.editMode) {
        this.newSystem = true
      }
    },
    supplierConditional() {
      if (this.supplierConditional == this.$langGetValue('yes') && this.editMode) {
        this.newSupplier = true
      }
    },
    internalDependencyConditional() {
      if (this.internalDependencyConditional == this.$langGetValue('yes') && this.editMode) {
        this.newInternalDependency = true
      }
    },
    companySelected: {
      handler: function () {
        let tempCompany = this.companySelected.map(company => company.label);
        if (tempCompany.length > 3) this.companySelectedTooltip = tempCompany.join(";\n");
        else this.companySelectedTooltip = " ";
      },
      deep: true,
    },
    generalDataSelected: {
      handler: function () {
        let tempLocation = this.generalDataSelected.selectedExecutionLocation.map(location => location.label);
        if (tempLocation.length > 3) this.locationSelectedTooltip = tempLocation.join(";\n");
        else this.locationSelectedTooltip = " ";
      },
      deep: true,
    },
  },
  async mounted() {

    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.handleResize);

    this.menuCheck = setInterval(() => {
      this.checkMenuIsOpen()
    }, 100)

    await this.populateHierarchyArray();

    this.mountPage()

    this.mountLogs()

    // console.log(Util.menuIsOpen)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    clearInterval(this.menuCheck)
  },
  methods: {
    checkMenuIsOpen() {
      this.menuIsOpen = Util.menuIsOpen
    },
    checkRole(roles) {
      return UserService.checkUserRoleAny(roles)
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    mountPage() {
      this.mountingPage = true

      this.filteredCompany = this.biaOptions.company;

      if (this.formData.data.fields.company != null) {
        let tempCompany = [];


        this.biaOptions.company.forEach(group => {
          group.children.forEach(company => {
            if (this.formData.data.fields.company.includes(company.id)) {
              tempCompany.push(company);
            }
          });
        });

        this.companySelected = tempCompany;
      }

      if (this.formData.data.fields.capability != null) {
        this.capabilitySelected = this.biaOptions.capability.filter(item => item.id == this.formData.data.fields.capability)[0]
      }

      this.processArea = this.allAreasList.filter(item => item.id == this.formData.data.process.area)[0].label

      if (this.formData.data.fields.dailyExecutionTeam != null) {
        this.formData.data.fields.dailyExecutionTeam.forEach((userMail) => {
          this.generalDataSelected.employees.push({
            user: Util.emailToName(userMail),
            mail: userMail
          })
        })
      }

      if (this.formData.data.fields.backupExecutionTeam != null) {
        this.formData.data.fields.backupExecutionTeam.forEach((userMail) => {
          this.generalDataSelected.employeesBackup.push({
            user: Util.emailToName(userMail),
            mail: userMail
          })
        })
      }

      if (this.formData.data.fields.normalExecutionLocations != null) {
        this.generalDataSelected.selectedExecutionLocation = this.biaOptions.executionLocation.filter(item => this.formData.data.fields.normalExecutionLocations.includes(item.id));
      }
      // periodos
      if (this.formData.data.fields.periodType != null && this.formData.data.fields.periodType == 1 && 'dailyFrequency' in this.formData.data.fields) {
        this.generalDataSelected.dailyFrequency = this.formData.data.fields.dailyFrequency
      }

      if (this.formData.data.fields.periodType != null && this.formData.data.fields.periodType == 2 && 'weekFrequency' in this.formData.data.fields) {
        var tempWeekFrequency = []

        this.formData.data.fields.weekFrequency.forEach((day) => {
          tempWeekFrequency.push(FieldService.data.bia.weekDays.filter(frequency => frequency.id === day)[0])
        })

        this.generalDataSelected.weeklyFrequency = tempWeekFrequency
      }

      if (this.formData.data.fields.periodType != null && this.formData.data.fields.periodType == 3 && 'monthFrequency' in this.formData.data.fields) {
        this.generalDataSelected.monthFrequency = this.formData.data.fields.monthFrequency
      }

      if (this.formData.data.fields.periodType != null && this.formData.data.fields.periodType == 4 && 'semesterFrequency' in this.formData.data.fields) {
        if (this.formData.data.fields.semesterFrequency.firstSemester.day !== null && this.formData.data.fields.semesterFrequency.firstSemester.month !== null) {
          this.generalDataSelected.firstSemesterFrequency = { "month": this.formData.data.fields.semesterFrequency.firstSemester.month, "days": [this.formData.data.fields.semesterFrequency.firstSemester.day] }
        }

        if (this.formData.data.fields.semesterFrequency.secondSemester.day !== null && this.formData.data.fields.semesterFrequency.secondSemester.month !== null) {
          this.generalDataSelected.secondSemesterFrequency = { "month": this.formData.data.fields.semesterFrequency.secondSemester.month, "days": [this.formData.data.fields.semesterFrequency.secondSemester.day] }
        }
      }

      if (this.formData.data.fields.periodType != null && this.formData.data.fields.periodType == 5 && 'yearFrequency' in this.formData.data.fields) {
        this.generalDataSelected.yearFrequency = { "month": this.formData.data.fields.yearFrequency.month, "days": [this.formData.data.fields.yearFrequency.day] }
      }

      if (this.formData.data.fields.periodType != null && this.formData.data.fields.periodType == 6 && 'otherFrequency' in this.formData.data.fields) {
        this.generalDataSelected.otherFrequency = this.formData.data.fields.otherFrequency
      }

      this.generalDataSelected.selectedPeriod = FieldService.data.bia.periodType.filter(item => item.id == this.formData.data.fields.periodType)[0]

      // faixas de horários
      if (this.formData.data.fields.executionHours != null) {
        this.formData.data.fields.executionHours.forEach((period) => {
          let [startHour, startMinute] = period.startHour.split(":");
          let [finalHour, finalMinute] = period.endHour.split(":");

          this.generalDataSelected.executionTimeRange.push(
            { startTime: { hour: startHour, minute: startMinute }, endTime: { hour: finalHour, minute: finalMinute } }
          )

          this.addExecutionHourScheduler(startHour, startMinute, finalHour, finalMinute, false)
        })
      }

      if (this.formData.data.fields.criticalExecutionHours != null) {
        this.formData.data.fields.criticalExecutionHours.forEach((period) => {
          let [startHour, startMinute] = period.startHour.split(":");
          let [finalHour, finalMinute] = period.endHour.split(":");

          this.generalDataSelected.executionTimeRangeCritical.push(
            { startTime: { hour: startHour, minute: startMinute }, endTime: { hour: finalHour, minute: finalMinute } }
          )

          this.addExecutionHourScheduler(startHour, startMinute, finalHour, finalMinute, true)
        })
      }
      // Sistemas
      if (this.formData.data.fields.system != null && this.formData.data.fields.system.length > 0) {
        this.systemConditional = this.$langGetValue('yes')
        this.formData.data.fields.system.forEach((system) => {

          var object = {
            system: this.biaOptions.system.filter(item => item.id == system.systemId)[0],
            description: system.systemDescription,
            paralyzingPotencial: system.freezePotential ? this.$langGetValue('yes') : this.$langGetValue('no'),
            contingencyStrategy: system.contingencyStrategy !== null ? this.biaOptions.contigencyStrategySystem.filter(item => item.id == system.contingencyStrategy)[0] : null,
          }

          if (object.contingencyStrategy != null && object.contingencyStrategy.id != 1) {
            object.contingencyDescription = system.contingencyStrategyDescription

            if (object.contingencyStrategy.id == 4) {
              object.contingencyBackup = this.biaOptions.system.filter(item => item.id == system.backupSystem)[0]
            }
          }
          else {
            object.contingencyDescription = null
          }
          this.systemsListSelected.push(object)
        });
      }
      else {
        this.systemConditional = this.$langGetValue('no')
      }

      // fornecedores
      if (this.formData.data.fields.supplier != null && this.formData.data.fields.supplier.length > 0) {
        this.supplierConditional = this.$langGetValue('yes')

        this.formData.data.fields.supplier.forEach((supplier) => {

          var object = {
            type: this.biaOptions.supplierType.filter(item => item.id == supplier.type)[0],
            supplier: this.biaOptions.supplier.filter(item => item.id == supplier.supplierId)[0],
            description: supplier.supplierDescription,
            paralyzingPotencial: supplier.freezePotential ? this.$langGetValue('yes') : this.$langGetValue('no'),
            contingencyStrategy: this.biaOptions.supplierContingency.filter(item => item.id == supplier.contingencyStrategy)[0]
          }

          if (object.contingencyStrategy != null && object.contingencyStrategy.id != 1) {
            object.contingencyDescription = supplier.contingencyStrategyDescription
          }
          else {
            object.contingencyDescription = null
          }
          this.supplierListSelected.push(object)
        })
      }
      else {
        this.supplierConditional = this.$langGetValue('no')
      }

      // dependencia
      if (this.formData.data.fields.internalDependency != null && this.formData.data.fields.internalDependency.length > 0) {
        this.internalDependencyConditional = this.$langGetValue('yes')

        this.formData.data.fields.internalDependency.forEach((dependency) => {
          var object = {
            type: this.biaOptions.dependencyType.filter(item => item.id == dependency.type)[0],
            area: [{
              id: this.allAreasList.filter(item => item.id == dependency.areaId)[0].id,
              type: "originated"
            }],
            description: dependency.dependencyDescription,
          }

          this.internalDependencyListSelected.push(object)
        })
      }
      else {
        this.internalDependencyConditional = this.$langGetValue('no')
      }

      // Informações pessoais
      if (this.formData.data.fields.personalInfo != null) {
        this.formData.data.fields.personalInfo ? this.dataSectionSelected.personalInformation = this.$langGetValue('yes') : this.dataSectionSelected.personalInformation = this.$langGetValue('no')
      }

      if (this.formData.data.fields.confidentialInfo != null) {
        this.formData.data.fields.confidentialInfo ? this.dataSectionSelected.confidentialInformation = this.$langGetValue('yes') : this.dataSectionSelected.confidentialInformation = this.$langGetValue('no')
      }

      if (this.formData.data.fields.sensitiveInfo != null) {
        this.formData.data.fields.sensitiveInfo ? this.dataSectionSelected.sensibleInformation = this.$langGetValue('yes') : this.dataSectionSelected.sensibleInformation = this.$langGetValue('no')
      }

      // impactos

      if (this.formData.data.fields.impact != null) {
        this.financial.hora1 = this.formData.data.fields.impact.financialImpact.oneHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.financialImpact.oneHour)[0] : null;
        this.financial.horas3 = this.formData.data.fields.impact.financialImpact.threeHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.financialImpact.threeHour)[0] : null;
        this.financial.horas6 = this.formData.data.fields.impact.financialImpact.sixHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.financialImpact.sixHour)[0] : null;
        this.financial.horas12 = this.formData.data.fields.impact.financialImpact.twelveHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.financialImpact.twelveHour)[0] : null;
        this.financial.horas24 = this.formData.data.fields.impact.financialImpact.twentyFourHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.financialImpact.twentyFourHour)[0] : null;
        this.financial.justify = this.formData.data.fields.impact.financialImpact.justification;

        this.regulatory.hora1 = this.formData.data.fields.impact.regulatoryImpact.oneHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.regulatoryImpact.oneHour)[0] : null;
        this.regulatory.horas3 = this.formData.data.fields.impact.regulatoryImpact.threeHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.regulatoryImpact.threeHour)[0] : null;
        this.regulatory.horas6 = this.formData.data.fields.impact.regulatoryImpact.sixHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.regulatoryImpact.sixHour)[0] : null;
        this.regulatory.horas12 = this.formData.data.fields.impact.regulatoryImpact.twelveHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.regulatoryImpact.twelveHour)[0] : null;
        this.regulatory.horas24 = this.formData.data.fields.impact.regulatoryImpact.twentyFourHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.regulatoryImpact.twentyFourHour)[0] : null;
        this.regulatory.justify = this.formData.data.fields.impact.regulatoryImpact.justification;

        this.reputational.hora1 = this.formData.data.fields.impact.reputationalImpact.oneHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.reputationalImpact.oneHour)[0] : null;
        this.reputational.horas3 = this.formData.data.fields.impact.reputationalImpact.threeHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.reputationalImpact.threeHour)[0] : null;
        this.reputational.horas6 = this.formData.data.fields.impact.reputationalImpact.sixHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.reputationalImpact.sixHour)[0] : null;
        this.reputational.horas12 = this.formData.data.fields.impact.reputationalImpact.twelveHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.reputationalImpact.twelveHour)[0] : null;
        this.reputational.horas24 = this.formData.data.fields.impact.reputationalImpact.twentyFourHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.reputationalImpact.twentyFourHour)[0] : null;
        this.reputational.justify = this.formData.data.fields.impact.reputationalImpact.justification;

        this.clients.hora1 = this.formData.data.fields.impact.clientImpact.oneHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.clientImpact.oneHour)[0] : null;
        this.clients.horas3 = this.formData.data.fields.impact.clientImpact.threeHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.clientImpact.threeHour)[0] : null;
        this.clients.horas6 = this.formData.data.fields.impact.clientImpact.sixHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.clientImpact.sixHour)[0] : null;
        this.clients.horas12 = this.formData.data.fields.impact.clientImpact.twelveHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.clientImpact.twelveHour)[0] : null;
        this.clients.horas24 = this.formData.data.fields.impact.clientImpact.twentyFourHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.clientImpact.twentyFourHour)[0] : null;
        this.clients.justify = this.formData.data.fields.impact.clientImpact.justification;

        this.operational.hora1 = this.formData.data.fields.impact.operationalImpact.oneHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.operationalImpact.oneHour)[0] : null;
        this.operational.horas3 = this.formData.data.fields.impact.operationalImpact.threeHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.operationalImpact.threeHour)[0] : null;
        this.operational.horas6 = this.formData.data.fields.impact.operationalImpact.sixHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.operationalImpact.sixHour)[0] : null;
        this.operational.horas12 = this.formData.data.fields.impact.operationalImpact.twelveHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.operationalImpact.twelveHour)[0] : null;
        this.operational.horas24 = this.formData.data.fields.impact.operationalImpact.twentyFourHour !== null ? this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.impact.operationalImpact.twentyFourHour)[0] : null;
        this.operational.justify = this.formData.data.fields.impact.operationalImpact.justification
      }

      if (this.formData.data.fields.roImpact != null) {
        this.impactedSelected.ROSelected = this.biaOptions.impactsFinancial1.filter(item => item.id === this.formData.data.fields.roImpact)[0]
      }

      if (this.formData.data.fields.roImpactJustification != null) {
        this.impactedSelected.ROSelectedJustification = this.formData.data.fields.roImpactJustification
      }

      // RTO / RPO

      setTimeout(() => {
        if (this.formData.data.fields.userRto != null) {
          this.timeSelected.rtoUser = this.biaOptions.impactHoursRTO.filter(item => item.id === this.formData.data.fields.userRto)[0]
        }

        if (this.formData.data.fields.userRtoJustification != null) {
          this.timeSelected.rtoUserJustify = this.formData.data.fields.userRtoJustification
        }
        
        if (this.formData.data.fields.userRpo != null) {
          this.timeSelected.rpoUser = this.biaOptions.impactHoursRPO.filter(item => item.id === this.formData.data.fields.userRpo)[0]
        }
      }, 200)

      // Contigencia

      this.contingencySelected.contingencyMinimumRequiredPeople = this.formData.data.fields.contingencyMinimumRequiredPeople

      if (this.formData.data.fields.contingencyEmployees != null) {
        this.formData.data.fields.contingencyEmployees.forEach((userMail) => {
          this.contingencySelected.contingencyEmployees.push({
            user: Util.emailToName(userMail),
            mail: userMail
          })
        })
      }

      setTimeout(() => {
        this.mountingPage = false
        this.fieldsBehaviour()
      }, 200)
    },
    mountLogs() {
      let logs = this.formData.data.logs

      logs.forEach(log => {
        if (log.changelog) {
          log.changelog.forEach(entry => {
            if (entry.field === "personalInfo") {
              entry.from = this.getBoolLogs(entry.from);
              entry.to = this.getBoolLogs(entry.to);
            }
            if (entry.field === "sensitiveInfo") {
              entry.from = this.getBoolLogs(entry.from);
              entry.to = this.getBoolLogs(entry.to);
            }
            if (entry.field === "confidentialInfo") {
              entry.from = this.getBoolLogs(entry.from);
              entry.to = this.getBoolLogs(entry.to);
            }
          });
        }
      });
    },
    getBoolLogs(value) {
      if (value === true) {
        return this.$langGetValue('yes')
      }
      if (value === false) {
        return this.$langGetValue('no')
      }

      return value
    },
    verifyFinancialJustify() {
      if (this.financial.justify == null || this.financial.justify.trim().length < 30) {
        this.isInvalidGeneral.financialJustify = true

        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('invalidJustification'),
          detail: this.$langGetValue('invalidJustificationLength'),
          life: 5000,
        });
        return
      }
      else {
        this.isInvalidGeneral.financialJustify = false
        this.financialDialog = false
      }
    },
    verifyRegulatoryJustify() {
      if (this.regulatory.justify == null || this.regulatory.justify.trim().length < 30) {
        this.isInvalidGeneral.regulatoryJustify = true

        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('invalidJustification'),
          detail: this.$langGetValue('invalidJustificationLength'),
          life: 5000,
        });
        return
      }
      else {
        this.isInvalidGeneral.regulatoryJustify = false
        this.regulatoryDialog = false
      }
    },
    verifyReputaionalJustify() {
      if (this.reputational.justify == null || this.reputational.justify.trim().length < 30) {
        this.isInvalidGeneral.reputationalJustify = true

        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('invalidJustification'),
          detail: this.$langGetValue('invalidJustificationLength'),
          life: 5000,
        });
        return
      }
      else {
        this.isInvalidGeneral.reputationalJustify = false
        this.reputationalDialog = false
      }
    },
    verifyOperationalJustify() {
      if (this.operational.justify == null || this.operational.justify.trim().length < 30) {
        this.isInvalidGeneral.operationalJustify = true

        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('invalidJustification'),
          detail: this.$langGetValue('invalidJustificationLength'),
          life: 5000,
        });
        return
      }
      else {
        this.isInvalidGeneral.operationalJustify = false
        this.operationalDialog = false
      }
    },
    verifyClientsJustify() {
      if (this.clients.justify == null || this.clients.justify.trim().length < 30) {
        this.isInvalidGeneral.clientsJustify = true

        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('invalidJustification'),
          detail: this.$langGetValue('invalidJustificationLength'),
          life: 5000,
        });
        return
      }
      else {
        this.isInvalidGeneral.clientsJustify = false
        this.clientsDialog = false
      }
    },
    fieldsBehaviour() {
      var status = this.formData.status

      if (status == 1 || status == 5 || status == 6) {
        // libera os campos pra bpm/rex/ro preencher
        if (UserService.user == this.formData.data.process.bpm || UserService.user == this.formData.data.process.rex || this.checkRole(['team.op-risk', 'bia.manager'])) {
          this.editMode = true
        }
      }
      if (status == 2) {
        // libera os campos pra bpm/ro preencher e validar
        if (UserService.user == this.formData.data.process.bpm || this.checkRole(['team.op-risk', 'bia.manager'])) {
          this.editMode = true
        }
      }
      if (status == 3) {
        // libera os campos pra ro preencher e validar
        if (this.checkRole(['team.op-risk', 'bia.manager'])) {
          this.editMode = true
        }
      }
      // status = 4 Somente vizualização
    },
    setAreaDependency(area) {
      this.internalDependencySelected.area = area
    },
    getValueHoursScheduler(i) {
      var tempValue = i - 1

      if (tempValue < 10) {
        return "0" + tempValue + ":00"
      }
      else {
        return tempValue + ":00"
      }
    },
    changeHour(behavior, type) {
      if (behavior == 'increment') {
        if (type == 'start') {
          let tempHour = parseInt(this.startHour)

          if (tempHour == 23) {
            return
          }

          tempHour += 1

          if (tempHour < 10) {
            this.startHour = "0" + tempHour.toString()
          } else {
            this.startHour = tempHour.toString()
          }
        }
        if (type == 'final') {
          let tempHour = parseInt(this.finalHour)

          if (tempHour == 23) {
            return
          }

          tempHour += 1

          if (tempHour < 10) {
            this.finalHour = "0" + tempHour.toString()
          }
          else {
            this.finalHour = tempHour.toString()
          }
        }
      }
      if (behavior == 'decrement') {
        if (type == 'start') {
          let tempHour = parseInt(this.startHour)

          if (tempHour == 0) {
            return
          }

          tempHour -= 1

          if (tempHour < 10) {
            this.startHour = "0" + tempHour.toString()
          }
          else {
            this.startHour = tempHour.toString()
          }
        }
        if (type == 'final') {
          let tempHour = parseInt(this.finalHour)

          if (tempHour == 0) {
            return
          }

          tempHour -= 1

          if (tempHour < 10) {
            this.finalHour = "0" + tempHour.toString()
          }
          else {
            this.finalHour = tempHour.toString()
          }
        }
      }
    },
    changeMinute(behavior, type) {
      if (behavior == 'increment') {
        if (type == 'start') {
          let tempMinute = parseInt(this.startMinute)

          if (tempMinute == 45) {
            this.startMinute = "00"
            this.changeHour('increment', 'start')
            return
          }

          tempMinute += 15

          this.startMinute = tempMinute.toString()
        }
        if (type == 'final') {
          let tempMinute = parseInt(this.finalMinute)

          if (tempMinute == 45) {
            this.finalMinute = "00"
            this.changeHour('increment', 'final')
            return
          }

          tempMinute += 15

          this.finalMinute = tempMinute.toString()
        }
      }
      if (behavior == 'decrement') {
        if (type == 'start') {
          let tempMinute = parseInt(this.startMinute)

          if (tempMinute == 0 && parseInt(this.startHour) == 0) {
            return
          }

          if (tempMinute == 0 && parseInt(this.startHour) > 0) {
            this.startMinute = "45"
            this.changeHour('decrement', 'start')
            return
          }

          tempMinute -= 15

          if (tempMinute == 0) {
            this.startMinute = "0" + tempMinute.toString()
          }
          else {
            this.startMinute = tempMinute.toString()
          }
        }
        if (type == 'final') {
          let tempMinute = parseInt(this.finalMinute)

          if (tempMinute == 0 && parseInt(this.finalHour) == 0) {
            return
          }

          if (tempMinute == 0) {
            this.finalMinute = "45"
            this.changeHour('decrement', 'final')
            return
          }

          tempMinute -= 15

          if (tempMinute == 0) {
            this.finalMinute = "0" + tempMinute.toString()
          }
          else {
            this.finalMinute = tempMinute.toString()
          }
        }
      }
    },
    checkHour() {
      var startHour = parseInt(this.startHour);
      var startMinute = parseInt(this.startMinute);
      var finalHour = parseInt(this.finalHour);
      var finalMinute = parseInt(this.finalMinute);

      const elements = document.getElementsByClassName('changeHourMinutes');

      if (startHour > finalHour || (startHour === finalHour && startMinute >= finalMinute)) {
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.color = 'red';
        }
        this.saveHourButtonDisabled = true
        return
      }

      for (let i = 0; i < elements.length; i++) {
        elements[i].style.color = 'rgb(51, 51, 51)';
      }
      this.saveHourButtonDisabled = false
      return;
    },
    canAddTimeRange(newStartTime, newEndTime, executionTimeRange) {
      for (let i = 0; i < executionTimeRange.length; i++) {
        const startTime = executionTimeRange[i].startTime;
        const endTime = executionTimeRange[i].endTime;

        if (
          (newStartTime.hour < endTime.hour || (newStartTime.hour === endTime.hour && newStartTime.minute < endTime.minute)) &&
          (newEndTime.hour > startTime.hour || (newEndTime.hour === startTime.hour && newEndTime.minute > startTime.minute))
        ) {
          return false;
        }
      }

      return true;
    },
    isTimeRangeContained(startTime1, endTime1, startTime2, endTime2) {
      // Verifica se o primeiro período está contido no segundo ou é igual
      if (
        (startTime1.hour > startTime2.hour || (startTime1.hour === startTime2.hour && startTime1.minute >= startTime2.minute)) &&
        (endTime1.hour < endTime2.hour || (endTime1.hour === endTime2.hour && endTime1.minute <= endTime2.minute))
      ) {
        return true;
      } else {
        return false;
      }
    },
    saveHour(critical = false) {

      if (critical == false) {
        if (!this.canAddTimeRange({ hour: this.startHour, minute: this.startMinute }, { hour: this.finalHour, minute: this.finalMinute }, this.generalDataSelected.executionTimeRange)) {
          this.hoursErrorMessage1 = true
          return
        }

        this.hoursErrorMessage1 = false

        this.generalDataSelected.executionTimeRange.push(
          { startTime: { hour: this.startHour, minute: this.startMinute }, endTime: { hour: this.finalHour, minute: this.finalMinute } }
        )

        this.addExecutionHourScheduler(this.startHour, this.startMinute, this.finalHour, this.finalMinute, false)

      }

      if (critical) {
        if (!this.isTimeRangeContained({ hour: this.startHour, minute: this.startMinute }, { hour: this.finalHour, minute: this.finalMinute }, this.selectedExecutionPeriod.startTime, this.selectedExecutionPeriod.endTime)) {
          this.hoursErrorMessage2 = true
          return
        }

        this.hoursErrorMessage2 = false

        if (!this.canAddTimeRange({ hour: this.startHour, minute: this.startMinute }, { hour: this.finalHour, minute: this.finalMinute }, this.generalDataSelected.executionTimeRangeCritical)) {
          this.hoursErrorMessage1 = true
          return
        }

        this.hoursErrorMessage1 = false

        this.generalDataSelected.executionTimeRangeCritical.push(
          { startTime: { hour: this.startHour, minute: this.startMinute }, endTime: { hour: this.finalHour, minute: this.finalMinute } }
        )

        this.addExecutionHourScheduler(this.startHour, this.startMinute, this.finalHour, this.finalMinute, true)

      }

      this.addPeriod = false

      this.$toast.add({
        severity: "success",
        summary: this.$langGetValue('timeAdded'),
        life: 5000,
      });

    },
    showTooltip(tooltip, e) {
      tooltip.style.top = e.clientY + 'px';
      tooltip.style.left = e.clientX + 'px';
      tooltip.style.display = 'block';
    },
    hideTooltip(tooltip) {
      tooltip.style.display = 'none';
    },
    addExecutionHourScheduler(startHour, startMinute, finalHour, finalMinute, critical) {

      var startHourAux = parseInt(startHour);
      var startMinuteAux = parseInt(startMinute);
      var finalHourAux = parseInt(finalHour);
      var finalMinuteAux = parseInt(finalMinute);

      var col, line

      if (critical) {
        col = '_c2'
        line = '_l2'
      }
      else {
        col = '_c1'
        line = '_l1'
      }

      var tooltip = document.createElement('div');
      tooltip.id = 'tooltip_' + startHour + '_' + startMinute + '_' + finalHour + '_' + finalMinute;
      tooltip.style.position = 'fixed';
      tooltip.textContent = startHour + ':' + startMinute + ' - ' + finalHour + ':' + finalMinute;
      tooltip.style.display = 'none';
      tooltip.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
      tooltip.style.color = 'white';
      tooltip.style.borderRadius = '16px';
      tooltip.style.padding = '8px';
      document.body.appendChild(tooltip);

      for (var i = startHourAux; i <= finalHourAux; i++) {
        var startMin = (i === startHourAux) ? startMinuteAux : 1;
        var endMin = (i === finalHourAux) ? finalMinuteAux : 60;

        var tempHour = i + 1

        var divAuxCol

        if (tempHour == 24) {
          divAuxCol = document.getElementById('hour_' + 2 + col);
        }
        else {
          divAuxCol = document.getElementById('hour_' + (tempHour + 1) + col);
        }

        if (divAuxCol && i < finalHourAux - 1) {
          divAuxCol.style.cursor = 'help';

          divAuxCol.addEventListener('mouseenter', (e) => {
            this.showTooltip(tooltip, e);
          });

          divAuxCol.addEventListener('mouseleave', () => {
            this.hideTooltip(tooltip);
          });
        }

        for (var j = startMin; j <= endMin; j++) {

          var divAuxLine

          divAuxLine = document.getElementById('hour_' + tempHour + '_minute_' + j + line);

          if (divAuxLine) {
            divAuxLine.style.backgroundColor = critical ? 'rgb(255, 77, 77)' : 'rgb(25, 90, 180)';
          }

        }
      }
    },
    removeExecutionHourScheduler(startHour, startMinute, finalHour, finalMinute, critical) {

      var startHourAux = parseInt(startHour);
      var startMinuteAux = parseInt(startMinute);
      var finalHourAux = parseInt(finalHour);
      var finalMinuteAux = parseInt(finalMinute);

      var col, line

      if (critical) {
        col = '_c2'
        line = '_l2'
      }
      else {
        col = '_c1'
        line = '_l1'
      }

      var tooltip = document.getElementById('tooltip_' + startHour + '_' + startMinute + '_' + finalHour + '_' + finalMinute)

      for (var i = startHourAux; i <= finalHourAux; i++) {
        var startMin = (i === startHourAux) ? startMinuteAux : 1;
        var endMin = (i === finalHourAux) ? finalMinuteAux : 60;

        var tempHour = i + 1

        var divAuxCol

        if (tempHour == 24) {
          divAuxCol = document.getElementById('hour_' + 2 + col);
        }
        else {
          divAuxCol = document.getElementById('hour_' + (tempHour + 1) + col);
        }

        if (divAuxCol && i < finalHourAux - 1) {
          divAuxCol.style.cursor = 'auto'

          // Remover eventos mouseenter e mouseleave
          divAuxCol.removeEventListener('mouseenter', this.showTooltip);
          divAuxCol.removeEventListener('mouseleave', this.hideTooltip);
        }

        for (var j = startMin; j <= endMin; j++) {

          var divAuxLine

          divAuxLine = document.getElementById('hour_' + tempHour + '_minute_' + j + line);

          if (divAuxLine) {
            divAuxLine.style.backgroundColor = 'white';
          }

        }
      }

      // Remover tooltip
      if (tooltip) {
        tooltip.remove();
      }
    },
    searchUsersLara(event, filter = "user") {
      let tempArray = [];

      LaraService.searchUser(event.query, filter).then((response) => {
        response.forEach((user) => {
          if (user.attributes.mail != "") {
            tempArray.push({
              user: Util.emailToName(user.attributes.mail.toLowerCase()),
              mail: user.attributes.mail.toLowerCase()
            });
          }
        });
        this.filteredUsers = tempArray;
      });
    },
    setupValueFirstSemesterOcurrence(data) {
      this.generalDataSelected.firstSemesterFrequency = data
    },
    setupValueSecondSemesterOcurrence(data) {
      this.generalDataSelected.secondSemesterFrequency = data
    },
    setupValueAnnualyOcurrence(data) {
      this.generalDataSelected.yearFrequency = data
    },
    setupValueMothlyOcurrence(data) {
      this.generalDataSelected.monthFrequency = data;
    },
    openSystem() {
      this.systemEditMode = false

      this.systemSelected.system = null
      this.systemSelected.description = null
      this.systemSelected.paralyzingPotencial = null
      this.systemSelected.contingencyDescription = null
      this.systemSelected.contingencyStrategy = null
      this.systemSelected.contingencyBackup = null

      setTimeout(() => {
        this.newSystem = true;
      }, 200)
    },
    handleAddNewSystem() {
      var emptyFields = []

      if (this.systemSelected.system == null) {
        emptyFields.push(this.$langGetValue('system'))
        this.isInvalidSystem.system = true
      }
      else {
        this.isInvalidSystem.system = false
      }

      if (this.systemSelected.description == null || this.systemSelected.description.trim().length < 30) {
        emptyFields.push(this.$langGetValue('description'))
        this.isInvalidSystem.description = true
      }
      else {
        this.isInvalidSystem.description = false
      }

      if (this.systemSelected.paralyzingPotencial == null) {
        emptyFields.push(this.$langGetValue('potentialToHaltProcess'))
        this.isInvalidSystem.freeze = true
      }
      else {
        this.isInvalidSystem.freeze = false
      }

      if (this.systemSelected.contingencyStrategy == null) {
        if (this.systemSelected.paralyzingPotencial == this.$langGetValue('yes')) {
          emptyFields.push(this.$langGetValue('contingencyStrategy'))
          this.isInvalidSystem.contingency = true
        }
      }
      else {
        this.isInvalidSystem.contingency = false

        if (this.systemSelected.contingencyStrategy.id != 1) {

          if (this.systemSelected.contingencyDescription == null || this.systemSelected.contingencyDescription.trim().length < 30) {
            emptyFields.push(this.$langGetValue('contingencyDescription'))
            this.isInvalidSystem.contingencyDescription = true
          }
          else {
            this.isInvalidSystem.contingencyDescription = false
          }

          if (this.systemSelected.contingencyBackup == null && this.systemSelected.contingencyStrategy.id == 4) {
            emptyFields.push(this.$langGetValue('backupSystem'))
            this.isInvalidSystem.backupSystem = true
          }
          else {
            this.isInvalidSystem.backupSystem = false
          }

        }
      }

      if (emptyFields.length > 0) {
        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('blankFields'),
          detail: this.$langGetValue('fillFields') + emptyFields.join(', '),
          life: 5000,
        });
        return
      }

      let tempVar = { ...this.systemSelected }
      this.systemsListSelected.push(tempVar)

      this.$toast.add({
        severity: "success",
        summary: this.$langGetValue('systemAdded'),
        life: 5000,
      });

      setTimeout(() => {
        this.systemSelected.system = null
        this.systemSelected.description = null
        this.systemSelected.paralyzingPotencial = null
        this.systemSelected.contingencyDescription = null
        this.systemSelected.contingencyStrategy = null
        this.systemSelected.contingencyBackup = null
      }, 200)
    },
    handleEditSystem() {
      var emptyFields = []

      if (this.systemSelected.system == null) {
        emptyFields.push(this.$langGetValue('system'))
        this.isInvalidSystem.system = true
      }
      else {
        this.isInvalidSystem.system = false
      }

      if (this.systemSelected.description == null || this.systemSelected.description.trim().length < 30) {
        emptyFields.push(this.$langGetValue('description'))
        this.isInvalidSystem.description = true
      }
      else {
        this.isInvalidSystem.description = false
      }

      if (this.systemSelected.paralyzingPotencial == null) {
        emptyFields.push(this.$langGetValue('potentialToHaltProcess'))
        this.isInvalidSystem.freeze = true
      }
      else {
        this.isInvalidSystem.freeze = false
      }

      if (this.systemSelected.contingencyStrategy == null) {
        if (this.systemSelected.paralyzingPotencial == this.$langGetValue('yes')) {
          emptyFields.push(this.$langGetValue('contingencyStrategy'))
          this.isInvalidSystem.contingency = true
        }
      }
      else {
        this.isInvalidSystem.contingency = false

        if (this.systemSelected.contingencyStrategy.id != 1) {

          if (this.systemSelected.contingencyDescription == null || this.systemSelected.contingencyDescription.trim().length < 30) {
            emptyFields.push(this.$langGetValue('contingencyDescription'))
            this.isInvalidSystem.contingencyDescription = true
          }
          else {
            this.isInvalidSystem.contingencyDescription = false
          }

          if (this.systemSelected.contingencyBackup == null && this.systemSelected.contingencyStrategy.id == 4) {
            emptyFields.push(this.$langGetValue('backupSystem'))
            this.isInvalidSystem.backupSystem = true
          }
          else {
            this.isInvalidSystem.backupSystem = false
          }

        }
      }

      if (emptyFields.length > 0) {
        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('blankFields'),
          detail: this.$langGetValue('fillFields') + emptyFields.join(', '),
          life: 5000,
        });
        return
      }

      if (this.systemSelected.contingencyStrategy?.id == 1) {
        this.systemSelected.contingencyDescription = null
        this.systemSelected.contingencyBackup = null
      }

      if (this.systemSelected.contingencyStrategy?.id != 4) {
        this.systemSelected.contingencyBackup = null
      }

      let tempVar = { ...this.systemSelected }

      this.systemsListSelected[this.systemEditIndex] = tempVar

      this.newSystem = false

      this.$toast.add({
        severity: "success",
        summary: this.$langGetValue('systemEdited'),
        life: 5000,
      });
    },
    handleDeleteSystem(index) {
      this.systemsListSelected.splice(index, 1);
    },
    openSupplier() {
      this.supplierEditMode = false

      this.supplierSelected.type = null
      this.supplierSelected.supplier = null
      this.supplierSelected.description = null
      this.supplierSelected.paralyzingPotencial = null
      this.supplierSelected.contingencyDescription = null
      this.supplierSelected.contingencyStrategy = null

      setTimeout(() => {
        this.newSupplier = true;
      }, 200)
    },
    handleDeleteSupplier(index) {
      this.supplierListSelected.splice(index, 1);
    },
    handleAddNewSupplier() {
      var emptyFields = []

      if (this.supplierSelected.type == null) {
        emptyFields.push(this.$langGetValue('type'))
        this.isInvalidSupplier.type = true
      }
      else {
        this.isInvalidSupplier.type = false
      }

      if (this.supplierSelected.supplier == null) {
        emptyFields.push(this.$langGetValue('supplier'))
        this.isInvalidSupplier.supplier = true
      }
      else {
        this.isInvalidSupplier.supplier = false
      }

      if (this.supplierSelected.description == null || this.supplierSelected.description.trim().length < 30) {
        emptyFields.push(this.$langGetValue('description'))
        this.isInvalidSupplier.description = true
      }
      else {
        this.isInvalidSupplier.description = false
      }

      if (this.supplierSelected.paralyzingPotencial == null) {
        emptyFields.push(this.$langGetValue('potentialToHaltProcess'))
        this.isInvalidSupplier.freeze = true
      }
      else {
        this.isInvalidSupplier.freeze = false
      }

      if (this.supplierSelected.contingencyStrategy == null) {
        if (this.supplierSelected.paralyzingPotencial == this.$langGetValue('yes')) {
          emptyFields.push(this.$langGetValue('contingencyStrategy'))
          this.isInvalidSystem.contingency = true
        }
      }
      else {
        this.isInvalidSystem.contingency = false

        if (this.supplierSelected.contingencyStrategy.id != 1) {

          if (this.supplierSelected.contingencyDescription == null || this.supplierSelected.contingencyDescription.trim().length < 30) {
            emptyFields.push(this.$langGetValue('contingencyDescription'))
            this.isInvalidSystem.contingencyDescription = true
          }
          else {
            this.isInvalidSystem.contingencyDescription = false
          }
        }
      }

      if (emptyFields.length > 0) {
        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('blankFields'),
          detail: this.$langGetValue('fillFields') + emptyFields.join(', '),
          life: 5000,
        });
        return
      }

      let tempVar = { ...this.supplierSelected }
      this.supplierListSelected.push(tempVar)

      this.$toast.add({
        severity: "success",
        summary: this.$langGetValue('supplierAdded'),
        life: 5000,
      });

      setTimeout(() => {
        this.supplierSelected.type = null
        this.supplierSelected.supplier = null
        this.supplierSelected.description = null
        this.supplierSelected.paralyzingPotencial = null
        this.supplierSelected.contingencyDescription = null
        this.supplierSelected.contingencyStrategy = null
      }, 200)
    },
    handleEditSupplier() {
      var emptyFields = []

      if (this.supplierSelected.type == null) {
        emptyFields.push(this.$langGetValue('type'))
        this.isInvalidSupplier.type = true
      }
      else {
        this.isInvalidSupplier.type = false
      }

      if (this.supplierSelected.supplier == null) {
        emptyFields.push(this.$langGetValue('supplier'))
        this.isInvalidSupplier.supplier = true
      }
      else {
        this.isInvalidSupplier.supplier = false
      }

      if (this.supplierSelected.description == null || this.supplierSelected.description.trim().length < 30) {
        emptyFields.push(this.$langGetValue('description'))
        this.isInvalidSupplier.description = true
      }
      else {
        this.isInvalidSupplier.description = false
      }

      if (this.supplierSelected.paralyzingPotencial == null) {
        emptyFields.push(this.$langGetValue('potentialToHaltProcess'))
        this.isInvalidSupplier.freeze = true
      }
      else {
        this.isInvalidSupplier.freeze = false
      }

      if (this.supplierSelected.contingencyStrategy == null) {
        if (this.supplierSelected.paralyzingPotencial == this.$langGetValue('yes')) {
          emptyFields.push(this.$langGetValue('contingencyStrategy'))
          this.isInvalidSystem.contingency = true
        }
      }
      else {
        this.isInvalidSystem.contingency = false

        if (this.supplierSelected.contingencyStrategy.id != 1) {

          if (this.supplierSelected.contingencyDescription == null || this.supplierSelected.contingencyDescription.trim().length < 30) {
            emptyFields.push(this.$langGetValue('contingencyDescription'))
            this.isInvalidSystem.contingencyDescription = true
          }
          else {
            this.isInvalidSystem.contingencyDescription = false
          }
        }
      }

      if (emptyFields.length > 0) {
        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('blankFields'),
          detail: this.$langGetValue('fillFields') + emptyFields.join(', '),
          life: 5000,
        });
        return
      }

      if (this.supplierSelected.contingencyStrategy?.id == 1) {
        this.supplierSelected.contingencyDescription = null
      }

      let tempVar = { ...this.supplierSelected }

      this.supplierListSelected[this.supplierEditIndex] = tempVar

      this.newSupplier = false

      this.$toast.add({
        severity: "success",
        summary: this.$langGetValue('supplierEdited'),
        life: 5000,
      });

    },
    handleAddNewInternalDependency() {
      var emptyFields = []

      if (this.internalDependencySelected.area == null) {
        emptyFields.push(this.$langGetValue('area'))
        this.isInvalidDependency.area = true
      }
      else {
        this.isInvalidDependency.area = false
      }

      if (this.internalDependencySelected.type == null) {
        emptyFields.push(this.$langGetValue('type'))
        this.isInvalidDependency.type = true
      }
      else {
        this.isInvalidDependency.type = false
      }

      if (this.internalDependencySelected.description == null || this.internalDependencySelected.description.trim().length < 30) {
        emptyFields.push(this.$langGetValue('description'))
        this.isInvalidDependency.description = true
      }
      else {
        this.isInvalidDependency.description = false
      }

      if (emptyFields.length > 0) {
        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('blankFields'),
          detail: this.$langGetValue('fillFields') + emptyFields.join(', '),
          life: 5000,
        });
        return
      }

      let tempVar = { ...this.internalDependencySelected }

      this.internalDependencyListSelected.push(tempVar)
      this.$toast.add({
        severity: "success",
        summary: this.$langGetValue('dependencyAdded'),
        life: 5000,
      });

      this.showHierarchy = false

      setTimeout(() => {
        this.internalDependencySelected.area = null
        this.internalDependencySelected.type = null
        this.internalDependencySelected.description = null

        this.showHierarchy = true
      }, 200)
    },
    openDependency() {
      this.internalDependencyEditMode = false

      this.internalDependencySelected.type = null
      this.internalDependencySelected.area = null
      this.internalDependencySelected.description = null

      setTimeout(() => {
        this.newInternalDependency = true;
      }, 200)
    },
    handleEditInternalDependency() {
      var emptyFields = []

      if (this.internalDependencySelected.area == null) {
        emptyFields.push(this.$langGetValue('area'))
        this.isInvalidDependency.area = true
      }
      else {
        this.isInvalidDependency.area = false
      }

      if (this.internalDependencySelected.type == null) {
        emptyFields.push(this.$langGetValue('type'))
        this.isInvalidDependency.type = true
      }
      else {
        this.isInvalidDependency.type = false
      }

      if (this.internalDependencySelected.description == null || this.internalDependencySelected.description.trim().length < 30) {
        emptyFields.push(this.$langGetValue('description'))
        this.isInvalidDependency.description = true
      }
      else {
        this.isInvalidDependency.description = false
      }

      if (emptyFields.length > 0) {
        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('blankFields'),
          detail: this.$langGetValue('fillFields') + emptyFields.join(', '),
          life: 5000,
        });
        return
      }

      this.reloadInternalDependency = false

      let tempVar = { ...this.internalDependencySelected }

      this.internalDependencyListSelected[this.internalDependencyIndex] = tempVar

      setTimeout(() => {
        this.reloadInternalDependency = true

        this.newInternalDependency = false
      }, 200)

      this.$toast.add({
        severity: "success",
        summary: this.$langGetValue('dependencyEdited'),
        life: 5000,
      });

    },
    handleDeleteInternalDependency(index) {
      this.reloadInternalDependency = false

      this.internalDependencyListSelected.splice(index, 1);

      setTimeout(() => {
        this.reloadInternalDependency = true
      }, 100);
    },
    scrollToDiv(targetDivProp) {
      var element = document.getElementById(targetDivProp);

      if (element) {
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - 100;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    },
    setEditMode() {
      if (this.mode == "edit") {
        this.editMode = true;
      }
    },
    addExecutionTime(critical = false) {
      this.hoursErrorMessage1 = false
      this.hoursErrorMessage2 = false
      this.selectedExecutionPeriod = null

      if (critical && this.generalDataSelected.executionTimeRange.length == 0) {
        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('atLeastOneExecutionTime'),
          // detail: "Você está agora editando o incidente",
          life: 3000,
        });
        return
      }
      if (critical) {
        this.hourDialogCriticalMode = true
        this.selectedExecutionPeriod = this.generalDataSelected.executionTimeRange[0]
        this.addPeriod = true
      }
      else {
        this.hourDialogCriticalMode = false
        this.addPeriod = true
      }
    },
    removeExecutionTime(index, critical) {
      var tempHour
      if (critical) {
        tempHour = this.generalDataSelected.executionTimeRangeCritical[index]
        this.removeExecutionHourScheduler(tempHour.startTime.hour, tempHour.startTime.minute, tempHour.endTime.hour, tempHour.endTime.minute, true)
        this.generalDataSelected.executionTimeRangeCritical = this.generalDataSelected.executionTimeRangeCritical.filter(item => item !== this.generalDataSelected.executionTimeRangeCritical[index])
      }
      else {
        tempHour = this.generalDataSelected.executionTimeRange[index]

        var tempHourCritical = []

        this.generalDataSelected.executionTimeRangeCritical.forEach((executionHourCritical) => {
          if (this.isTimeRangeContained(executionHourCritical.startTime, executionHourCritical.endTime, tempHour.startTime, tempHour.endTime)) {
            tempHourCritical.push(executionHourCritical)
          }
        })

        if (tempHourCritical.length > 0) {
          tempHourCritical.forEach((executionHour) => {
            this.removeExecutionHourScheduler(executionHour.startTime.hour, executionHour.startTime.minute, executionHour.endTime.hour, executionHour.endTime.minute, true)
            this.generalDataSelected.executionTimeRangeCritical = this.generalDataSelected.executionTimeRangeCritical.filter(item => item !== executionHour)
          })
        }

        this.removeExecutionHourScheduler(tempHour.startTime.hour, tempHour.startTime.minute, tempHour.endTime.hour, tempHour.endTime.minute, false)
        this.generalDataSelected.executionTimeRange = this.generalDataSelected.executionTimeRange.filter(item => item !== tempHour)
      }
    },
    valuateImpact() {

      if (this.mountingPage == false) {
        if (this.impactedSelected.ROSelected !== null) {
          this.$toast.add({
            severity: "warn",
            summary: this.$langGetValue('roImpact'),
            detail: this.$langGetValue('deletedField'),
            life: 5000,
          });
        }
        this.impactedSelected.ROSelected = null
      }

      if (this.financial.hora1 == null || this.financial.horas3 == null || this.financial.horas6 == null || this.financial.horas12 == null || this.financial.horas24 == null) {
        this.impactedSelected.calculated = null
        return
      }
      if (this.regulatory.hora1 == null || this.regulatory.horas3 == null || this.regulatory.horas6 == null || this.regulatory.horas12 == null || this.regulatory.horas24 == null) {
        this.impactedSelected.calculated = null
        return
      }
      if (this.reputational.hora1 == null || this.reputational.horas3 == null || this.reputational.horas6 == null || this.reputational.horas12 == null || this.reputational.horas24 == null) {
        this.impactedSelected.calculated = null
        return
      }
      if (this.operational.hora1 == null || this.operational.horas3 == null || this.operational.horas6 == null || this.operational.horas12 == null || this.operational.horas24 == null) {
        this.impactedSelected.calculated = null
        return
      }
      if (this.clients.hora1 == null || this.clients.horas3 == null || this.clients.horas6 == null || this.clients.horas12 == null || this.clients.horas24 == null) {
        this.impactedSelected.calculated = null
        return
      }

      var impactWeight = [
        { impactId: 1, value: 0.5 },
        { impactId: 2, value: 1 },
        { impactId: 3, value: 2 },
        { impactId: 4, value: 4 },
        { impactId: 5, value: 4.5 }
      ];

      // financialImpact
      var financialImpact = (
        (impactWeight.filter(item => item.impactId === this.financial.hora1.id)[0].value * 1) +
        (impactWeight.filter(item => item.impactId === this.financial.horas3.id)[0].value * 0.8) +
        (impactWeight.filter(item => item.impactId === this.financial.horas6.id)[0].value * 0.6) +
        (impactWeight.filter(item => item.impactId === this.financial.horas12.id)[0].value * 0.4) +
        (impactWeight.filter(item => item.impactId === this.financial.horas24.id)[0].value * 0.2)
      ) * 2;

      // regulatoryImpact
      var regulatoryImpact = (
        (impactWeight.filter(item => item.impactId === this.regulatory.hora1.id)[0].value * 1) +
        (impactWeight.filter(item => item.impactId === this.regulatory.horas3.id)[0].value * 0.8) +
        (impactWeight.filter(item => item.impactId === this.regulatory.horas6.id)[0].value * 0.6) +
        (impactWeight.filter(item => item.impactId === this.regulatory.horas12.id)[0].value * 0.4) +
        (impactWeight.filter(item => item.impactId === this.regulatory.horas24.id)[0].value * 0.2)
      ) * 0.8;

      // reputationalImpact
      var reputationalImpact = (
        (impactWeight.filter(item => item.impactId === this.reputational.hora1.id)[0].value * 1) +
        (impactWeight.filter(item => item.impactId === this.reputational.horas3.id)[0].value * 0.8) +
        (impactWeight.filter(item => item.impactId === this.reputational.horas6.id)[0].value * 0.6) +
        (impactWeight.filter(item => item.impactId === this.reputational.horas12.id)[0].value * 0.4) +
        (impactWeight.filter(item => item.impactId === this.reputational.horas24.id)[0].value * 0.2)
      ) * 0.6;

      // clientsImpact
      var clientsImpact = (
        (impactWeight.filter(item => item.impactId === this.clients.hora1.id)[0].value * 1) +
        (impactWeight.filter(item => item.impactId === this.clients.horas3.id)[0].value * 0.8) +
        (impactWeight.filter(item => item.impactId === this.clients.horas6.id)[0].value * 0.6) +
        (impactWeight.filter(item => item.impactId === this.clients.horas12.id)[0].value * 0.4) +
        (impactWeight.filter(item => item.impactId === this.clients.horas24.id)[0].value * 0.2)
      ) * 0.4;

      // operationalImpact
      var operationalImpact = (
        (impactWeight.filter(item => item.impactId === this.operational.hora1.id)[0].value * 1) +
        (impactWeight.filter(item => item.impactId === this.operational.horas3.id)[0].value * 0.8) +
        (impactWeight.filter(item => item.impactId === this.operational.horas6.id)[0].value * 0.6) +
        (impactWeight.filter(item => item.impactId === this.operational.horas12.id)[0].value * 0.4) +
        (impactWeight.filter(item => item.impactId === this.operational.horas24.id)[0].value * 0.2)
      ) * 0.2;

      this.impactedSelected.calculated
      var calculatedImpact = financialImpact + regulatoryImpact + reputationalImpact + clientsImpact + operationalImpact

      if (calculatedImpact < 6) {
        // "Imaterial"
        this.impactedSelected.calculated = FieldService.data.bia.impactsBia.filter(item => item.id == 1)[0]
      }

      else if (calculatedImpact >= 6 && calculatedImpact < 14) {
        // "Baixo" 
        this.impactedSelected.calculated = FieldService.data.bia.impactsBia.filter(item => item.id == 2)[0]
      }

      else if (calculatedImpact >= 14 && calculatedImpact < 35) {
        // "Médio"
        this.impactedSelected.calculated = FieldService.data.bia.impactsBia.filter(item => item.id == 3)[0]
      }

      else if (calculatedImpact >= 35 && calculatedImpact < 44) {
        // "Alto"
        this.impactedSelected.calculated = FieldService.data.bia.impactsBia.filter(item => item.id == 4)[0]
      }

      else if (calculatedImpact >= 44) {
        // "Extremo"
        this.impactedSelected.calculated = FieldService.data.bia.impactsBia.filter(item => item.id == 5)[0]
      }
    },
    async populateHierarchyArray() {
      function visitNodes(tree, path = []) {
        if (!tree) {
          return [];
        }

        path.push(tree.label);
        const info = {
          id: tree.id,
          label: tree.label,
          path: path.join(" -> "),
          selectable: tree.selectable,
        };

        const result = [info];

        if (tree.children && tree.children?.length > 0) {
          for (const children of tree.children) {
            result.push(...visitNodes(children, path.slice()));
          }
        }

        return result;
      }

      for (let i = 0; i < this.areaHierarchy?.length; i++) {
        this.allAreasList = this.allAreasList.concat(
          visitNodes(this.areaHierarchy[i])
        );
      }

      return true
    },
    getImpactSeverityColor(value) {
      if (value == 1) {
        return "#43b4ff"
      }
      if (value == 2) {
        return "#307AE0"
      }
      if (value == 3) {
        return "#FFCA43"
      }
      if (value == 4) {
        return "#EB3E48"
      }
      if (value == 5) {
        return "#754CC5"
      }

      return 'white'
    },
    valuateRTO() {

      if (this.mountingPage == false) {
        if (this.timeSelected.rtoUser !== null) {
          this.$toast.add({
            severity: "warn",
            summary: this.$langGetValue('userRecoveryTimeObjective'),
            detail: this.$langGetValue('deletedField'),
            life: 5000,
          });
        }

        this.timeSelected.rtoUser = null
      }

      if (this.financial.hora1 == null || this.financial.horas3 == null || this.financial.horas6 == null || this.financial.horas12 == null || this.financial.horas24 == null) {
        this.timeSelected.rtoCalculated = null
        return
      }
      if (this.regulatory.hora1 == null || this.regulatory.horas3 == null || this.regulatory.horas6 == null || this.regulatory.horas12 == null || this.regulatory.horas24 == null) {
        this.timeSelected.rtoCalculated = null
        return
      }
      if (this.reputational.hora1 == null || this.reputational.horas3 == null || this.reputational.horas6 == null || this.reputational.horas12 == null || this.reputational.horas24 == null) {
        this.timeSelected.rtoCalculated = null
        return
      }
      if (this.operational.hora1 == null || this.operational.horas3 == null || this.operational.horas6 == null || this.operational.horas12 == null || this.operational.horas24 == null) {
        this.timeSelected.rtoCalculated = null
        return
      }
      if (this.clients.hora1 == null || this.clients.horas3 == null || this.clients.horas6 == null || this.clients.horas12 == null || this.clients.horas24 == null) {
        this.timeSelected.rtoCalculated = null
        return
      }

      var oneHour = [
        {
          category: "financial",
          value: this.financial.hora1.id
        },
        {
          category: "regulatory",
          value: this.regulatory.hora1.id
        },
        {
          category: "reputational",
          value: this.reputational.hora1.id
        },
        {
          category: "clients",
          value: this.clients.hora1.id
        },
        {
          category: "operational",
          value: this.operational.hora1.id
        }
      ]

      var threeHours = [
        {
          category: "financial",
          value: this.financial.horas3.id
        },
        {
          category: "regulatory",
          value: this.regulatory.horas3.id
        },
        {
          category: "reputational",
          value: this.reputational.horas3.id
        },
        {
          category: "clients",
          value: this.clients.horas3.id
        },
        {
          category: "operational",
          value: this.operational.horas3.id
        }
      ]

      var sixHours = [
        {
          category: "financial",
          value: this.financial.horas6.id
        },
        {
          category: "regulatory",
          value: this.regulatory.horas6.id
        },
        {
          category: "reputational",
          value: this.reputational.horas6.id
        },
        {
          category: "clients",
          value: this.clients.horas6.id
        },
        {
          category: "operational",
          value: this.operational.horas6.id
        }
      ]

      var twelveHours = [
        {
          category: "financial",
          value: this.financial.horas12.id
        },
        {
          category: "regulatory",
          value: this.regulatory.horas12.id
        },
        {
          category: "reputational",
          value: this.reputational.horas12.id
        },
        {
          category: "clients",
          value: this.clients.horas12.id
        },
        {
          category: "operational",
          value: this.operational.horas12.id
        }
      ]

      var twentyFourHours = [
        {
          category: "financial",
          value: this.financial.horas24.id
        },
        {
          category: "regulatory",
          value: this.regulatory.horas24.id
        },
        {
          category: "reputational",
          value: this.reputational.horas24.id
        },
        {
          category: "clients",
          value: this.clients.horas24.id
        },
        {
          category: "operational",
          value: this.operational.horas24.id
        }
      ]

      var greaterValue
      var tempArray = []
      var tempValue

      // 1 hora
      if (oneHour.some(item => item.value === 4 || item.value === 5)) {
        this.timeSelected.rtoCalculated = FieldService.data.bia.impactHours.filter(item => item.id == 1)[0]
        return
      }

      if (oneHour.filter(item => item.value === 3).length == 3 && oneHour[0].value == 3) {
        this.timeSelected.rtoCalculated = FieldService.data.bia.impactHours.filter(item => item.id == 1)[0]
        return
      }

      if (oneHour.filter(item => item.value === 3).length >= 4) {
        this.timeSelected.rtoCalculated = FieldService.data.bia.impactHours.filter(item => item.id == 1)[0]
        return
      }

      oneHour.forEach((impact) => {
        tempValue = this.biaOptions.impactsFinancial1.filter(item => item.id === impact.value)[0].id
        tempArray.push(tempValue)
      })

      greaterValue = {
        rto: FieldService.data.bia.impactHours.filter(item => item.id == 1)[0],
        value: Math.max(...tempArray)
      }

      // 3 horas
      if (threeHours.some(item => item.value === 4 || item.value === 5)) {
        this.timeSelected.rtoCalculated = FieldService.data.bia.impactHours.filter(item => item.id == 2)[0]
        return
      }

      if (threeHours.filter(item => item.value === 3).length == 3 && threeHours[0].value == 3) {
        this.timeSelected.rtoCalculated = FieldService.data.bia.impactHours.filter(item => item.id == 2)[0]
        return
      }

      if (threeHours.filter(item => item.value === 3).length >= 4) {
        this.timeSelected.rtoCalculated = FieldService.data.bia.impactHours.filter(item => item.id == 2)[0]
        return
      }

      tempArray = []

      threeHours.forEach((impact) => {
        tempValue = this.biaOptions.impactsFinancial1.filter(item => item.id === impact.value)[0].id
        tempArray.push(tempValue)
      })

      if (Math.max(...tempArray) > greaterValue.value) {
        greaterValue = {
          rto: FieldService.data.bia.impactHours.filter(item => item.id == 2)[0],
          value: Math.max(...tempArray)
        }
      }

      // 6 horas
      if (sixHours.some(item => item.value === 4 || item.value === 5)) {
        this.timeSelected.rtoCalculated = FieldService.data.bia.impactHours.filter(item => item.id == 3)[0]
        return
      }

      if (sixHours.filter(item => item.value === 3).length == 3 && sixHours[0].value == 3) {
        this.timeSelected.rtoCalculated = FieldService.data.bia.impactHours.filter(item => item.id == 3)[0]
        return
      }

      if (sixHours.filter(item => item.value === 3).length >= 4) {
        this.timeSelected.rtoCalculated = FieldService.data.bia.impactHours.filter(item => item.id == 3)[0]
        return
      }

      tempArray = []

      sixHours.forEach((impact) => {
        tempValue = this.biaOptions.impactsFinancial1.filter(item => item.id === impact.value)[0].id
        tempArray.push(tempValue)
      })

      if (Math.max(...tempArray) > greaterValue.value) {
        greaterValue = {
          rto: FieldService.data.bia.impactHours.filter(item => item.id == 3)[0],
          value: Math.max(...tempArray)
        }
      }

      // 12 horas
      if (twelveHours.some(item => item.value === 4 || item.value === 5)) {
        this.timeSelected.rtoCalculated = FieldService.data.bia.impactHours.filter(item => item.id == 4)[0]
        return
      }

      if (twelveHours.filter(item => item.value === 3).length == 3 && twelveHours[0].value == 3) {
        this.timeSelected.rtoCalculated = FieldService.data.bia.impactHours.filter(item => item.id == 4)[0]
        return
      }

      if (twelveHours.filter(item => item.value === 3).length >= 4) {
        this.timeSelected.rtoCalculated = FieldService.data.bia.impactHours.filter(item => item.id == 4)[0]
        return
      }

      tempArray = []

      twelveHours.forEach((impact) => {
        tempValue = this.biaOptions.impactsFinancial1.filter(item => item.id === impact.value)[0].id
        tempArray.push(tempValue)
      })

      if (Math.max(...tempArray) > greaterValue.value) {
        greaterValue = {
          rto: FieldService.data.bia.impactHours.filter(item => item.id == 4)[0],
          value: Math.max(...tempArray)
        }
      }

      // 24 horas
      if (twentyFourHours.some(item => item.value === 4 || item.value === 5)) {
        this.timeSelected.rtoCalculated = FieldService.data.bia.impactHours.filter(item => item.id == 5)[0]
        return
      }

      if (twentyFourHours.filter(item => item.value === 3).length == 3 && twentyFourHours[0].value == 3) {
        this.timeSelected.rtoCalculated = FieldService.data.bia.impactHours.filter(item => item.id == 5)[0]
        return
      }

      if (twentyFourHours.filter(item => item.value === 3).length >= 4) {
        this.timeSelected.rtoCalculated = FieldService.data.bia.impactHours.filter(item => item.id == 5)[0]
        return
      }

      tempArray = []

      twentyFourHours.forEach((impact) => {
        tempValue = this.biaOptions.impactsFinancial1.filter(item => item.id === impact.value)[0].id
        tempArray.push(tempValue)
      })

      if (Math.max(...tempArray) > greaterValue.value) {
        greaterValue = {
          rto: FieldService.data.bia.impactHours.filter(item => item.id == 5)[0],
          value: Math.max(...tempArray)
        }
      }

      var allImpacts = []

      oneHour.forEach((item) => {
        allImpacts.push(item.value)
      })

      threeHours.forEach((item) => {
        allImpacts.push(item.value)
      })

      sixHours.forEach((item) => {
        allImpacts.push(item.value)
      })

      twelveHours.forEach((item) => {
        allImpacts.push(item.value)
      })

      twentyFourHours.forEach((item) => {
        allImpacts.push(item.value)
      })

      setTimeout(() => {
        if (allImpacts.every(item => item === allImpacts[0])) {
          this.timeSelected.rtoCalculated = FieldService.data.bia.impactHours[4]
          return
        }
        else {
          this.timeSelected.rtoCalculated = greaterValue.rto
          return
        }
      }, 200);

    },
    mountPayload() {
      var tempCompany = []

      this.companySelected.forEach((company) => {
        tempCompany.push(company.id)
      })

      var tempDailyExecutionTeam = []

      this.generalDataSelected.employees.forEach((user) => {
        tempDailyExecutionTeam.push(user.mail)
      })

      var tempBackupExecutionTeam = []

      this.generalDataSelected.employeesBackup.forEach((user) => {
        tempBackupExecutionTeam.push(user.mail)
      })

      var tempNormalExecutionLocations = []

      this.generalDataSelected.selectedExecutionLocation.forEach((location) => {
        tempNormalExecutionLocations.push(location.id)
      })

      var tempExecutionHour = []

      this.generalDataSelected.executionTimeRange.forEach((range) => {
        tempExecutionHour.push({
          startHour: range.startTime.hour + ':' + range.startTime.minute,
          endHour: range.endTime.hour + ':' + range.endTime.minute
        })
      })

      var tempExecutionHourCritical = []

      this.generalDataSelected.executionTimeRangeCritical.forEach((range) => {
        tempExecutionHourCritical.push({
          startHour: range.startTime.hour + ':' + range.startTime.minute,
          endHour: range.endTime.hour + ':' + range.endTime.minute
        })
      })

      var tempSystem = []

      if (this.systemConditional == this.$langGetValue('yes')) {
        this.systemsListSelected.forEach((systemSelected) => {
          var object = {
            systemId: systemSelected.system.id,
            systemDescription: systemSelected.description,
            freezePotential: systemSelected.paralyzingPotencial == this.$langGetValue('yes') ? true : false,
            contingencyStrategy: systemSelected.contingencyStrategy != null ? systemSelected.contingencyStrategy.id : null,
          }

          if (object.contingencyStrategy != null && object.contingencyStrategy != 1) {
            object.contingencyStrategyDescription = systemSelected.contingencyDescription

            if (object.contingencyStrategy == 4) {
              object.backupSystem = systemSelected.contingencyBackup.id
            }
          }
          else {
            object.contingencyStrategyDescription = null
          }
          tempSystem.push(object)
        })
      }

      var tempSupplier = []

      if (this.supplierConditional == this.$langGetValue('yes')) {
        this.supplierListSelected.forEach((supplierSelected) => {
          var object = {
            type: supplierSelected.type.id,
            supplierId: supplierSelected.supplier.id,
            supplierDescription: supplierSelected.description,
            freezePotential: supplierSelected.paralyzingPotencial == this.$langGetValue('yes') ? true : false,
            contingencyStrategy: supplierSelected.contingencyStrategy != null ? supplierSelected.contingencyStrategy.id : null,
          }
          if (object.contingencyStrategy != 1) {
            object.contingencyStrategyDescription = supplierSelected.contingencyDescription
          }
          else {
            object.contingencyStrategyDescription = null
          }
          tempSupplier.push(object)
        })
      }

      var tempInternalDependency = []

      if (this.internalDependencyConditional == this.$langGetValue('yes')) {
        this.internalDependencyListSelected.forEach((selectedInternalDependency) => {
          tempInternalDependency.push({
            type: selectedInternalDependency.type.id,
            areaId: selectedInternalDependency.area[0].id,
            dependencyDescription: selectedInternalDependency.description
          })
        })
      }

      var tempPersonalInfo = null

      if (this.dataSectionSelected.personalInformation != null) {
        tempPersonalInfo = this.dataSectionSelected.personalInformation == this.$langGetValue('yes') ? true : false
      }

      var tempSensitiveInfo = null

      if (this.dataSectionSelected.sensibleInformation != null) {
        tempSensitiveInfo = this.dataSectionSelected.sensibleInformation == this.$langGetValue('yes') ? true : false
      }

      var confidentialInfo = null

      if (this.dataSectionSelected.confidentialInformation != null) {
        confidentialInfo = this.dataSectionSelected.confidentialInformation == this.$langGetValue('yes') ? true : false
      }

      var tempContingencyEmployees = []
      if (this.contingencySelected.contingencyEmployees != null) {
        this.contingencySelected.contingencyEmployees.forEach((user) => {
          tempContingencyEmployees.push(user.mail)
        })
      }

      var payload = {
        "dailyExecutionTeam": tempDailyExecutionTeam,
        "backupExecutionTeam": tempBackupExecutionTeam,
        "normalExecutionLocations": tempNormalExecutionLocations,
        "executionHours": tempExecutionHour,
        "criticalExecutionHours": tempExecutionHourCritical,
        "system": tempSystem,
        "supplier": tempSupplier,
        "internalDependency": tempInternalDependency,
        "personalInfo": tempPersonalInfo,
        "sensitiveInfo": tempSensitiveInfo,
        "confidentialInfo": confidentialInfo,
        "impact": {
          "financialImpact": {
            "oneHour": this.financial.hora1?.id,
            "threeHour": this.financial.horas3?.id,
            "sixHour": this.financial.horas6?.id,
            "twelveHour": this.financial.horas12?.id,
            "twentyFourHour": this.financial.horas24?.id,
            "justification": this.financial.justify
          },
          "regulatoryImpact": {
            "oneHour": this.regulatory.hora1?.id,
            "threeHour": this.regulatory.horas3?.id,
            "sixHour": this.regulatory.horas6?.id,
            "twelveHour": this.regulatory.horas12?.id,
            "twentyFourHour": this.regulatory.horas24?.id,
            "justification": this.regulatory.justify
          },
          "reputationalImpact": {
            "oneHour": this.reputational.hora1?.id,
            "threeHour": this.reputational.horas3?.id,
            "sixHour": this.reputational.horas6?.id,
            "twelveHour": this.reputational.horas12?.id,
            "twentyFourHour": this.reputational.horas24?.id,
            "justification": this.reputational.justify
          },
          "clientImpact": {
            "oneHour": this.clients.hora1?.id,
            "threeHour": this.clients.horas3?.id,
            "sixHour": this.clients.horas6?.id,
            "twelveHour": this.clients.horas12?.id,
            "twentyFourHour": this.clients.horas24?.id,
            "justification": this.clients.justify
          },
          "operationalImpact": {
            "oneHour": this.operational.hora1?.id,
            "threeHour": this.operational.horas3?.id,
            "sixHour": this.operational.horas6?.id,
            "twelveHour": this.operational.horas12?.id,
            "twentyFourHour": this.operational.horas24?.id,
            "justification": this.operational.justify
          }
        },
        "calculatedImpact": this.impactedSelected.calculated != null ? this.impactedSelected.calculated.id : null,
        "roImpact": this.impactedSelected.ROSelected?.id,
        "roImpactJustification": this.impactedSelected.ROSelected != null ? this.impactedSelected.ROSelectedJustification : null,
        "calculatedRto": this.timeSelected.rtoCalculated != null ? this.timeSelected.rtoCalculated.id : null,
        "userRto": this.timeSelected.rtoUser?.id,
        "userRtoJustification": this.timeSelected.rtoUser !== null ? this.timeSelected.rtoUserJustify : null,
        "userRpo": this.timeSelected.rpoUser?.id,
        "contingencyMinimumRequiredPeople": this.contingencySelected.contingencyMinimumRequiredPeople,
        "contingencyEmployees": tempContingencyEmployees.length > 0 ? tempContingencyEmployees : null,
        "company": tempCompany.length > 0 ? tempCompany : null,
        "capability": this.capabilitySelected !== null ? this.capabilitySelected.id : null,
      }

      payload.periodType = this.generalDataSelected.selectedPeriod?.id

      if (payload.periodType == 1) {
        payload.dailyFrequency = this.generalDataSelected.dailyFrequency
      }
      else if (payload.periodType == 2) {
        var tempWeekFrequency = []
        this.generalDataSelected.weeklyFrequency.forEach((weekDay) => {
          tempWeekFrequency.push(weekDay.id)
        })
        payload.weekFrequency = tempWeekFrequency
      }
      else if (payload.periodType == 3) {
        payload.monthFrequency = this.generalDataSelected.monthFrequency
      }
      else if (payload.periodType == 4) {
        payload.semesterFrequency = {
          firstSemester: {
            month: this.generalDataSelected.firstSemesterFrequency?.month,
            day: this.generalDataSelected.firstSemesterFrequency?.days
          },
          secondSemester: {
            month: this.generalDataSelected.secondSemesterFrequency?.month,
            day: this.generalDataSelected.secondSemesterFrequency?.days
          }
        }
      }
      else if (payload.periodType == 5) {
        payload.yearFrequency = {
          month: this.generalDataSelected.yearFrequency?.month,
          day: this.generalDataSelected.yearFrequency?.days
        }
      }
      else if (payload.periodType == 6) {
        payload.otherFrequency = this.generalDataSelected.otherFrequency
      }

      if (this.formData.data.fields.bpmRejectionJustification != null) {
        payload.bpmRejectionJustification = this.formData.data.fields.bpmRejectionJustification
      }

      if (this.formData.data.fields.roRejectionJustification != null) {
        payload.roRejectionJustification = this.formData.data.fields.roRejectionJustification
      }

      return payload
    },
    checkAllFields() {
      var emptyFields = []
      if (this.companySelected.length == 0) {
        emptyFields.push(this.$langGetValue('company'))
        this.isInvalidGeneral.selectedCompany = true
      }
      else {
        this.isInvalidGeneral.selectedCompany = false
      }

      if (this.capabilitySelected == null) {
        emptyFields.push(this.$langGetValue('capability'))
        this.isInvalidGeneral.selectedCapability = true
      }
      else {
        this.isInvalidGeneral.selectedCapability = false
      }

      if (this.generalDataSelected.employees.length == 0) {
        emptyFields.push(this.$langGetValue('involvedEmployees'))
        this.isInvalidGeneral.employees = true
      }
      else {
        this.isInvalidGeneral.employees = false
      }

      if (this.generalDataSelected.employeesBackup.length == 0) {
        emptyFields.push(this.$langGetValue('backupEmployees'))
        this.isInvalidGeneral.employeesBackup = true
      }
      else {
        this.isInvalidGeneral.employeesBackup = false
      }

      if (this.generalDataSelected.selectedExecutionLocation.length == 0) {
        emptyFields.push(this.$langGetValue('executionLocations'))
        this.isInvalidGeneral.selectedExecutionLocation = true
      }
      else {
        this.isInvalidGeneral.selectedExecutionLocation = false
      }

      if (this.generalDataSelected.selectedPeriod == null) {
        emptyFields.push(this.$langGetValue('processExecutionFrequency'))
        this.isInvalidGeneral.selectedPeriod = true
      }
      else {
        this.isInvalidGeneral.selectedPeriod = false
        // Diário
        if (this.generalDataSelected.selectedPeriod.id == 1 && this.generalDataSelected.dailyFrequency == null) {
          emptyFields.push(this.$langGetValue('executions'))
          this.isInvalidGeneral.dailyFrequency = true
        }
        else {
          this.isInvalidGeneral.dailyFrequency = false
        }
        // Semanal
        if (this.generalDataSelected.selectedPeriod.id == 2 && this.generalDataSelected.weeklyFrequency.length == 0) {
          emptyFields.push(this.$langGetValue('weekDays'))
          this.isInvalidGeneral.weeklyFrequency = true
        }
        else {
          this.isInvalidGeneral.weeklyFrequency = false
        }
        // Mensal
        if (this.generalDataSelected.selectedPeriod.id == 3 && (this.generalDataSelected.monthFrequency == null || this.generalDataSelected.monthFrequency.days.length == 0)) {
          emptyFields.push(this.$langGetValue('daysOfMonth'))
          this.isInvalidGeneral.monthFrequency = true
        }
        else {
          this.isInvalidGeneral.monthFrequency = false
        }
        // Semestral
        if (this.generalDataSelected.selectedPeriod.id == 4) {
          if (this.generalDataSelected.firstSemesterFrequency == null) {
            emptyFields.push(this.$langGetValue('firstSemester'))
            this.isInvalidGeneral.firstSemesterFrequency = true
          }
          else {
            this.isInvalidGeneral.firstSemesterFrequency = false
          }

          if (this.generalDataSelected.secondSemesterFrequency == null) {
            emptyFields.push(this.$langGetValue('secondSemester'))
            this.isInvalidGeneral.secondSemesterFrequency = true
          }
          else {
            this.isInvalidGeneral.secondSemesterFrequency = false
          }
        }
        // Anual
        if (this.generalDataSelected.selectedPeriod.id == 5 && this.generalDataSelected.yearFrequency == null) {
          emptyFields.push(this.$langGetValue('dayAndMonth'))
          this.isInvalidGeneral.yearFrequency = true
        }
        else {
          this.isInvalidGeneral.yearFrequency = false
        }
        // Outro
        if (this.generalDataSelected.selectedPeriod.id == 6) {
          if (this.generalDataSelected.otherFrequency == null || this.generalDataSelected.otherFrequency.trim().length == 0) {
            emptyFields.push(this.$langGetValue('otherOption'))
            this.isInvalidGeneral.otherFrequency = true
          }
          else {
            this.isInvalidGeneral.otherFrequency = false
          }
        }
      }

      if (this.generalDataSelected.executionTimeRange.length == 0) {
        emptyFields.push(this.$langGetValue('biaFieldExecutionHours'))
        this.isInvalidGeneral.executionTimeRange = true
      }
      else {
        this.isInvalidGeneral.executionTimeRange = false
      }

      if (this.generalDataSelected.executionTimeRangeCritical.length == 0) {
        emptyFields.push(this.$langGetValue('biaFieldCriticalExecutionHours'))
        this.isInvalidGeneral.executionTimeRangeCritical = true
      }
      else {
        this.isInvalidGeneral.executionTimeRangeCritical = false
      }

      if ((this.systemConditional == this.$langGetValue('yes') && this.systemsListSelected.length == 0) || this.systemConditional == null) {
        emptyFields.push(this.$langGetValue('systems'))
        this.isInvalidGeneral.system = true
      }
      else {
        this.isInvalidGeneral.system = false
      }

      if ((this.supplierConditional == this.$langGetValue('yes') && this.supplierListSelected.length == 0) || this.supplierConditional == null) {
        emptyFields.push(this.$langGetValue('suppliers'))
        this.isInvalidGeneral.supplier = true
      }
      else {
        this.isInvalidGeneral.supplier = false
      }

      if ((this.internalDependencyConditional == this.$langGetValue('yes') && this.internalDependencyListSelected == 0) || this.internalDependencyConditional == null) {
        emptyFields.push(this.$langGetValue('biaFieldInternalDependency'))
        this.isInvalidGeneral.internalDependency = true
      }
      else {
        this.isInvalidGeneral.internalDependency = false
      }

      if (this.dataSectionSelected.personalInformation == null) {
        emptyFields.push(this.$langGetValue('biaFieldPersonalInfo'))
        this.isInvalidGeneral.personalInformation = true
      }
      else {
        this.isInvalidGeneral.personalInformation = false
      }

      if (this.dataSectionSelected.sensibleInformation == null) {
        emptyFields.push(this.$langGetValue('biaFieldSensitiveInfo'))
        this.isInvalidGeneral.sensibleInformation = true
      }
      else {
        this.isInvalidGeneral.sensibleInformation = false
      }

      if (this.dataSectionSelected.confidentialInformation == null) {
        emptyFields.push(this.$langGetValue('biaFieldConfidentialInfo'))
        this.isInvalidGeneral.confidentialInformation = true
      }
      else {
        this.isInvalidGeneral.confidentialInformation = false
      }

      var addImpact = false

      if (this.financial.hora1 == null || this.financial.horas3 == null || this.financial.horas6 == null || this.financial.horas12 == null || this.financial.horas24 == null) {
        addImpact = true
      }
      else {
        if (this.financialJustify && (this.financial.justify == null || this.financial.justify.trim().length < 30)) {
          addImpact = true
        }
      }

      if (this.regulatory.hora1 == null || this.regulatory.horas3 == null || this.regulatory.horas6 == null || this.regulatory.horas12 == null || this.regulatory.horas24 == null) {
        addImpact = true
      }
      else {
        if (this.regulatoryJustify && (this.regulatory.justify == null || this.regulatory.justify.trim().length < 30)) {
          addImpact = true
        }
      }

      if (this.reputational.hora1 == null || this.reputational.horas3 == null || this.reputational.horas6 == null || this.reputational.horas12 == null || this.reputational.horas24 == null) {
        addImpact = true
      }
      else {
        if (this.reputationalJustify && (this.reputational.justify == null || this.reputational.justify.trim().length < 30)) {
          addImpact = true
        }
      }

      if (this.operational.hora1 == null || this.operational.horas3 == null || this.operational.horas6 == null || this.operational.horas12 == null || this.operational.horas24 == null) {
        addImpact = true
      }
      else {
        if (this.operationalJustify && (this.operational.justify == null || this.operational.justify.trim().length < 30)) {
          addImpact = true
        }
      }

      if (this.clients.hora1 == null || this.clients.horas3 == null || this.clients.horas6 == null || this.clients.horas12 == null || this.clients.horas24 == null) {
        addImpact = true
      }
      else {
        if (this.clientsJustify && (this.clients.justify == null || this.clients.justify.trim().length < 30)) {
          addImpact = true
        }
      }

      if (addImpact) {
        emptyFields.push(this.$langGetValue('biaFieldImpact'))
        this.isInvalidGeneral.impact = true
      }
      else {
        this.isInvalidGeneral.impact = false
      }

      if (this.impactedSelected.ROSelected != null && (this.impactedSelected.ROSelectedJustification == null || this.impactedSelected.ROSelectedJustification.trim().length < 30)) {
        emptyFields.push(this.$langGetValue('biaFieldRoImpactJustification'))
        this.isInvalidGeneral.ROSelectedJustification = true
      }
      else {
        this.isInvalidGeneral.ROSelectedJustification = false
      }

      if (this.timeSelected.rtoUser != null && (this.timeSelected.rtoUserJustify == null || this.timeSelected.rtoUserJustify.trim().length < 30)) {
        emptyFields.push(this.$langGetValue('biaFieldUserRtoJustification'))
        this.isInvalidGeneral.rtoUserJustify = true
      }
      else {
        this.isInvalidGeneral.rtoUserJustify = false
      }

      if (this.timeSelected.rpoUser == null) {
        emptyFields.push("Recovery Point Objective")
        this.isInvalidGeneral.rpo = true
      }
      else {
        this.isInvalidGeneral.rpo = false
      }

      if (this.contingencySelected.contingencyMinimumRequiredPeople == null) {
        emptyFields.push(this.$langGetValue('biaFieldContingencyMinimumRequiredPeople'))
        this.isInvalidGeneral.contingencyMinimumRequiredPeople = true
      }
      else {
        this.isInvalidGeneral.contingencyMinimumRequiredPeople = false
      }

      if (this.contingencySelected.contingencyEmployees.length == 0) {
        emptyFields.push(this.$langGetValue('biaFieldContingencyEmployees'))
        this.isInvalidGeneral.contingencyEmployees = true
      }
      else {
        this.isInvalidGeneral.contingencyEmployees = false
      }

      if (emptyFields.length > 0) {
        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('blankFields'),
          detail: this.$langGetValue('fillFields') + emptyFields.join(', '),
          life: 5000,
        });
        return
      }

      if (this.formData.status == 1 || this.formData.status == 5 || this.formData.status == 6) {
        this.warningDialogREX = true
      }

      if (this.formData.status == 2) {
        this.warningDialogBPM = true
      }

      if (this.formData.status == 3) {
        this.warningDialogRO = true
      }
    },
    sendDraft() {
      Util.loading = true

      var payload = this.mountPayload()

      BiaService.saveDraft(payload, this.formData.id).then((response) => {
        Util.loading = false
        if (response?.success) {
          this.$toast.add({
            severity: "success",
            summary: this.$langGetValue('formSaved'),
            life: 5000,
          });
        }
        else {
          this.$toast.add({
            severity: "error",
            summary: this.$langGetValue('saveError'),
            life: 5000,
          });
        }
      })
    },
    sendForm() {
      Util.loading = true

      var payload = this.mountPayload()

      BiaService.saveForm(payload, this.formData.id).then((response) => {
        Util.loading = false
        if (response?.success == true) {
          window.location.reload()
        }
        else {
          this.$toast.add({
            severity: "error",
            summary: this.$langGetValue('sendError'),
            life: 5000,
          });
        }

      })
    },
    bpmRejectForm() {
      if (this.bpmRejectionJustification == null || this.bpmRejectionJustification.trim().length < 30) {
        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('invalidJustification'),
          detail: this.$langGetValue('invalidJustificationLength'),
          life: 5000,
        });
        return
      }

      Util.loading = true

      var payload = this.mountPayload()

      payload.bpmRejectionJustification = this.bpmRejectionJustification,

        BiaService.bpmReject(payload, this.formData.id).then((response) => {
          Util.loading = false
          if (response?.success) {
            window.location.reload()
          }
          else {
            this.$toast.add({
              severity: "error",
              summary: this.$langGetValue('rejectError'),
              life: 5000,
            });
          }
        })
    },
    bpmApproveForm() {
      Util.loading = true

      var payload = this.mountPayload()

      BiaService.bpmApprove(payload, this.formData.id).then((response) => {
        Util.loading = false
        if (response?.success) {
          window.location.reload()
        }
        else {
          this.$toast.add({
            severity: "error",
            summary: this.$langGetValue('approvalError'),
            life: 5000,
          });
        }
      })
    },
    roRejectForm() {
      if (this.roRejectionJustification == null || this.roRejectionJustification.trim().length < 30) {
        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('invalidJustification'),
          detail: this.$langGetValue('invalidJustificationLength'),
          life: 5000,
        });
        return
      }

      Util.loading = true

      var payload = this.mountPayload()

      payload.roRejectionJustification = this.roRejectionJustification

      BiaService.roReject(payload, this.formData.id).then((response) => {
        Util.loading = false
        if (response?.success) {
          window.location.reload()
        }
        else {
          this.$toast.add({
            severity: "error",
            summary: this.$langGetValue('rejectError'),
            life: 5000,
          });
        }
      })
    },
    roApproveForm() {
      Util.loading = true

      var payload = this.mountPayload()

      BiaService.roApprove(payload, this.formData.id).then((response) => {
        Util.loading = false
        if (response?.success) {
          window.location.reload()
        }
        else {
          this.$toast.add({
            severity: "error",
            summary: this.$langGetValue('approvalError'),
            life: 5000,
          });
        }
      })
    },
    altBpm() {
      if (this.newBpm?.mail != null) {
        Util.loading = true

        let payload = {
          form: [this.formData.id],
          bpm: this.newBpm.mail
        }

        BiaService.changeBpm(payload).then(() => {
          Util.loading = false
          window.location.reload()
        })
      }
      else {
        this.$toast.add({
          severity: "error",
          summary: "Selecione um BPM",
          life: 5000,
        });

        return
      }
    },
    altRex() {
      if (this.newRex?.mail != null) {
        Util.loading = true

        let payload = {
          form: [this.formData.id],
          rex: this.newRex.mail
        }

        BiaService.changeRex(payload).then(() => {
          Util.loading = false
          window.location.reload()
        })
      }

      else {
        this.$toast.add({
          severity: "error",
          summary: "Selecione um REX",
          life: 5000,
        });

        return
      }
    },
    altDescription() {
      if (this.newDescription == null || this.newDescription.trim().length < 30) {
        this.$toast.add({
          severity: "error",
          summary: "Descrição deve ter pelo menos 30 caracteres",
          life: 5000,
        });

        return
      }

      Util.loading = true

      let payload = {
        form: this.formData.id,
        description: this.newDescription
      }

      BiaService.changeDescription(payload).then(() => {
        Util.loading = false
        window.location.reload()
      })
    },
    altName() {
      if (this.newName == null || this.newName.trim().length < 10) {
        this.$toast.add({
          severity: "error",
          summary: "Nome deve ter pelo menos 10 caracteres",
          life: 5000,
        });

        return
      }

      Util.loading = true

      let payload = {
        form: this.formData.id,
        name: this.newName
      }

      BiaService.changeName(payload).then(() => {
        Util.loading = false
        window.location.reload()
      })
    },
    filterCompany(event) {
      if (event.value.trim().length === 0) {
        this.filteredCompany = this.biaOptions.company;
        return;
      }

      let tempCompany = [];

      this.biaOptions.company.forEach((group) => {
        let matchedChildren = group.children.filter((child) =>
          child.label.toLowerCase().includes(event.value.toLowerCase())
        );

        if (matchedChildren.length > 0) {
          let tempGroup = { ...group };
          tempGroup.children = matchedChildren;
          tempCompany.push(tempGroup);
        }
      });

      this.filteredCompany = [...tempCompany];
    }
  },
};
</script>
<style>
.biggerStepper .activeText {
  font-size: 14px;
}

.biggerStepper .inactiveText,
.biggerStepper .doneText {
  font-size: 12px;
}

.biggerStepper .pi {
  font-size: 1rem;
}

/* .time .p-datepicker {
  border-width: 0px;
  padding: 0px;
}

.time .p-datepicker .p-timepicker span {
  font-size: 1rem;
}

.p-datepicker .p-timepicker button {
  height: 1.5rem;
}

.time .p-timepicker {
  padding: 0px;
} */

.p-inputswitch .p-inputswitch-slider {
  background-color: var(--color_gray_medium);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background-color: var(--color_feedback_positive);
}

.p-autocomplete-input {
  width: 100%;
}

/* .calendar-container .p-datepicker .p-datepicker-header,
.calendar-container th {
  display: none;
}

.calendar-container .p-datepicker td.p-datepicker-today span {
  background-color: transparent;
} */

.scroll::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100px;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
  background: var(--color_brand_medium);
  border-radius: 100px;
}

.scrollDialog .p-dialog-content::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.scrollDialog .p-dialog-content::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100px;
}

.dialogBorder .p-dialog-content {
  border-radius: 0px 0px 8px 8px;
}

/* Handle */
.scrollDialog .p-dialog-content::-webkit-scrollbar-thumb {
  background: var(--color_brand_medium);
  border-radius: 100px;
}

.legend {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.square {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.legend span {
  color: black;
}

.hourDialog .p-dialog-content {
  border-radius: 8px 8px 0px 0px;
}

.customDataTable th {
  display: none;
}

.customDataTable .p-datatable-tbody tr td {
  padding-top: 0px;
  padding-bottom: 0px;
}

.customDataTable .p-datatable-loading-overlay {
  display: none;
}

.customDataTable .p-datatable-row-expansion {
  background: #f7f7f7 !important;
}

.customDialogDependency .p-dialog-content {
  background: transparent;
}

.customDialogDependency {
  box-shadow: none
}

.customDialogDependency .p-dialog-header {
  display: none;
}

.customAutoComplete .p-autocomplete-multiple-container {
  gap: 4px;
}

.datepickerInvalid .card {
  border: 1px solid #e24c4c;
}

.executionHoursInvalid,
.impactInvalid {
  border: 1px solid #e24c4c;
  ;
  border-radius: 8px;
}

.customDropdownImpact .p-dropdown-label {
  color: black
}

.hourButton:focus {
  color: var(--color_brand_medium);
}


.button-like {
  display: inline-block;
  height: 35px;
  width: 65px;
  background-color: #efefef;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  padding: 5px 10px;
}

.image-style {
  height: fit-content;
  width: fit-content;
}

.image-dialog .p-dialog-header {
  display: none
}

.image-dialog .p-dialog-content {
  padding: 0;
  background: transparent;
}
</style>
