<template>
    <div class="calendar-container" v-if="currentMonth != null">
        <div class="" style="width: fit-content" :class="{ 'card': true, 'card_disabled': disabled }">
            <div class="flex justify-content-between" v-if="showMonth && !disabled">
                <i class="pi pi-angle-left" style="font-size: 2rem; cursor: pointer;" @click="previousMonth()"></i>
                <span>{{ currentMonth.label }}</span>
                <i class="pi pi-angle-right" style="font-size: 2rem; cursor: pointer;" @click="nextMonth()"></i>
            </div>
            <div class="flex justify-content-center" v-if="showMonth && disabled">
                <span style="font-weight: bold">{{ currentMonth.label }}</span>
            </div>
            <table class="mt-2">
                <tr v-for="i in 4" :key="i">
                    <td v-for="j in 7" :key="j">
                        <span @mouseover="setColorIn($event)" @mouseout="setColorOut($event)"
                            :id="getValue(i, j) + this.semester" :class="{ 'day': !disabled, 'day_disabled': disabled }"
                            @click="selectADay(getValue(i, j))">
                            {{ getValue(i, j) }}
                        </span>
                    </td>
                </tr>
                <tr v-show="currentMonth.id != 1">
                    <td>
                        <span @mouseover="setColorIn($event)" @mouseout="setColorOut($event)" :id="'29' + this.semester"
                            :class="{ 'day': !disabled, 'day_disabled': disabled }" @click="selectADay(29)">
                            29
                        </span>
                    </td>
                    <td>
                        <span @mouseover="setColorIn($event)" @mouseout="setColorOut($event)" :id="'30' + this.semester"
                            :class="{ 'day': !disabled, 'day_disabled': disabled }" @click="selectADay(30)">
                            30
                        </span>
                    </td>
                    <td
                        v-show="currentMonth.id == 0 || currentMonth.id == 2 || currentMonth.id == 4 || currentMonth.id == 6 || currentMonth.id == 7 || currentMonth.id == 8 || currentMonth.id == 11">
                        <span @mouseover="setColorIn($event)" @mouseout="setColorOut($event)" :id="'31' + this.semester"
                            :class="{ 'day': !disabled, 'day_disabled': disabled }" @click="selectADay(31)">
                            31
                        </span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
  
<script>

export default {
    props: {
        selectionMode: String,
        showMonth: Boolean,
        semester: String,
        value: Object,
        disabled: Boolean
    },
    data() {
        return {
            id: 0,
            selectedDays: [],
            selectedDay: null,
            months: [],
            currentMonth: null
        };
    },
    watch: {

    },
    mounted() {
        if (this.semester == "0") {
            this.months = [
                { label: this.$langGetValue('january'), id: 0, value: "January" },
                { label: this.$langGetValue('february'), id: 1, value: "February" },
                { label: this.$langGetValue('march'), id: 2, value: "March" },
                { label: this.$langGetValue('april'), id: 3, value: "April" },
                { label: this.$langGetValue('may'), id: 4, value: "May" },
                { label: this.$langGetValue('june'), id: 5, value: "June" },
                { label: this.$langGetValue('july'), id: 6, value: "July" },
                { label: this.$langGetValue('august'), id: 7, value: "August" },
                { label: this.$langGetValue('september'), id: 8, value: "September" },
                { label: this.$langGetValue('october'), id: 9, value: "October" },
                { label: this.$langGetValue('november'), id: 10, value: "November" },
                { label: this.$langGetValue('december'), id: 11, value: "December" }
            ]
        }
        else if (this.semester == "1") {
            this.months = [
                { label: this.$langGetValue('january'), id: 0, value: "January" },
                { label: this.$langGetValue('february'), id: 1, value: "February" },
                { label: this.$langGetValue('march'), id: 2, value: "March" },
                { label: this.$langGetValue('april'), id: 3, value: "April" },
                { label: this.$langGetValue('may'), id: 4, value: "May" },
                { label: this.$langGetValue('june'), id: 5, value: "June" }
            ]
        }
        else if (this.semester == "2") {
            this.months = [
                { label: this.$langGetValue('july'), id: 6, value: "July" },
                { label: this.$langGetValue('august'), id: 7, value: "August" },
                { label: this.$langGetValue('september'), id: 8, value: "September" },
                { label: this.$langGetValue('october'), id: 9, value: "October" },
                { label: this.$langGetValue('november'), id: 10, value: "November" },
                { label: this.$langGetValue('december'), id: 11, value: "December" }
            ]
        }

        if (this.showMonth == false || this.showMonth == undefined) {
            this.currentMonth = { label: this.$langGetValue('march'), id: 2, value: "March" }
        }
        else {
            this.currentMonth = this.months[0]
        }

        if (this.value != undefined && this.value != null) {
            if ("month" in this.value) {
                this.currentMonth = this.months.filter(item => item.value == this.value.month)[0]
            }

            setTimeout(() => {
                this.value.days.forEach((day) => {
                    this.selectADay(day, true)
                })
            }, 200);
        }
    },
    methods: {
        setColorIn(event) {
            if (this.disabled) {
                return
            }

            if (event.target.style.backgroundColor != 'rgb(25, 90, 180)') {
                event.target.style.backgroundColor = 'rgb(107, 170, 255)'
            }
        },
        setColorOut(event) {
            if (this.disabled) {
                return
            }

            if (event.target.style.backgroundColor != 'rgb(25, 90, 180)') {
                event.target.style.backgroundColor = 'rgb(234, 235, 235)'
            }
        },
        getValue(i, j) {
            const baseValues = [0, 7, 14, 21];
            if (i >= 1 && i <= 4) {
                return baseValues[i - 1] + j;
            }
        },
        previousMonth() {
            let tempIndex = this.months.indexOf(this.currentMonth) - 1

            if (tempIndex >= 0) {
                this.currentMonth = this.months[tempIndex]
            }
        },
        nextMonth() {
            let tempIndex = this.months.indexOf(this.currentMonth) + 1

            if (tempIndex + 1 <= this.months.length) {
                this.currentMonth = this.months[tempIndex]
            }
        },
        selectADay(day, force = false) {
            if (this.disabled && force == false) {
                return
            }

            if (this.selectionMode == 'single') {
                if (this.selectedDay == null) {
                    this.selectedDay = day

                    var spanOne = document.getElementById(day + this.semester);

                    spanOne.style.backgroundColor = 'rgb(25, 90, 180)';
                    spanOne.style.color = 'white'
                }
                else {
                    var spanAux = document.getElementById(this.selectedDay + this.semester);

                    spanAux.style.backgroundColor = 'rgb(234, 235, 235)';
                    spanAux.style.color = 'black'

                    this.selectedDay = day

                    var spanTwo = document.getElementById(day + this.semester);

                    spanTwo.style.backgroundColor = 'rgb(25, 90, 180)';
                    spanTwo.style.color = 'white'
                }
            }

            if (this.selectionMode == 'multiple') {
                var spanThree = document.getElementById(day + this.semester);

                if (this.selectedDays.indexOf(day) !== -1) {
                    this.selectedDays.splice(this.selectedDays.indexOf(day), 1);

                    spanThree.style.backgroundColor = 'rgb(234, 235, 235)';
                    spanThree.style.color = 'black'
                } else {
                    this.selectedDays.push(day);

                    spanThree.style.backgroundColor = 'rgb(25, 90, 180)';
                    spanThree.style.color = 'white'
                }
            }

            this.returnDays()
        },
        returnDays() {
            if (this.selectionMode == 'single') {
                if (this.showMonth) {
                    this.$emit('returned-days', { month: this.currentMonth.value, days: this.selectedDay });
                }
                else {
                    this.$emit('returned-days', { days: this.selectedDay });
                }
            } else {
                if (this.showMonth) {
                    this.$emit('returned-days', { month: this.currentMonth.value, days: this.selectedDays });
                }
                else {
                    this.$emit('returned-days', { days: this.selectedDays });
                }
            }
        }
    },
};
</script>
  
<style>
.day {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(234, 235, 235);
    text-align: center;
    line-height: 40px;
    cursor: pointer;
}

.day_disabled {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    color: #cacaca;
    text-align: center;
    line-height: 40px;
}

.card_disabled {
    background-color: rgb(255, 255, 255);
}
</style>
  